// @External Dependencies
import React from 'react'
import styled from 'styled-components'
import {
  Alert,
  Toggle,
  Input,
  Select,
  Textarea,
  Datapicker
} from '../../Components/UI'

const SeasonForm = ({
  alertContent,
  alertState,
  loading,
  Season,
  Games,
  onSubmit,
  onChange,
  handleDismissAlert,
  onChangeToggle,
  changeDate
}) => {
  return (
    <Wrapper>
      <form onSubmit={e => onSubmit(e)} acceptCharset='utf-8' id='SeasonForm'>
        {alertContent.length > 0 && (
          <Alert color={alertState} close={e => handleDismissAlert(e)}>
            {alertContent}
          </Alert>
        )}
        <hr />
        <h2>Datos de la temporada</h2>
        <Input
          disabled={loading}
          inputSize={10}
          name='name'
          placeholder='Nombre'
          onChange={e => onChange(e)}
          value={Season.name}
          type='input'
          label='Nombre'
          required
        />
        <Textarea
          disabled={loading}
          inputSize={10}
          name='description'
          placeholder='Descripción'
          label='Descripción'
          onChange={e => onChange(e)}
          value={Season.description}
          required
        />
        <Toggle
          label={'Estado'}
          name={`isActive`}
          onChange={e => onChangeToggle(e)}
          checked={Season.isActive}
        />
        <Datapicker
          name='startDate'
          label='Fecha Inicio'
          onChange={changeDate}
          value={Season.startDate}
          date
          required
        />
        <Datapicker
          name='endDate'
          label='Fecha Fin'
          onChange={changeDate}
          value={Season.endDate}
          date
          required
        />
        <Select
          disabled={loading}
          inputSize={10}
          name='gameId'
          label='Juego'
          onChange={e => onChange(e)}
          select={Season.gameId}
          options={Games.map(game => {
            return {
              id: game.id,
              name: game.name
            }
          })}
          required
        />
      </form>
    </Wrapper>
  )
}

export default SeasonForm

// @Styles
const Wrapper = styled.section``
