// @External Dependencies
import React, { Component } from 'react'
// import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

import {
  UserRegister,
  UserLogin,
  getUser,
  getCountries,
  getRegions,
  getLocations,
  getUserCategories
} from '../../../Redux/Actions'
import {
  Alert,
  Button,
  Input,
  Select,
  Datapicker,
  LoadingSVG
} from '../../../Components/UI'
import { axiosAbort } from '../../../Services'

// @Component
class SimpleUserRegister extends Component {
  state = {
    alertContent: '',
    alertState: 'warning',
    loading: false,
    loadingRegister: false,
    person: {
      name: '',
      firstSurname: '',
      lastSurname: '',
      email: '',
      nickname: '',
      birthday: '',
      passwd: '',
      phone: '',
      genderId: 0,
      categoryId: 1
    },
    address: {
      name: 'Personal',
      address: '',
      countryId: 0,
      regionId: 0,
      locationId: 0
    },
    genders: ['Hombre', 'Mujer', 'Otro'],
    countries: [],
    regions: [],
    locations: [],
    categories: []
  }

  /**
   * - Al iniciar busca los paises
   * - Si estoy logueado entonces me manda al home
   */
  async componentDidMount () {
    const countries = await this.props.getCountries()
    const categories = await this.props.getUserCategories()

    const logged = this.props.loggedUser
    this.setState({
      countries: countries.filter(
        ({ id }) => id === logged.user.Address.countryId
      ),
      categories
    })
  }
  componentWillUnmount () {
    axiosAbort()
  }

  handleDismissAlert = () => {
    this.setState({ alertContent: '' })
  }

  onSubmit = async event => {
    event.preventDefault()
    const { UserRegister } = this.props
    const { person, address } = this.state

    this.setState({
      alertContent: 'Estamos creando tu usuario',
      loading: true,
      loadingRegister: true
    })

    // Armar data para enviar Form
    const data = {
      person,
      address
    }

    const resolve = await UserRegister(data)
    if (!resolve.status.success) {
      this.setState({
        alertContent: resolve.status.detail,
        loading: false,
        loadingRegister: false
      })
    } else {
      await this.props.newLocalPlayer(resolve.person)
      this.setState({
        alertContent: '',
        loading: false,
        loadingRegister: false,
        person: {
          name: '',
          firstSurname: '',
          lastSurname: '',
          email: '',
          nickname: '',
          birthday: '',
          passwd: '',
          phone: '',
          genderId: 0,
          categoryId: 1
        },
        address: {
          name: 'Personal',
          address: '',
          countryId: 0,
          regionId: 0,
          locationId: 0
        }
      })
    }
  }

  handleChange = async e => {
    const { address, person } = this.state
    const target = e.target

    /**
     * Validar persona
     */
    if (target && target.id === 'name') {
      person.name = target.value
    }
    if (target && target.id === 'firstSurname') {
      person.firstSurname = target.value
    }
    if (target && target.id === 'lastSurname') {
      person.lastSurname = target.value
    }
    if (target && target.id === 'genderId') {
      person.genderId = parseInt(target.value, 10)
    }
    if (target && target.id === 'passwd') {
      person.passwd = target.value
    }
    if (target && target.id === 'email') {
      person.email = target.value
    }
    if (target && target.id === 'phone') {
      person.phone = target.value
    }
    if (target && target.id === 'birthday') {
      person.birthday = target.value
    }

    /**
     * Dirección
     */
    if (target && target.id === 'addressName') {
      address.name = target.value
    }
    if (target && target.id === 'address') {
      address.address = target.value
    }
    if (target && target.id === 'categoryId') {
      person.categoryId = parseInt(target.value, 10)
    }
    if (target && target.id === 'countryId') {
      const countryId = parseInt(target.value, 10)
      address.countryId = countryId
      address.regionId = 0
      address.locationId = 0
      const regions = await this.props.getRegions(countryId)
      this.setState({
        address,
        regions: regions.map(r => {
          return { id: r.id, name: r.fullName }
        }),
        locations: []
      })
    }

    if (target && target.id === 'regionId') {
      const regionId = parseInt(target.value, 10)
      address.regionId = regionId
      address.locationId = 0
      const locations = await this.props.getLocations(regionId)
      this.setState({
        address,
        locations
      })
    }

    if (target && target.id === 'locationId') {
      const locationId = parseInt(target.value, 10)
      address.locationId = locationId
      this.setState({
        address
      })
    }

    this.setState({ person, address })
  }

  /**
   * Controla los cambios de los Datapicker
   */
  changeDate = (e, name) => {
    let key, value
    if (name) {
      key = name
      value = e
    } else {
      key = e.target.name
      value = parseInt(e.target.value, 10)
    }
    const { person } = this.state
    if (key === 'birthday') {
      person.birthday = value
    }

    this.setState({ person })
  }

  render () {
    const {
      loading,
      loadingRegister,
      alertContent,
      alertState,
      countries,
      regions,
      locations,
      address,
      person,
      genders
    } = this.state
    const { t } = this.props
    if (loadingRegister) return <LoadingSVG message={alertContent} />
    return (
      <Wrapper>
        <form onSubmit={this.onSubmit} acceptCharset='utf-8' id='UserLoginForm'>
          {alertContent.length > 0 && (
            <Alert color={alertState} close={this.handleDismissAlert}>
              {alertContent}
            </Alert>
          )}
          <Input
            disabled={loading}
            labelSize={3}
            inputSize={9}
            label={t('Nombre')}
            name='name'
            onChange={this.handleChange}
            placeholder={t('Nombre')}
            required
            type='input'
            value={person.name}
          />
          <Input
            disabled={loading}
            labelSize={3}
            inputSize={9}
            label={t('Primer apellido')}
            name='firstSurname'
            onChange={this.handleChange}
            placeholder={t('Primer Apellido')}
            required
            type='input'
            value={person.firstSurname}
          />

          <Datapicker
            disabled={loading}
            name='birthday'
            label='Fecha Nacimiento'
            onChange={this.changeDate}
            labelSize={3}
            inputSize={9}
            birthDate
            required
          />

          <Select
            labelSize={3}
            inputSize={9}
            disabled={loading}
            label={t('Género')}
            name='genderId'
            onChange={this.handleChange}
            options={genders}
            required
            select={person.genderId}
          />
          <Input
            disabled={loading}
            labelSize={3}
            inputSize={9}
            label={t('Contraseña')}
            name='passwd'
            onChange={this.handleChange}
            placeholder={t('contraseña')}
            required
            type='password'
            value={person.passwd}
          />
          <Input
            disabled={loading}
            labelSize={3}
            inputSize={9}
            label={t('Email')}
            name='email'
            onChange={this.handleChange}
            placeholder={t('Email')}
            required
            type='mail'
            value={person.email}
          />
          <div>
            <hr />
            <h2>Dirección</h2>
            <p>
              Ingresa la dirección del jugador, recuerda que solo podrá editarla
              cada 6 meses.
            </p>
            <Input
              disabled={loading}
              labelSize={3}
              inputSize={9}
              label={t('Dirección')}
              name='address'
              onChange={this.handleChange}
              placeholder={t('Dirección')}
              required
              type='text'
              value={address.address}
            />
            <Select
              disabled={loading}
              name='countryId'
              options={countries}
              label={t('País')}
              onChange={this.handleChange}
              labelSize={3}
              inputSize={9}
              select={address.countryId}
              required
            />
            <Select
              disabled={loading}
              name='regionId'
              options={regions}
              label={t('Región')}
              onChange={this.handleChange}
              labelSize={3}
              inputSize={9}
              select={address.regionId}
              required
            />
            {locations.length > 0 && (
              <Select
                disabled={loading}
                name='locationId'
                options={locations}
                label={t('Comuna')}
                onChange={this.handleChange}
                labelSize={3}
                inputSize={9}
                select={address.locationId}
                required
              />
            )}
          </div>
        </form>

        <div className='BaseRight__Bottom__actions buttons'>
          <Button
            disabled={loading}
            type='submit'
            text={t('Registrar Jugador')}
            state='primary'
            form='UserLoginForm'
            className='btn-login'
            style={{ margin: 'auto' }}
          />
        </div>
      </Wrapper>
    )
  }
}

// @Proptypes
SimpleUserRegister.propTypes = {
  loggedUser: PropTypes.object
}

const mapStateToProps = state => ({
  loggedUser: state.loggedUser,
  countries: state.countries
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      UserRegister,
      UserLogin,
      getUser,
      getCountries,
      getRegions,
      getLocations,
      getUserCategories
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(SimpleUserRegister))

// @Styles
const Wrapper = styled.div.attrs({ className: 'SimpleUserRegister' })`
  height: inherit;
`
