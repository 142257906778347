// @External Dependencies
import React from 'react'
import { Card, Table } from 'reactstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'

import {
  DeleteTournamentRelated,
  FindTournamentRelated,
  AddTournamentRelated
} from '../../Redux/Actions'
import { Button, Input, Select, LoadingSVG } from '../../Components/UI'

class RelatedTournaments extends React.Component {
  state = {
    disableAdd: false,
    tournaments: [],
    listTournaments: [],
    loading: false
  }

  componentDidMount () {
    const { tournaments } = this.props
    this.setState({ tournaments })
  }

  searchTournamentRelated = async () => {
    const { FindTournamentRelated } = this.props
    const { Tournaments, status } = await FindTournamentRelated(
      this.props.tournamentId
    )
    if (status.success) {
      const total = Tournaments.map(t => {
        return {
          id: t.id,
          name: `${t.name} - ${t.TournamentType.name}`
        }
      })
      this.setState({
        listTournaments: total
      })
    }
  }

  removeRelated = async tournament => {
    this.setState({ loading: true })
    const { status, deletedId } = await this.props.DeleteTournamentRelated(
      tournament.id
    )
    if (status.success) {
      const tournaments = this.state.tournaments.filter(t => {
        return t.id !== deletedId
      })
      this.setState({ tournaments, loading: false })
    }
  }

  saveRelated = async tournament => {
    this.setState({ loading: true })

    if (tournament.tournamentId) {
      const { status } = await this.props.AddTournamentRelated({
        tournamentId: this.props.tournamentId,
        relatedId: tournament.relatedId,
        quotas: tournament.quotas
      })
      if (status.success) {
        this.searchTournamentRelated()
        this.setState({ loading: true, disableAdd: false })
        window.location.reload()
      } else {
        alert(status.name)
        this.searchTournamentRelated()
        window.location.reload()
      }
    } else {
      this.setState({ loading: false })
    }
  }

  /**
   * Maneja los cambios de estado en el input y select de torneo y cupos
   */
  onChange = e => {
    e.preventDefault()
    const { tournaments } = this.state
    const { target } = e
    if (target.id === 'quotas') {
      const fill = tournaments.map(t => {
        if (!t.id && !isNaN(target.value)) {
          const value = parseInt(target.value, 10)
          if (value >= 0 && value <= 32) {
            t.quotas = value
          } else {
            t.quotas = 1
          }
        }

        return {
          ...t
        }
      })
      this.setState({ tournaments: fill })
    }
    if (target.id === 'relatedId') {
      const { value } = target
      if (parseInt(value, 10) > 0) {
        const fill = tournaments.map(t => {
          if (!t.id && !isNaN(target.value)) {
            const value = parseInt(target.value, 10)

            const index = e.nativeEvent.target.selectedIndex
            const name = e.nativeEvent.target[index].text

            if (value > 0) {
              t.relatedId = value
              t.name = name
            }
          }

          return {
            ...t
          }
        })

        this.setState({ tournaments: fill })
      }
    }
  }

  /**
   * Agrega un nuevo relacionado al State temporal
   */
  newRelated = () => {
    const { tournaments } = this.state
    tournaments.push({
      id: 0,
      name: '',
      relatedId: 0,
      tournamentId: this.props.tournamentId,
      quotas: 0
    })
    this.searchTournamentRelated()

    const disableAdd =
      tournaments.filter(f => {
        return !f.id
      }).length > 0

    this.setState({ tournaments, disableAdd })
  }

  render () {
    const { tournaments } = this.state
    if (this.state.loading) {
      return <LoadingSVG />
    }
    return (
      <Card body className='RelatedTournaments'>
        <h2 className='text-center'>Torneos Relacionados</h2>
        <p>
          Acá puedes asociar torneos y cupos para que cuando se reporte este
          evento se clasifiquen de forma automática los jugadores.
        </p>
        <p className='text-right text-info'>
          ** sección privada administradores
        </p>
        {tournaments.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <th>Torneo</th>
                <th className='text-center'>Cupos</th>
                <th className='text-center'>
                  <i className='fa fa-cogs' />
                </th>
              </tr>
            </thead>
            <tbody>
              {tournaments.map((related, key) => {
                return (
                  <tr key={key}>
                    {related.id > 0 ? (
                      <td>{related.name}</td>
                    ) : (
                      <td>
                        <Select
                          inputSize={12}
                          name='relatedId'
                          options={this.state.listTournaments}
                          select={related.id || related.relatedId}
                          onChange={this.onChange}
                        />
                      </td>
                    )}
                    {related.id > 0 ? (
                      <td className='text-center'>{related.quotas}</td>
                    ) : (
                      <td>
                        <Input
                          disabled={this.state.loading && related.id > 0}
                          inputSize={12}
                          type='number'
                          max={32}
                          min={0}
                          name='quotas'
                          placeholder={'Cantidad de Cupos'}
                          onChange={this.onChange}
                          value={related.quotas}
                          required
                        />
                      </td>
                    )}
                    <td className='text-center'>
                      {related.id > 0 ? (
                        <i
                          className='fa fa-trash btn btn-danger'
                          onClick={() => this.removeRelated(related)}
                        />
                      ) : (
                        <i
                          className='fa fa-save btn btn-success'
                          onClick={() => this.saveRelated(related)}
                        />
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
            {!this.state.loading && (
              <tfoot>
                <tr>
                  <td colSpan={3}>
                    <Button
                      text='Agregar Torneo'
                      style={{ margin: 'auto' }}
                      onClick={this.newRelated}
                      disabled={this.state.loading || this.state.disableAdd}
                    />
                  </td>
                </tr>
              </tfoot>
            )}
          </Table>
        ) : (
          <h2 className='text-center'>
            Por ahora no hay torneos relacionados.
            <Button
              text='Agregar Torneo'
              style={{ margin: 'auto' }}
              onClick={this.newRelated}
              disabled={this.state.loading || this.state.disableAdd}
            />
          </h2>
        )}
      </Card>
    )
  }
}

/*
  @Store Connection: connect
  @Export Component
*/

const mapStateToProps = state => ({
  loggedUser: state.loggedUser,
  countries: state.countries
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      DeleteTournamentRelated,
      FindTournamentRelated,
      AddTournamentRelated
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(RelatedTournaments))
