// @External Dependencies
import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row, Container } from 'reactstrap'
import styled from 'styled-components'
import moment from 'moment'
const dateFormat = 'DD MMMM Y'

/**
 * Print Papeletas
 */
const RoundPrintBallot = ({ round, players }) => {
  const { Tournament } = round
  const printPoints = round.TournamentRoundStatus.id === 1
  const tables = round.TournamentMatches
  const pages = Math.ceil(tables.length / 5)
  const breaks = []
  for (let index = 0; index < pages; index++) {
    breaks.push(index + (index + 1) * 4)
  }
  let first = 0

  return (
    <PdfWrapper>
      <Container id='print'>
        {tables
          .filter(f => {
            return f.opponentId > 0
          })
          .map((match, key) => {
            const pageBreak = breaks.includes(key)
            first = pageBreak ? key + 1 : first
            const player = players.filter(f => {
              return f.id === match.Player.id
            })[0]
            const opponent = players.filter(f => {
              return f.id === match.Opponent.id
            })[0]

            return (
              <Container
                className={`Match__container ${pageBreak ? 'break' : ''} ${
                  first === key ? 'first' : ''
                }`}
                key={key}>
                <Row className='player player__info'>
                  <Col md={2} className='text-center Match__container__table'>
                    <h2>#{key + 1}</h2>
                    <p>Mesa</p>
                  </Col>
                  <Col md={10}>
                    <h4>
                      {Tournament.name}{' '}
                      <small> - {Tournament.Store.name}</small>
                    </h4>
                    <div className='detail'>
                      <span>
                        <b>{round.name}</b> - {round.TournamentRoundStatus.name}
                      </span>
                      <span>
                        Formato: <b>{Tournament.Format.name}</b>
                      </span>
                      <span>
                        Fecha:{' '}
                        <b>{moment(Tournament.startDate).format(dateFormat)}</b>
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row className='player player__info'>
                  <Col md={7} />
                  <Col md={5} className='text-center'>
                    <span className='text-center'>Wins</span>
                    <span className='text-center'>Drop?</span>
                  </Col>
                </Row>
                <Row className='player player__A flex'>
                  <Col md={7}>
                    <span className='name'>
                      {player.Person.fullName}{' '}
                      <span>[{player.Person.code}]</span>
                      {printPoints && <span>{`(${player.points} pts.)`}</span>}
                    </span>
                  </Col>
                  <Col md={5}>
                    <span className='wins' />
                    <span className='loses' />
                  </Col>
                </Row>
                <Row className='player draw flex'>
                  <Col md={12}>
                    <span className='draw'>________</span>
                  </Col>
                </Row>
                <Row className='player player__B flex'>
                  <Col md={7}>
                    <span className='name'>
                      {opponent.Person.fullName}{' '}
                      <span>[{opponent.Person.code}]</span>
                      {printPoints && (
                        <span>{`(${opponent.points} pts.)`}</span>
                      )}
                    </span>
                  </Col>
                  <Col md={5}>
                    <span className='wins' />
                    <span className='loses' />
                  </Col>
                </Row>
                <Row className='player player__signs flex'>
                  <Col md={6}>
                    <span className='sign'>{match.Player.Person.fullName}</span>
                  </Col>
                  <Col md={6}>
                    <span className='sign'>
                      {match.Opponent && match.Opponent.Person.fullName}
                    </span>
                  </Col>
                </Row>
              </Container>
            )
          })}
      </Container>
    </PdfWrapper>
  )
}

// @Proptypes
RoundPrintBallot.propTypes = {
  round: PropTypes.object.isRequired
}

export default RoundPrintBallot

const PdfWrapper = styled.div`
  align-items: center;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  padding: 15px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9999;

  .jumbotron {
    background-color: transparent;
    padding: 0rem 1rem;
    font-size: 13px;
    width: 700px;
    table {
      td {
        padding: 2px !important;
        text-transform: uppercase;
      }
      th {
        background-color: #f8f9fa;
        vertical-align: middle;
      }
      span {
        float: right;
      }
    }
  }
  #print {
    max-width: 100%;
    padding: 0px;
    .Match {
      &__container {
        margin-bottom: -1px;
        padding: 30px 30px 27px 30px;
        border-bottom: 1px dashed black;
        width: 790px !important;
        &.break {
          /* page-break-after: always; */
          /* background-color: red; */
          /* margin-bottom: 30px; */
        }
        .player {
          .name {
            text-transform: uppercase;
            span {
              font-style: italic;
              font-size: 12px;
              margin-left: 20px;
              float: right;
            }
          }
          .wins,
          .loses {
            border-bottom: 1px solid black;
            width: 30%;
            height: 1px;
            margin: 50px;
            &::before {
              color: white;
              content: '________';
            }
          }
          &.draw {
            text-align: right;
            padding: 0px;
            margin-right: 80px;
          }
          &__A,
          &__B {
            padding-top: 5px;
          }
          &__B {
            padding-top: 0px;
            padding-bottom: 10px;
          }
          &__signs {
            margin-top: 30px;
            text-align: center;
            .sign {
              border-top: 1px solid black;
              text-transform: uppercase;
              font-size: 10px;
              padding: 3px 60px;
            }
          }
          &__info {
            font-size: 10px;
            h2,
            p {
              margin: 0px;
            }
            h4 {
              margin: 0px;
              height: 36px;
              overflow: hidden;
            }
            p {
              margin-bottom: 10px;
            }
            span {
              margin: 68px;
            }
            .detail {
              span {
                font-size: 12px;
                margin: 0px 40px 0px 0px;
              }
            }
          }
        }
      }
    }
  }
  @media print {
    .break {
      page-break-after: always;
      background-color: red;
      margin-bottom: 50px;
    }
    .first {
      margin-top: 58px;
    }
  }
`
