const Modal = `
  .modal{
    border-radius: 5px;
    &-dialog{
      max-width: 940px;
      width: 100%;
    }
    &-content{
      border: none;
      box-shadow: none;
      &>section{
        color: #777781;
        &>div{
          padding: 20px 40px;
          position: relative;
        }
      }
    }
    &-header{
      border: none;
      padding: 20px 0!important;
      margin: auto 40px;
      h4 {
        font-size: 1.7857rem;
      }
      button{
        margin-top: 5px;
        margin-right: 0;
        background-color: #F5F5FA;
        width: 30px;
        height: 30px;
        border-radius: 5px;
        color: #000;
        font-size: 20px;
        outline: none;
        opacity: 1;
        transition: all .2s ease;
        :hover{
          color: #c9c9c9;
          opacity: 0.8;
        }
      }
      .close {
        padding: 0px;
        margin: 0px;
      }
    }
    &-body{
      margin: auto 40px;
      padding: 1rem 0px;
      p:last-child{
        margin-bottom: 0;
      }
    }
    &-footer{
      align-items: center;
      background-color: #f5f5fb;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border-top: none;
      display: flex;
      justify-content: flex-end;
      span{
        margin-right: 1rem;
        &.left{
          position: absolute;
          left: 40px;
          top: 50%;
          transform: translateY(-50%)
        }
      }
      button:last-child{
        margin-right: 0;
      }
    }
    .jumbotron {
      .buttons {
        margin: 10px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        a {
          margin: 0px 10px;
        }
      }
    }
  }

  @media (max-width: 425px) {
    .modal {
      &-header{
        margin: 0px;
        background-color: #e9ecef;
        padding: 20px !important;
      }
      &-body{
        margin: auto 20px;
        padding: 1rem 0px;
        p:last-child{
          margin-bottom: 0;
        }
        .table-responsive {
          max-height: 430px;
          overflow-y: auto;
        }
      }
      &-content {
        width: 100vw;
        height: 90vh;
      }
      &-dialog {
        margin: 0px;
        &.PlayerDetail {
          .modal-content {
            height: 110vh;
          }
        }
      }
    }
  }


`
export default Modal
