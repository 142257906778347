// @External Dependencies
import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

// @Dependencies
import logoTOR from '../../../assets/img/tor_logo.png'

// @Component
const PageError = ({ user, t }) => {
  return (
    <StyledPageError>
      <div className='page-error'>
        <div className='header'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='logo'>
                  <img src={logoTOR} alt={t('Logo Mitos y Leyendas')} />
                </div>
              </div>
            </div>
          </div>
          <div className='error'>
            <i className='fa fa-frown-o' aria-hidden='true' />
            <h1>404</h1>
            <p>Página no encontrada</p>
            <p>
              Esta página no existe. Puedes continuar navegando desde el inicio
              del sitio.
            </p>
            <Link to={`/ranking`} className='btn btn-primary'>
              <i className='fa fa-chevron-left' /> Volver al inicio
            </Link>
          </div>
        </div>
      </div>
    </StyledPageError>
  )
}

// @Proptypes
PageError.propTypes = {
  user: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  user: state.loggedUser
})

// @Export Component
export default connect(
  mapStateToProps,
  null
)(withTranslation()(PageError))

// @Styles
const StyledPageError = styled.section`
  padding: 30px;

  .logo {
    text-align: center;
    margin-bottom: 50px;
  }
  .page-error .error {
    height: 245px;
    margin: 20px auto 60px;
    width: 100%;
    text-align: center;
    i.fa-frown-o {
      font-size: 180px;
    }
    p {
      margin-bottom: 0px;
    }
    a {
      margin-top: 30px;
    }
  }

  @media screen and (max-width: 640px) {
    .page-error .error {
      background-size: 600px;
      height: 115px;
      margin: 0 auto 50px;
    }
  }
`
