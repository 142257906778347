// @External Dependencies
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormatCard } from './FormatCard'

const FormatList = ({ formats, deleteFormat, gameId, isAdmin }) => {
  const filterFormats = formats.filter(f => {
    if (gameId) {
      return f.FormatGames.some(formatGame => {
        return formatGame.gameId === gameId
      })
    }
    return true
  })

  return (
    <Wrapper className={!filterFormats.length ? 'empty' : ''}>
      {filterFormats.length > 0 ? (
        filterFormats.map((format, key) => {
          return (
            <div className='ListElement' key={key}>
              <FormatCard key={key} data={format} isAdmin={isAdmin} />
            </div>
          )
        })
      ) : (
        <h3>No hay formatos disponibles para este juego.</h3>
      )}
    </Wrapper>
  )
}

// @Proptypes
FormatList.propTypes = {
  formats: PropTypes.array.isRequired
}

export default FormatList

// @Styles
const Wrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  &.empty {
    grid-template-columns: 1fr;
    text-align: center;
  }
  p {
    margin: 0;
  }
  @media (min-width: 426px) and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 426px) {
    grid-template-columns: 1fr;
  }
  .ListElement {
    margin: 0px 10px;
    .card {
      margin: 20px auto;
      // box-shadow: 10px 5px 40px 20px #e9ecef;
      transition: 0.25s;
      padding: 0px;

      &:hover {
        box-shadow: 0px 0px 20px 10px #c9c9d3;
        cursor: pointer;

        .button-primary {
          transform: translate(10px, 0);
        }
      }
    }

    .card-header {
      height: 200px;
      width: 100%;
      padding: 0px;
      width: 100%;
      background-size: cover;
      background-position: center;
      color: #fff;
      h4 {
        font-size: 1rem;
        padding: 15px;
        background-color: rgba(244, 244, 244, 0.4);
      }
      &--title {
        text-transform: uppercase;
        margin: 0;
      }
    }

    .card-body {
      padding: 15px;
      background-color: #fff;
      width: 100%;
      h3 {
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
      }
      .date {
        font-size: 11px;
        font-weight: 600;
        color: grey;
      }
      .category {
        position: absolute;
        top: 160px;
        left: 0px;
        width: 100%;
        background: #e74c3c;
        padding: 10px 15px;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        i {
          margin-right: 10px;
        }
      }

      .body-content {
        margin-bottom: 10px;
        font-size: 13px;
        // height: 40px;
        overflow: hidden;
      }

      .button-primary {
        border: none;
        box-shadow: none;
        font-family: inherit;
        background-color: transparent;
        font-size: 15px;
        transition: 0.25s;

        i {
          padding-right: 4px;
        }
      }

      .designer-link {
        color: #fff;
        text-align: center;
        font-size: 12px;
        text-decoration: none;
        display: block;
      }
    }
  }
`
