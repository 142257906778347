// @External Dependencies
import React from 'react'
import { Link } from 'react-router-dom'
import { format, isValid } from 'date-fns'
import { es } from 'date-fns/locale'
import styled from 'styled-components'
import ReactCountryFlag from 'react-country-flag'
import ReactHtmlParser from 'react-html-parser'

export const CardHeader = ({
  image,
  title,
  ignoreTitle = false,
  href = ''
}) => {
  const style = {
    backgroundImage: image ? `url('${image} ')` : '',
    backgroundColor: !image ? '#c0c0c0' : 'transparent'
  }
  if (href) {
    return (
      <Link to={href}>
        <header style={style} id={`title_${title}`} className='card-header'>
          {!ignoreTitle && <h4 className='card-header--title'>{title}</h4>}
        </header>
      </Link>
    )
  }
  return (
    <header style={style} id={`title_${title}`} className='card-header'>
      {!ignoreTitle && <h4 className='card-header--title'>{title}</h4>}
    </header>
  )
}

export const CardBody = ({
  info,
  substract,
  date,
  href,
  title,
  text,
  status,
  store = false,
  isObject = false,
  titleClassName = ''
}) => {
  const dateFormatted = isValid(new Date(date))
    ? format(new Date(date), `EEEE dd 'de' MMMM y`, {
        locale: es
      })
    : ''
  return (
    <div className='card-body'>
      {!status.ignore && (
        <div className={`category bg-${status.style}`}>
          <i className={`fa fa-${status.icon}`} />{' '}
          {status.link ? (
            <React.Fragment>
              <a
                href={status.link}
                target='_blank'
                rel='noopener noreferrer'
                style={{ color: 'white' }}>
                {status.name}
              </a>
              <span className='pull-right'>
                {ReactHtmlParser(status.extra)}
              </span>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <span>{status.name}</span>
              <span className='pull-right'>
                {ReactHtmlParser(status.extra)}
              </span>
            </React.Fragment>
          )}
        </div>
      )}
      <p className='date'>
        {dateFormatted}
        {substract}
      </p>
      {href ? (
        <Link to={href}>
          <h3 className={titleClassName}>{title}</h3>
        </Link>
      ) : (
        <h3 className={titleClassName}>{title}</h3>
      )}
      {text && text.length > 0 && <p className='body-content'> {text} </p>}
      {store ? 'Torneos todos los días: ' : ''}
      {info &&
      info.filter(f => {
        return isObject || (f.value && f.value.length > 0)
      }).length > 0 ? (
        <ul className='list-unstyled'>
          {info.map((data, key) => {
            return (
              data.name && (
                <li key={key}>
                  <b>{ReactHtmlParser(data.name)}:</b>{' '}
                  <span>
                    {typeof data.value === 'object'
                      ? data.value
                      : ReactHtmlParser(data.value)}
                  </span>
                </li>
              )
            )
          })}
        </ul>
      ) : (
        info.map((data, key) => {
          return (
            <React.Fragment key={key}>
              <span>
                {data.name} {info.length !== key + 1 ? ',' : ''}{' '}
              </span>
            </React.Fragment>
          )
        })
      )}
    </div>
  )
}
export const CardFooter = ({
  href = '',
  isMobile,
  texts = { mobile: 'Ver info torneo', desktop: 'Ver más info del Torneo' },
  country,
  second = {
    href: '',
    title: ''
  }
}) => (
  <FooterWrapper className='card-footer flex'>
    <Link to={href}>
      <i className='fa fa-chevron-right' />{' '}
      {!isMobile ? texts.desktop : texts.mobile}
    </Link>
    {second.title && (
      <Link to={second.href}>
        <i className='fa fa-chevron-right' /> {second.title}
      </Link>
    )}
    {country && (
      <span>
        <i className='fa fa-map-marker' />
        <ReactCountryFlag code={country} svg />
      </span>
    )}
  </FooterWrapper>
)
const FooterWrapper = styled.div`
  span {
    i {
      margin-right: 20px;
    }
  }
`
