// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { getToken, axiosError } from '../../../Services'

const TournamentDelete = tournamentId => {
  return async dispatch => {
    getToken()
    const query = `
      mutation TournamentDelete($TournamentDeleteInput: TournamentDeleteInput!) {
        TournamentDelete(input: $TournamentDeleteInput) {
          status {
            success
            name
            detail
          }
        }
      }

    `
    const variables = {
      TournamentDeleteInput: {
        tournamentId
      }
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then(response => response.data.data.TournamentDelete)
      .catch(err => axiosError(err))
  }
}

export default TournamentDelete
