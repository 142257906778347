// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { getToken, axiosError } from '../../../Services'

export default id => {
  const requestId = 'FindTournamentType'
  return async dispatch => {
    getToken()
    const query = `
      query {
        TournamentType(id: ${id}) {
          id
          name
          urlImage
          description
          status
          private
          minPlayers
          maxTopPlayers
          multiplier
          winnerPoints
          loserPoints
          drawPoints
          socialRequired
          storeTournaments
          canBeDouble
        }
      }
    `

    return axios
      .post(`${apiURL}/graphql`, { query }, { requestId })
      .then(response => {
        const data = response.data.data.TournamentType
        if (data) {
          data.private = data.private > 0
          data.status = data.status > 0
          data.socialRequired = data.socialRequired > 0
          data.canBeDouble = data.canBeDouble > 0
          return {
            TournamentType: data,
            status: {
              success: true
            }
          }
        } else {
          return {
            status: {
              success: false
            }
          }
        }
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}
