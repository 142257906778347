// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { getToken, axiosError } from '../../../Services'

const getGamesList = (storeId = 0) => {
  const requestId = 'getGamesList'
  return async dispatch => {
    getToken()

    const query = `
      query GameList($GameListInput: GameListInput!) {
        GameList(input: $GameListInput) {
          status {
            success
            name
            detail
          }
          Games {
            id
            name
            availables
          }
        }
      }
    `
    const variables = {
      GameListInput: { storeId }
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables }, { requestId })
      .then(response => {
        if (response.status === 200 && response.data.data.GameList) {
          return response.data.data.GameList
        } else {
          return {
            status: {
              success: false
            },
            Games: []
          }
        }
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}

export default getGamesList
