// @External Dependencies
import React from 'react'

import {
  CardHeader,
  CardBody,
  CardFooter
} from '../../Components/UI/SimpleCard'

export default ({ store, isMobile }) => {
  return (
    <article className='card'>
      <CardHeader
        image={
          store.urlLogo ||
          'https://api.myl.cl/static/images-wpmyl/sectionWallpaper/arte.jpg'
        }
        title={store.name}
        ignoreTitle={store.urlLogo.length > 0}
      />
      <CardBody
        key={store.id}
        title={store.name}
        substract={store.Level.name}
        text={store.description}
        href={store.urlView}
        info={store.StoreTournamentDays.map(d => {
          return {
            name: d.TournamentDay.name
          }
        })}
        store={true}
        status={{
          style: 'info',
          icon: 'external-link',
          name: `Sitio Web`,
          extra: store.phone,
          link: store.siteUrl
        }}
      />
      <CardFooter
        href={store.urlView}
        isMobile={isMobile}
        country={store.Address.Country.iso}
        texts={{ desktop: 'Ver información y torneos', mobile: 'Ver tienda' }}
        tooltip={[
          store.Address.address,
          ', ',
          store.Address.Region.name,
          store.Address.Location ? store.Address.Location.name : ''
        ].join(' ')}
      />
    </article>
  )
}
