// @External Dependencies
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// @External Dependencies
import Select from './Select'
import Input from './Input'

// @Component
class InputDocument extends Component {
  state = {
    type: undefined,
    placeholder: this.props.t('Usuario'),
    selected: undefined,
    options: [
      { id: 'EMAIL', nombre: 'Email' },
      { id: 'CODE_TOR', nombre: 'Código TOR' },
      { id: 'NICKNAME', nombre: 'Nickname' }
    ]
  }

  componentDidMount () {
    const { selectedSelect, selectedInput } = this.props

    let type = selectedSelect
    if (typeof selectedSelect === 'number') {
      type = this.selectNumber(selectedSelect)
    }

    let placeholder = this.setPlaceholder(type)

    this.setState({
      type,
      placeholder,
      selected: selectedInput
    })
  }

  selectNumber = selected => {
    let type
    switch (selected) {
      case 1:
        type = 'EMAIL'
        break
      case 2:
        type = 'CODE_TOR'
        break
      case 3:
        type = 'NICKNAME'
        break
      default:
        type = undefined
        break
    }
    return type
  }

  setPlaceholder = type => {
    const { t } = this.props
    let placeholder
    switch (type) {
      case 'EMAIL':
        placeholder = 'usuario@email.com'
        break
      case 'CODE_TOR':
        placeholder = 'Código de Jugador'
        break
      case 'NICKNAME':
        placeholder = 'Nickname jugador'
        break
      default:
        placeholder = t('Usuario')
    }
    return placeholder
  }

  changeType = e => {
    const type = e.target.value
    let placeholder = this.setPlaceholder(type)
    let selected = ''

    this.setState({
      type,
      placeholder,
      selected
    })
  }

  changeInput = (e, clean) => {
    this.setState({
      selected: clean ? '' : e.target.value
    })
  }

  render () {
    const {
      inputSize,
      disabled,
      nameSelect,
      labelSelect,
      nameInput,
      labelInput,
      required,
      icon
    } = this.props
    const { placeholder, type, options, selected } = this.state
    const inputDisabled = disabled || (disabled === false && !type)

    return (
      <Wrapper>
        <Select
          disabled={disabled}
          icon={icon}
          inputIcon={icon}
          inputSize={inputSize}
          label={labelSelect}
          name={nameSelect}
          onChange={this.changeType}
          options={options}
          required={required}
          select={type}
        />
        <Input
          disabled={inputDisabled}
          inputSize={inputSize}
          label={labelInput}
          name={nameInput}
          onBlur={this.changeInput}
          placeholder={placeholder}
          required={required}
          value={selected}
        />
      </Wrapper>
    )
  }
}

// @Proptypes
InputDocument.propTypes = {
  t: PropTypes.func
}

// @Export Component
export default InputDocument

const Wrapper = styled.div.attrs({ className: 'InputDocument' })``
