// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { getToken, axiosError } from '../../../Services'

export default (seasonId, filters) => {
  const requestId = 'getRankingList'
  return dispatch => {
    getToken()
    const query = `
      query RankingList($RankingListInput: RankingListInput!) {
        RankingList(input: $RankingListInput) {
          status {
            success
            name
            detail
          }
          total
          pages
          Ranking {
            id
            points
            totalTournaments
            victories
            draws
            defeats
            Person {
              id
              avatar
              fullName
              urlAvatar
              code
              Address {
                Country {
                  iso
                  name
                }
              }
            }
          }
          Season {
            id
            name
            description
            startDate
            endDate
            isActive
            rankingUrl
            Game {
              name
            }
          }
          Countries {
            id
            name
          }
          Regions {
            id
            fullName
          }
        }
      }
    `
    const variables = {
      RankingListInput: {
        seasonId: seasonId,
        filter: filters
      }
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables }, { requestId })
      .then(response => {
        if (response.status === 200) {
          return response.data.data.RankingList
        } else {
          return []
        }
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}
