// @External Dependencies
import React from 'react'
import {
  CardBody,
  CardFooter,
  CardHeader
} from '../../Components/UI/SimpleCard'
import { format } from 'date-fns'
import { es } from 'date-fns/locale'

/**
 * Componente con la caja con los tipos de torneos.
 *
 */
export const SeasonCard = ({ data, isAdmin }) => {
  return (
    <div className='card'>
      <CardHeader
        image={data.Game.urlImage}
        title={data.Game.name}
        ignoreTitle={data.Game.urlImage.length > 0}
      />
      <CardBody
        key={data.id}
        title={data.name}
        text={data.description}
        titleClassName='text-center'
        substract={`Torneos realizados: ${data.totalTournaments}`}
        href={data.urlActiveSeason}
        info={[
          {
            name: 'Juego',
            value: data.Game.name
          },
          {
            name: 'Estado',
            value: data.isActive ? '✅ Activa' : '⚠️ Inactiva'
          },
          {
            name: 'Fecha Inicio',
            value: format(new Date(data.startDate), `EEEE dd 'de' MMMM y`, {
              locale: es
            })
          },
          {
            name: 'Fecha Fin',
            value: format(new Date(data.endDate), `EEEE dd 'de' MMMM y`, {
              locale: es
            })
          }
        ]}
        status={{
          ignore: true
        }}
      />
      <CardFooter
        href={data.urlUpdate}
        texts={{ desktop: 'Editar', mobile: 'Editar' }}
        second={{ href: data.rankingUrl, title: 'Ver Ranking' }}
      />
    </div>
  )
}
