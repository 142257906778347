// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { getToken, axiosError } from '../../../Services'

const UploadAvatar = params => {
  return async () => {
    getToken()
    const query = `
      mutation UploadImage($UploadImageInput: UploadImageInput!) {
        UploadImage(input: $UploadImageInput) {
          status {
            success
            name
            detail
          }
          Person {
            urlAvatar
            Store {
              urlLogo
            }
          }
          Tournament {
            urlImage
            isPresential
          }
          TournamentType {
            urlImage
          }
          Game {
            urlImage
          }
        }
      }
    `
    const variables = {
      UploadImageInput: params
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then(response => {
        if (response.data.errors) {
          return response.data.errors[0].message
        } else {
          const { UploadImage } = response.data.data
          return UploadImage
        }
      })
      .catch(err => axiosError(err))
  }
}

export default UploadAvatar
