// @External Dependencies
import React from 'react'
import { SeasonCard } from './SeasonCard'
import { Wrapper } from '../WrapperLists'

const ListSeasons = ({ seasons, isMobile }) => {
  seasons = seasons.sort((a, b) => a.Game.sortOrder - b.Game.sortOrder)
  return (
    <Wrapper className='Seasons_list'>
      {seasons.map((season, key) => {
        return (
          <div className='ListElement' key={key}>
            <SeasonCard data={season} />
          </div>
        )
      })}
    </Wrapper>
  )
}

export default ListSeasons
