// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../constants'
import { getToken, axiosError } from '../../../../Services'

const AddRelatedTournament = data => {
  return async () => {
    getToken()

    const relatedId = data.relatedId || 0
    if (relatedId) {
      const query = `
        mutation {
          createTournamentRelated(
            tournamentId: ${data.tournamentId},
            relatedId: ${relatedId},
            quotas: ${data.quotas || 1}) {
            id
          }
        }
      `

      return axios
        .post(`${apiURL}/graphql`, { query })
        .then(response => {
          if (response.data.errors) {
            return {
              status: {
                success: false,
                name: 'Falló el registro'
              }
            }
          } else if (response.status === 200 && response.data.data) {
            return {
              status: {
                success: true,
                name: 'Se agregó el Torneo Relacionado'
              }
            }
          }
        })
        .catch(err => axiosError(err))
    } else {
      return {
        status: {
          success: false,
          name: 'No se agregó un torneo relacionado.'
        }
      }
    }
  }
}

export default AddRelatedTournament
