// @External Dependencies
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { Row, Col, Container, Jumbotron } from 'reactstrap'
import { bgBackground } from '../../../assets/img/login'
// @Dependencies
import { getStoreList, getUser, getGames } from '../../Redux/Actions'
import { axiosAbort } from '../../Services'
import { LoadingSVG, Select, PaginationList, Input } from '../../Components/UI'
import StoreList from './StoreList'
class StoresView extends React.Component {
  state = {
    loading: true,
    loadingMessage: 'Estamos buscando las tiendas del sistema.',
    Stores: [],
    Countries: [],
    Regions: [],
    Days: [
      { id: 1, name: 'Lunes' },
      { id: 2, name: 'Martes' },
      { id: 3, name: 'Miércoles' },
      { id: 4, name: 'Jueves' },
      { id: 5, name: 'Viernes' },
      { id: 6, name: 'Sábado' },
      { id: 7, name: 'Domingo' }
    ],
    games: [],
    filters: {
      page: 1,
      limit: 12,
      active: 1
    },
    StoreActives: [
      { id: 1, name: 'Tiendas Activas' },
      { id: 2, name: 'Tiendas Inactivas' }
    ]
  }

  async componentDidMount () {
    this.searchStores()
    const { loggedUser, getGames } = this.props

    const games = await getGames()
    this.setState({ isAdmin: loggedUser.isAdmin, games })
  }
  componentWillUnmount () {
    axiosAbort()
  }

  /**
   * Busca el top 16 del Stores
   */
  async searchStores () {
    this.setState({ loading: true })
    const response = await this.props.getStoreList(this.state.filters)
    if (response.status.success) {
      this.setState({
        ...response,
        Regions: response.Regions.map(r => {
          return {
            id: r.id,
            name: r.fullName
          }
        }),
        loading: false
      })
    } else {
      this.setState({
        loadingMessage: 'Ocurrió un error al buscar los datos'
      })
      setTimeout(() => {
        this.props.history.replace('/')
      }, 2500)
    }
  }

  onChange = async e => {
    e.preventDefault()
    const target = e.target
    let { filters } = this.state

    if (target.id === 'countryId') {
      filters.countryId = Number(target.value)
      filters.page = 1
      if (!filters.countryId) {
        this.setState({ Regions: [] })
      }
    }
    if (target.id === 'regionId') {
      filters.regionId = Number(target.value)
      filters.page = 1
    }

    if (target.id === 'dayId') {
      filters.dayId = Number(target.value)
    }

    if (target.id === 'gameId') {
      filters.gameId = Number(target.value)
    }

    if (target.id === 'active') {
      filters.active = Number(target.value)
      filters.page = 1
    }

    let search = true
    if (target.id === 'search') {
      filters.search = target.value.trim()
      search = false
    }

    this.setState({ filters })
    if (search) {
      this.searchStores()
    }
  }

  handleSubmit = async e => {
    e.preventDefault()
    await this.searchStores()
  }

  /**
   * Paginación
   */
  handlePagination = async event => {
    const { filters } = this.state
    if (filters.page !== event) {
      filters.page = event
      this.setState({ filters })
      await this.searchStores()
    }
  }

  render () {
    const { loading, loadingMessage, Stores } = this.state
    if (loading) return <LoadingSVG message={loadingMessage} />
    return (
      <Wrapper>
        <Container>
          <Row>
            <Col md={12}>
              <article className='Stores'>
                <Jumbotron>
                  <Row>
                    <Col md={12}>
                      <h1 className='text-center'>Tiendas</h1>
                      <p>
                        Puedes aplicar filtros para acotar la búsqueda de
                        tiendas organizadoras de torneos.
                      </p>
                    </Col>
                  </Row>
                  <div className='Tournaments__filter row'>
                    <form onSubmit={this.handleSubmit} className='col-sm-4'>
                      <Input
                        inputSize={12}
                        label='Nombre tienda'
                        name='search'
                        placeholder='Buscar por nombre de tienda'
                        value={this.state.filters.search}
                        onChange={this.onChange}
                      />
                    </form>
                    <Col md={4} className='text-center'>
                      <Select
                        inputSize={12}
                        name='countryId'
                        options={this.state.Countries}
                        label={'País'}
                        placeholder='Todos los Paises'
                        onChange={this.onChange}
                        select={this.state.filters.countryId}
                      />
                    </Col>
                    <Col md={4} className='text-center'>
                      <Select
                        inputSize={12}
                        name='regionId'
                        options={this.state.Regions}
                        label={'Región'}
                        placeholder='Todos las regiones'
                        onChange={this.onChange}
                        select={this.state.filters.regionId}
                      />
                    </Col>
                    <Col md={4} className='text-center'>
                      <Select
                        inputSize={12}
                        name='dayId'
                        options={this.state.Days}
                        placeholder='Todos los días'
                        label={'Días de torneos'}
                        onChange={this.onChange}
                        select={this.state.filters.dayId}
                      />
                    </Col>
                    <Col md={4} className='text-center'>
                      <Select
                        inputSize={12}
                        name='gameId'
                        options={this.state.games}
                        placeholder='Todos'
                        label={'Organización de Torneos'}
                        onChange={this.onChange}
                        select={this.state.filters.gameId}
                      />
                    </Col>
                    {this.state.isAdmin && (
                      <Col md={4} className='text-center'>
                        <Select
                          inputSize={12}
                          name='active'
                          options={this.state.StoreActives}
                          label={'Estados de Tiendas'}
                          onChange={this.onChange}
                          select={this.state.filters.active}
                        />
                      </Col>
                    )}
                  </div>
                </Jumbotron>

                {Stores.length > 0 ? (
                  <React.Fragment>
                    <StoreList
                      stores={Stores}
                      total={this.state.total}
                      page={this.state.filters.page}
                      limit={this.state.filters.limit}
                      active={this.state.filters.active}
                      isMobile={this.props.isMobile}
                    />
                    <PaginationList
                      active={this.state.filters.page}
                      pages={this.state.pages}
                      total={this.state.total}
                      handle={this.handlePagination}
                      centered
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Jumbotron>
                      <h2>No se encontraron tiendas.</h2>
                    </Jumbotron>
                  </React.Fragment>
                )}
              </article>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    )
  }
}

// @Proptypes
StoresView.propTypes = {
  loggedUser: PropTypes.object
}

const mapStateToProps = state => ({
  loggedUser: state.loggedUser
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getStoreList,
      getUser,
      getGames
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(StoresView))

// @Styles
const Wrapper = styled.div.attrs({ className: 'Stores' })`
  background: url(${bgBackground('fondo2')}) 50% 0;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding: 80px 0px;

  .container {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
  }
  .Stores {
    padding: 0px;
    &__table {
      td,
      th {
        padding: 3px;
        vertical-align: middle;
      }
    }
    &__footer {
      width: 100%;
      text-align: center;
      button {
        margin: 0 auto !important;
      }
    }
    .label-defect {
      max-width: 100%;
      flex: 100%;
      text-align: left;
    }
  }
`
