// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { getToken, axiosError } from '../../../Services'

export default (data, games) => {
  const requestId = 'FormatUpdate'
  return async dispatch => {
    getToken()
    const query = `
      mutation FormatUpdate($FormatUpdateInput: FormatUpdateInput!) {
        FormatUpdate(input: $FormatUpdateInput) {
          status {
            success
            name
            detail
          }
          Format {
            id
            name
            description
            sortOrder
            status
            FormatGames {
              gameId
              formatId
            }
          }
        }
      }
    `
    const variables = {
      FormatUpdateInput: {
        id: data.id,
        name: data.name,
        description: data.description,
        status: data.status,
        gameIds: games.filter(g => g.status).map(g => g.id)
      }
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables }, { requestId })
      .then(response => {
        return response.data.data.FormatUpdate
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}
