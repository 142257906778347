// @External Dependencies
import React from 'react'

import {
  CardHeader,
  CardBody,
  CardFooter
} from '../../Components/UI/SimpleCard'

/**
 * Componente con la caja con los tipos de torneos.
 *
 */
export const TypeCard = ({ data, deleteType, isAdmin }) => {
  const trophys = []
  for (
    let index = 0;
    index < data.storeTournaments && trophys.length < 9;
    index++
  ) {
    trophys.push(index)
  }

  return (
    <div className='card'>
      <CardHeader
        image={
          data.urlImage ||
          'https://api.myl.cl/static/images-wpmyl/sectionWallpaper/arte.jpg'
        }
        title={data.name}
        ignoreTitle={data.urlImage.length > 0}
      />
      <CardBody
        key={data.id}
        title={data.name}
        substract={`Torneos Realizados: ${data.totalTournaments}`}
        text={data.description}
        href={isAdmin ? data.urlUpdate : ''}
        info={[
          {
            name: 'Privacidad',
            value: data.status ? '✅  &nbsp; Público' : '⛔️ &nbsp; Privado'
          },
          {
            name: 'Torneos por mes',
            value: trophys.length ? (
              trophys.map(t => {
                return <i className='fa fa-trophy' key={t} />
              })
            ) : (
              <i>No tiene torneos</i>
            )
          },
          {
            name: 'Ranking',
            value: `x${data.multiplier}`
          },
          {
            name: '- Ganadas',
            value: `${data.winnerPoints} pts`
          },
          {
            name: '- Empate',
            value: `${data.loserPoints} pts`
          },
          {
            name: '- Perdidas',
            value: `${data.drawPoints} pts`
          }
        ]}
        status={{
          style: data.status ? 'success' : 'danger',
          icon: '',
          name: data.status ? 'Activo' : 'Inactivo',
          link: '',
          extra: data.canBeDouble > 0 ? '⚠️ &nbsp;Permite fechas doble' : ''
        }}
      />
      {isAdmin && (
        <CardFooter
          href={data.urlUpdate}
          texts={{ desktop: 'Ver tipo torneo', mobile: 'Ver tipo Torneo' }}
        />
      )}
    </div>
  )
}
