import { TODOS } from '../Tournaments/constants'

const Years = []
const init = 2019
const end = new Date().getFullYear()
for (var i = end; i >= init; i--) {
  Years.push({ id: i, name: i })
}
export const initStates = {
  Games: [],
  Seasons: [],
  SeasonsTypes: [],
  Countries: [],
  Regions: [],
  Types: [],
  States: [],
  Stores: [],
  Presentials: [],
  seasonId: 0,
  showFilters: true,
  Years
}
export const initFilters = {
  page: 1,
  limit: 12 * 2,
  formatId: 0,
  typeId: 0,
  storeId: 0,
  countryId: 0,
  gameId: 0,
  isActiveSeason: 1,
  seasonId: 0,
  regionId: 0,
  isPresential: 2,
  search: '',
  date: 'DESC',
  players: 'DESC',
  /** Flag para cursos futuros solamente. */
  statusId: TODOS, // TODOS
  startDate: end
}
