// @External Dependencies
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { Row, Col, Container, Jumbotron } from 'reactstrap'
import { Link } from 'react-router-dom'
import { bgBackground } from '../../../assets/img/login'

// @Dependencies
import {
  getTournamentTypeList,
  TournamentTypeDelete,
  getUser
} from '../../Redux/Actions'
import { axiosAbort } from '../../Services'
import { LoadingSVG } from '../../Components/UI'
import TournamentTypeList from './List'

// @Component
class TorunamentTypesView extends React.Component {
  state = {
    loading: true,
    loadingMessage: 'Estamos buscando los Tipos de Torneo del sistema',
    buttonBack: '',
    TournamentTypes: []
  }

  async componentDidMount () {
    await this.props.getUser()
    this.search()
  }
  componentWillUnmount () {
    axiosAbort()
  }

  search = async () => {
    this.setState({ loading: true })
    const TournamentTypes = await this.props.getTournamentTypeList()
    this.setState({
      TournamentTypes,
      loading: false
    })
  }
  deleteType = async type => {
    this.setState({
      loading: true,
      loadingMessage: 'Estamos borrando el Tipo de Torneo'
    })
    const response = await this.props.TournamentTypeDelete(type.id)

    this.setState({
      loadingMessage: response.status.name
    })

    setTimeout(() => {
      this.search()
      this.setState({ loading: false })
    }, 3000)
  }

  render () {
    const { loading, TournamentTypes } = this.state
    if (loading)
      return (
        <LoadingSVG
          message={this.state.loadingMessage}
          buttonBack={this.state.buttonBack}
        />
      )
    const { isAdmin } = this.props.loggedUser
    return (
      <Wrapper>
        <Container>
          <Row>
            <Col md={12}>
              <article className='Ranking'>
                <Jumbotron>
                  <Row>
                    <Col md={12}>
                      <h1 className='text-center'>Tipos de Torneo</h1>
                      <p>
                        Los tipos de torneos son las instancias competitivas que
                        una vez reportados los torneos por las tiendas, el
                        puntaje obtenido de cada jugador se reporta al ranking
                        de la temporada correspondiente.
                      </p>
                      <p>
                        Cada torneo del sistema debe ser creado y asociado a un{' '}
                        <b>Juego</b> y también a un <b>Tipo de Torneo</b>, cada
                        uno de estos puede tener diferentes multiplicadores y
                        exiten y son válidos los que están en esta sección.
                      </p>

                      <p>Nomenclatura importante</p>
                      <ul>
                        <li>
                          <b>Privacidad</b>
                          <ul>
                            <li>
                              <span role='img' aria-label='public'>
                                ✅{' '}
                              </span>
                              &nbsp; <b>Público</b>: Cualquier tienda u
                              organizador puede registrar este tipo de torneo
                            </li>
                            <li>
                              <span role='img' aria-label='private'>
                                ⛔️{' '}
                              </span>
                              &nbsp; <b>Privado</b>: Sólo Juego Organizado Klu,
                              puede crear y/o asignar estos tipos de torneos.
                            </li>
                          </ul>
                        </li>
                        <li>
                          <b>Torneos por mes</b>: La cantidad de{' '}
                          <i className='fa fa-trophy' /> corresponde al total de
                          torneos mensuales que puede inscribir una tienda o
                          organizador de torneos.
                        </li>
                        <li>
                          <b>Ranking</b>: El puntaje total obtenido por el
                          jugador se multiplicará por este valor al ranking de
                          la temporada.
                          <ul>
                            <li>
                              <b>Ganadas</b>: Puntos obtenidos por ganar una
                              partida.
                            </li>
                            <li>
                              <b>Perdidas</b>: Puntos obtenidos por perder una
                              partida
                            </li>
                            <li>
                              <b>Empates</b>: Puntos obtenidos por empatar una
                              partida
                            </li>
                          </ul>
                        </li>
                        <li>
                          <span role='img' aria-label='private'>
                            ⚠️{' '}
                          </span>
                          <b>Permite fechas dobles</b>: Las tiendas pueden
                          marcar estos torneos x2, por lo tanto el puntaje del
                          ranking se duplica cuando se reporta. Pero también es
                          válido por 2 torneos, por lo tanto se descuenta doble
                          de los <i className='fa fa-trophy' /> mensuales
                          disponibles para cada tienda u organizador.
                        </li>
                      </ul>
                      {isAdmin && (
                        <div className='isAdmin'>
                          <h4>Herramientas administrador</h4>
                          <p>
                            Crea los tipos de torneos. Estos tienen los
                            multiplicadores que van directo al ranking de cada
                            temporada una vez reportados.
                          </p>
                          <Link
                            to={'/admin/tournament_type/create'}
                            className='btn btn-primary'>
                            Crear Tipo Torneo
                          </Link>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Jumbotron>

                {TournamentTypes.length > 0 ? (
                  <TournamentTypeList
                    types={TournamentTypes}
                    deleteType={this.deleteType}
                    isAdmin={isAdmin}
                  />
                ) : (
                  <Jumbotron>
                    <h2>No se encontraron tipos de torneo creados.</h2>
                  </Jumbotron>
                )}
              </article>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    )
  }
}

// @Proptypes
TorunamentTypesView.propTypes = {
  loggedUser: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  loggedUser: state.loggedUser
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getTournamentTypeList,
      TournamentTypeDelete,
      getUser
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(TorunamentTypesView))

// @Styles
const Wrapper = styled.div.attrs({ className: 'TournamentTypes' })`
  background: url(${bgBackground('fondo2')}) 50% 0;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding: 80px 0px;

  .container {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
  }

  .isAdmin {
    padding: 20px;
    background-color: white;
  }

  .Ranking {
    padding: 0px;
    &__table {
      td,
      th {
        padding: 3px;
        cursor: pointer;
        vertical-align: middle;
      }
    }
    &__footer {
      width: 100%;
      text-align: center;
      button {
        margin: 0 auto !important;
      }
    }
  }
  .Tournaments {
    .btn-link {
      display: block;
      text-align: right;
    }
  }
`
