// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { getToken, axiosError } from '../../../Services'

const requestId = 'getTournamentOrganizers'
const getTournamentOrganizers = tournamentId => {
  return dispatch => {
    getToken()
    const query = `
      query TournamentOrganizers($TournamentOrganizersInput: TournamentOrganizersInput!) {
        TournamentOrganizers(input: $TournamentOrganizersInput) {
          status {
            success
            name
            detail
          }
          Organizers {
            id
            personId
            Person {
              id
              avatar
              fullName
              urlAvatar
              code
            }
          }
        }
      }
    `
    const variables = {
      TournamentOrganizersInput: {
        tournamentId
      }
    }

    return (
      axios
        // @ts-ignore
        .post(`${apiURL}/graphql`, { query, variables }, { requestId })
        .then(response => {
          if (response.status === 200) {
            return response.data.data.TournamentOrganizers
          } else {
            return []
          }
        })
        .catch(err => axiosError(err, requestId, dispatch))
    )
  }
}
export default getTournamentOrganizers
