// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../constants'
import { getToken, axiosError } from '../../../../Services'

const RoundStart = data => {
  return dispatch => {
    getToken()
    const query = `
      mutation RoundStart($RoundStartInput: RoundStartInput!) {
        RoundStart(input: $RoundStartInput) {
          status {
            success
            name
            detail
          }
          Round {
            id
            urlView
            urlStanding
          }
        }
      }

    `
    const variables = {
      RoundStartInput: data
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then(response => {
        if (response.data.errors) {
          return response.data.errors[0].message
        } else {
          const { RoundStart } = response.data.data
          return RoundStart
        }
      })
      .catch(err => axiosError(err))
  }
}

export default RoundStart
