// @External Dependencies
import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// @Component
const Breadcrumbs = ({ links }) => (
  <StyledBreadcrumbs>
    <Link to='/dashboard'>
      <i className='fa fa-home' />
    </Link>
    {links.map((item, index) =>
      links.length !== index + 1 ? (
        <Link className='custom-links' to={item.url} key={index}>
          {item.text}
        </Link>
      ) : (
        <span key={index}>{item.text}</span>
      )
    )}
  </StyledBreadcrumbs>
)

// @Proptypes
Breadcrumbs.propTypes = {
  links: PropTypes.array.isRequired
}

// @Export Component
export default Breadcrumbs

// @Styles
const StyledBreadcrumbs = styled.div`
  display: flex;
  margin-bottom: 20px;
  a {
    align-items: center;
    background-color: #f5f5fb;
    color: #9a9aa4;
    display: flex;
    font-size: 0.9286rem;
    height: 34px;
    line-height: 34px;
    margin-right: 1px;
    padding: 8px 10px 8px 20px;
    position: relative;
    text-decoration: none;
    :not(:last-child) {
      :after,
      :before {
        content: ' ';
        display: block;
        height: 0;
        position: absolute;
        right: -13px;
        top: 0;
        width: 0;
      }
      :after {
        border-bottom: 17px solid transparent;
        border-left: 12px solid #f5f5fb;
        border-top: 17px solid transparent;
        margin-right: 1px;
        top: -1;
        z-index: 2;
      }
      :before {
        border-bottom: 17px solid transparent;
        border-left: 12px solid #fff;
        border-top: 17px solid transparent;
        margin-right: 0px;
        z-index: 1;
      }
      :hover {
        background-color: #c9c9d3;
        color: white;
        :after {
          border-left: 12px solid #c9c9d3;
        }
      }
    }
    :first-child {
      border-bottom-right-radius: 0;
      border-radius: 5px 0 0 5px;
      border-top-right-radius: 0;
      font-size: 1.1429rem;
      padding-left: 10px;
    }
  }
  span {
    color: #9a9aa4;
    font-size: 0.9286rem;
    padding: 8px 10px 8px 20px;
  }
`
