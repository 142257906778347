// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { getToken, axiosError } from '../../../Services'

const getCountries = () => {
  const requestId = 'getCountries'
  return dispatch => {
    getToken()

    const query = `
      query {
        allPersonCategorys(paginate: 20, orderBy:name_ASC) {
          name
          id
        }
      }
    `

    return axios
      .post(`${apiURL}/graphql`, { query }, { requestId })
      .then(response => {
        if (response.status === 200 && response.data.data.allPersonCategorys) {
          return response.data.data.allPersonCategorys
        } else {
          return []
        }
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}

export default getCountries
