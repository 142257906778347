// @External Dependencies
import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  Row,
  Col,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap'
import { Link } from 'react-router-dom'
import { Button, Avatar } from '../../Components/UI'
import moment from 'moment'

const TournamentModal = ({ player, isOpen, toggleModal, isMobile }) => {
  const dates = [
    'Desde el: ',
    moment(player.SeasonRank.Season.startDate).format('DD MMM Y'),
    ' al ',
    moment(player.SeasonRank.Season.endDate).format('DD MMM Y')
  ]
  const isAdmin = useSelector(state => state.loggedUser.isAdmin)

  const registered = moment(player.Person.created).format('DD MMM Y')
  return (
    <Wrapper>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          toggleModal(0)
        }}
        className='PlayerDetail'>
        <ModalHeader
          toggle={() => {
            toggleModal(0)
          }}>
          "<b>{player.Person.fullName}</b>" - {player.Person.code}{' '}
          <small>(Registrado el: {registered}) </small>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={3} className='text-center'>
              <Avatar
                person={player.Person}
                size={isMobile ? 100 : 200}
                fontSize={40}
              />
              <hr />
            </Col>
            <Col md={9}>
              <h6>
                <i className='fa fa-trophy' /> Detalle de torneos
              </h6>
              {!isMobile && (
                <ul>
                  <li>
                    Participación en Torneos:{' '}
                    {player.SeasonRank.totalTournaments}
                  </li>
                  <li>Puntos Totales obtenidos: {player.SeasonRank.points}</li>
                  <li className='text-success'>
                    {player.SeasonRank.victories} rondas ganadas
                  </li>
                  <li className='text-info'>
                    {player.SeasonRank.draws} rondas empatadas
                  </li>
                  <li className='text-danger'>
                    {player.SeasonRank.defeats} rondas perdidas
                  </li>
                </ul>
              )}
              <p>
                Temporada:{' '}
                <b>
                  {player.SeasonRank.Season.name} --{' '}
                  <i>{player.SeasonRank.Season.Game.name}</i>
                </b>
                <br />
                <i> {dates.join('')} </i>
              </p>
            </Col>
          </Row>
          <Row>
            <Table
              className={`Ranking__table table-bordered ${
                isMobile ? 'table-responsive' : ''
              }`}>
              <thead>
                <tr>
                  <th className='text-center'>#</th>
                  <th className='text-center'>Torneo</th>
                  <th className='text-center'>Tipo</th>
                  <th className='text-center'>Fecha</th>
                  <th className='text-center'>Puntos</th>
                  <th className='text-center'>Jugadores</th>
                  <th className='text-center'>Link</th>
                </tr>
              </thead>
              <tbody>
                {player.Tournaments.map((tournament, key) => {
                  return (
                    <tr key={key}>
                      <td className='text-center'>{key + 1}</td>
                      <td>
                        {tournament.name} <br />
                        {!isMobile && (
                          <>
                            <i className='fa fa-home' /> {tournament.Store.name}
                          </>
                        )}
                      </td>
                      <td className='text-center'>
                        {tournament.TournamentType.name}
                      </td>
                      <td className='text-center'>
                        {moment(tournament.startDate).format('DD MMM Y')}
                      </td>
                      <td className='text-center'>
                        {tournament.UserPosition
                          ? tournament.UserPosition.points
                          : 0}
                      </td>
                      <td className='text-center'>
                        {tournament.UserPosition
                          ? tournament.UserPosition.TournamentRound.Tournament
                              .maxPlayers
                          : 0}
                      </td>
                      <td className='text-center'>
                        <Link
                          to={
                            tournament.UserPosition.TournamentRound.urlStanding
                          }
                          className='btn btn-info btn-xs'>
                          Ver
                        </Link>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </Row>
        </ModalBody>
        <ModalFooter>
          {isAdmin && (
            <Link
              onClick={() => {
                toggleModal(0)
              }}
              to={player.Person.urlEdit}
              className='btn btn-success'>
              Modificar Perfil
            </Link>
          )}
          <Button
            state='default'
            onClick={() => {
              toggleModal(0)
            }}
            text='Cancelar'
          />
        </ModalFooter>
      </Modal>
    </Wrapper>
  )
}

// @Proptypes
TournamentModal.propTypes = {
  player: PropTypes.object.isRequired
}

export default TournamentModal

// @Styles
const Wrapper = styled.section``
