// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { getToken, axiosError } from '../../../Services'

const getSeasonsList = () => {
  const requestId = 'getSeasonsList'
  return dispatch => {
    getToken()

    const query = `
      query {
        allSeasons(paginate: 999, orderBy: name_ASC) {
          id
          name
          code
          description
          isActive
          startDate
          endDate
          urlUpdate
          rankingUrl
          totalTournaments
          gameId
          Game {
            id
            name
            sortOrder
            urlImage
          }
        }
      }
    `

    return axios
      .post(`${apiURL}/graphql`, { query }, { requestId })
      .then(response => {
        if (response.status === 200 && response.data.data.allSeasons) {
          return response.data.data.allSeasons
        } else {
          return []
        }
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}

export default getSeasonsList
