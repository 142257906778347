// @External Dependencies
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Table, UncontrolledTooltip } from 'reactstrap'
import { Avatar } from '../../Components/UI'
import ReactCountryFlag from 'react-country-flag'
const RankingList = ({
  ranking,
  total,
  page,
  limit,
  toggleModal,
  isMobile
}) => (
  <Wrapper>
    <React.Fragment>
      <Table
        className={`Ranking__table table-bordered table-hover ${
          isMobile ? 'table-responsive' : ''
        }`}>
        <thead>
          <tr>
            <th className='text-center' rowSpan={2}>
              #
            </th>
            <th colSpan={!isMobile ? 3 : 1} rowSpan={2} className='text-center'>
              Jugador
            </th>
            <th className='text-center' rowSpan={2}>
              <i id={`tooltipPoints`}>
                <UncontrolledTooltip placement='top' target={`tooltipPoints`}>
                  Puntaje Total obtenido
                </UncontrolledTooltip>
                {isMobile ? 'Pje.' : 'Puntaje'}
              </i>
            </th>
            {!isMobile && (
              <th className='text-center' rowSpan={2}>
                <i id={`tooltipTournaments`}>
                  <UncontrolledTooltip
                    placement='top'
                    target={`tooltipTournaments`}>
                    Total de Torneos participados
                  </UncontrolledTooltip>
                  Torneos
                </i>
              </th>
            )}
            <th colSpan={4} className='text-center'>
              Detalles
            </th>
          </tr>
          <tr>
            <th className='text-center'>
              <i id={`tooltipVictories`}>
                <UncontrolledTooltip
                  placement='top'
                  target={`tooltipVictories`}>
                  Rondas Victoriosas
                </UncontrolledTooltip>
                V
              </i>
            </th>
            <th className='text-center'>
              <i id={`tooltipDraws`}>
                <UncontrolledTooltip placement='top' target={`tooltipDraws`}>
                  Rondas Empatadas
                </UncontrolledTooltip>
                E
              </i>
            </th>
            <th className='text-center'>
              <i id={`tooltipDefeats`}>
                <UncontrolledTooltip placement='top' target={`tooltipDefeats`}>
                  Rondas perdidas
                </UncontrolledTooltip>
                P
              </i>
            </th>
            <th className='text-center'>
              <i id={`tooltipRendimiento`}>
                <UncontrolledTooltip
                  placement='top'
                  target={`tooltipRendimiento`}>
                  Expresado en Rondas <br /> <br />
                  Rendimiento = (Victorias + (Empates / 2) * 100) / (Victorias +
                  Empates + Derrotas)
                </UncontrolledTooltip>
                Rend.
              </i>
            </th>
          </tr>
        </thead>
        <tbody>
          {ranking.map((r, key) => {
            let position = key + 1
            if (page > 1) {
              position = position + limit * (page - 1)
            }
            /* r.victories = 14
            r.draws = 3
            r.defeats = 4 */
            const total = r.victories + r.draws + r.defeats
            // Victorias + (Empates % 2) * 100 / Total
            const rendimiento = Math.round(
              ((r.victories + r.draws / 2) * 100) / total
            )
            let classRendimiento = ''
            if (rendimiento <= 33) {
              classRendimiento = 'danger'
            } else if (rendimiento > 33 && rendimiento < 70) {
              classRendimiento = 'warning'
            } else if (rendimiento >= 70) {
              classRendimiento = 'success'
            }
            return (
              <tr
                key={key}
                onClick={() => {
                  toggleModal(r.Person.id)
                }}
                className={isMobile ? 'mobile' : ''}>
                <td className='text-center'>{position}</td>
                {!isMobile && (
                  <td className='text-center'>
                    <Avatar person={r.Person} size={32} fontSize={12} />
                  </td>
                )}
                <td className={`name`}>
                  {r.Person.fullName}
                  {!isMobile && (
                    <ReactCountryFlag code={r.Person.Address.Country.iso} svg />
                  )}
                </td>
                {!isMobile && <td>{r.Person.code}</td>}
                <td className='text-center'>{r.points}</td>
                {!isMobile && (
                  <td className='text-center'>{r.totalTournaments}</td>
                )}
                <td className='text-center'>{r.victories}</td>
                <td className='text-center'>{r.draws}</td>
                <td className='text-center'>{r.defeats}</td>
                <td className={`text-center text-${classRendimiento}`}>
                  {rendimiento}%
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </React.Fragment>
  </Wrapper>
)

// @Proptypes
RankingList.propTypes = {
  ranking: PropTypes.array.isRequired
}

export default RankingList

// @Styles
const Wrapper = styled.div.attrs({ className: 'Ranking' })`
  .Ranking {
    &__table {
      tr.mobile {
        font-size: 14px;
      }
      td,
      th {
        padding: 3px;
        cursor: pointer;
        vertical-align: middle;

        &.name {
          span {
            float: right;
          }
        }
      }
    }
  }
`
