// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { axiosError, getToken } from '../../../Services'

const getLocations = regionId => {
  const requestId = 'getLocations'
  return dispatch => {
    getToken()
    const query = `
      query allLocations(
        $paginate: Int
        $filter: LocationFilterFilter
        $orderBy: LocationFilterOrderBy
      ){
        allLocations(
          paginate: $paginate
          filter: $filter
          orderBy: $orderBy
          ){
          name
          id
        }
      }
    `
    const variables = {
      paginate: 1000,
      orderBy: 'sortOrder_ASC',
      filter: {
        regionId
      }
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables }, { requestId })
      .then(response => {
        if (response.status === 200 && response.data.data.allLocations) {
          return response.data.data.allLocations
        } else {
          return []
        }
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}

export default getLocations
