// @External Dependencies
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

// @Dependencies
import { Button } from '../'
import BaseForm from './BaseForm'
import vars from '../variables'

// @Component
export class InputFile extends Component {
  state = {
    empty: true,
    files: [],
    rejected: false
  }

  static getDerivedStateFromProps (props, state) {
    let empty

    if (props.files && props.files.length) {
      empty = false
    } else {
      empty = true
    }

    return {
      empty,
      files: props.files
    }
  }

  cleanInput = () => {
    const { onChange, disabled } = this.props

    if (disabled === undefined || disabled === false) {
      this.setState({
        empty: true,
        files: []
      })
      onChange && onChange([])
    }
  }

  onDropAccepted = files => {
    const { onChange } = this.props

    this.setState({
      empty: false,
      files,
      rejected: false
    })

    onChange && onChange(files) // Le paso los archivos al componente padre
  }

  onDropRejected = files => {
    this.setState({ rejected: true })
    setTimeout(() => {
      this.setState({ rejected: false })
    }, 700)
  }

  renderIcon = type => {
    switch (type) {
      case 'imagen':
        return 'file-image-o'
      case 'pdf':
        return 'file-pdf-o'
      case 'audio':
        return 'file-audio-o'
      case 'video':
        return 'file-video-o'
      case 'word':
        return 'file-word-o'
      case 'documento':
        return 'file-o'
      default:
        return 'file-o'
    }
  }

  accept = type => {
    switch (type) {
      case 'imagen':
        return 'image/jpeg, image/png, image/gif'
      case 'pdf':
        return 'application/pdf'
      case 'audio':
        return 'video/mp4'
      case 'video':
        return 'audio/mp3'
      case 'word':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      case 'documento':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf'
      default:
        return ''
    }
  }

  thumb = (type, files) => {
    if (type === 'imagen') {
      return (
        <div className='fileThumb'>
          <Thumb files={files} />
        </div>
      )
    } else {
      return (
        <div className='fileThumb'>
          <i className={`fa fa-${this.renderIcon(type)}`} />
          <DropImage
            name='dropImage'
            type='imagen'
            uploadedFile={files}
            download={this.props.download}
            disabled={this.props.disabled}
          />
        </div>
      )
    }
  }

  render () {
    const {
      disabled,
      info,
      type,
      inputSize,
      label,
      name,
      required,
      size,
      tooltip,
      alert,
      multi,
      t
    } = this.props
    const { empty, files, rejected, rejectedMessage } = this.state
    let _size = size !== undefined ? size : ''

    return (
      <StyledInputFile>
        <BaseForm
          info={info || rejectedMessage}
          inputSize={inputSize}
          label={label}
          name={name}
          required={required}
          tooltip={tooltip}
          alert={alert}>
          <Dropzone
            className={`form-control input-${_size} ${
              rejected ? 'rejected' : ''
            }`}
            required={required}
            multiple={multi}
            accept={this.accept(type)}
            name={name}
            id={name}
            disabled={disabled || !empty}
            onDropAccepted={this.onDropAccepted}
            onDropRejected={this.onDropRejected}>
            <div className='content'>
              <div className={`textIcon ${empty ? 'empty' : 'full'}`}>
                {empty ? (
                  <i className={`fa fa-${this.renderIcon(type)}`} />
                ) : (
                  this.thumb(type, files)
                )}
                <div>
                  {`${t('File')} `}
                  <span
                    className={`type ${
                      type === 'pdf' ? 'uppercase' : 'capitalize'
                    }`}>
                    {type}
                  </span>
                  {!empty && (
                    <div className='fileName'>
                      <strong>{files.length && files[0].name}</strong>
                    </div>
                  )}
                </div>
              </div>
              {empty && (
                <div className='textButton'>
                  {t('Drag File Or')}
                  <Button
                    disabled={disabled}
                    onClick={event => event.preventDefault()}
                    text={t('Load From Computer')}
                    state='primary'
                  />
                </div>
              )}
            </div>
          </Dropzone>
          {!empty && !disabled && (
            <i
              className='glyphicon glyphicon-remove btn-clean'
              onClick={this.cleanInput}
            />
          )}
        </BaseForm>
      </StyledInputFile>
    )
  }
}

class DropImage extends Component {
  state = {
    empty: true,
    files: [],
    rejected: false
  }

  cleanInput = e => {
    this.setState({
      empty: true,
      files: []
    })
  }

  onDropAccepted = files => {
    this.setState({
      empty: false,
      files,
      rejected: false
    })
  }

  onDropRejected = files => {
    this.setState({ rejected: true })
    setTimeout(() => {
      this.setState({ rejected: false })
    }, 700)
  }

  downloadFile = (event, files) => {
    event.preventDefault()
    this.props.download(files)
  }

  render () {
    const { name, uploadedFile, disabled } = this.props
    const { empty, files, rejected } = this.state
    // let dropzoneRef

    return (
      <Dropzone
        ref={node => {
          /* dropzoneRef = node */
        }}
        onDropAccepted={this.onDropAccepted}
        onDropRejected={this.onDropRejected}
        className={`form-control dropImage ${rejected ? 'rejected' : ''}`}
        accept='image/jpeg, image/png, image/gif'
        name={name}
        id={name}
        disableClick={true}
        disabled={!empty}>
        <div className='content'>
          {!empty && (
            <div className='textIcon'>
              <div className='fileThumb'>
                <Thumb files={files} />
              </div>
            </div>
          )}
          <div className='textButton'>
            {disabled}
            <Button
              tooltip='Descargar archivo'
              size='xs'
              icon='download'
              state='primary'
              onClick={e => this.downloadFile(e, uploadedFile)}
            />
            {/*
              Hay que ver el guardado de la data con una imgen dentro para implementar este botón
              <Button className={`${empty ? 'empty' : 'full'}`} tooltip="Cargar imagen" size="xs" icon="picture-o" state="primary" onClick={() => { dropzoneRef.open() }} />
            */}
          </div>
        </div>
        {!empty && (
          <Button
            onClick={this.cleanInput}
            className='btn-clean'
            tooltip='Remover imagen'
            size='xs'
            icon='times'
            state='danger'
          />
        )}
      </Dropzone>
    )
  }
}

// @Proptypes
InputFile.propTypes = {
  disabled: PropTypes.bool,
  info: PropTypes.node,
  inputSize: PropTypes.number,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  multi: PropTypes.bool,
  size: PropTypes.string,
  tooltip: PropTypes.string,
  alert: PropTypes.string,
  type: PropTypes.string.isRequired,
  value: PropTypes.string
}

InputFile.defaultProps = {
  multi: false
}

// @Export Component
export default withTranslation()(InputFile)

const StyledInputFile = styled.div`
  .form-control {
    background: #f5f5fb;
    border-radius: 5px;
    border: 2px dotted #dedee8;
  }

  .content,
  .textIcon,
  .fileThumb {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .content {
    word-break: break-all;

    .fileName {
      margin-top: 10px;
      word-break: break-word;
    }
    .type {
      display: inline-block;
      &.capitalize {
        text-transform: capitalize;
      }
      &.uppercase {
        text-transform: uppercase;
      }
    }
  }

  .rejected {
    animation: rejectedAnimation 0.7s ease forwards;
  }

  @keyframes rejectedAnimation {
    50% {
      background-color: ${vars.setColors.danger};
    }
  }

  i {
    color: #cbcbcb;
    font-size: 22px;
    margin-right: 15px;

    &.btn-clean {
      background: ${vars.setColors.danger};
      border-radius: 5px;
      color: white;
      font-size: 14px;
      margin: 0;
      padding: 4px;
      position: absolute;
      right: 10px;
      top: 10px;
      transform: none;
    }
  }

  .textIcon {
    color: #9a9aa4;

    &.empty {
      align-items: center;
      line-height: 20px;
    }
    &.full {
      align-items: flex-start;
    }
  }

  .fileThumb {
    justify-content: center;
    margin-right: 10px;
    position: relative;

    & > i {
      color: rgba(255, 255, 255, 0.75);
      margin: 0;
      z-index: 1;
    }
  }

  .textButton {
    color: #777781;
    flex-shrink: 0;
  }

  button {
    font-size: 14px;
    margin-left: 15px;
    margin-right: 0;
  }

  .fileThumb {
    background: #c9c9d3 center / cover no-repeat;
    border-radius: 5px;
    flex-shrink: 0;
    height: 135px;
    position: relative;
    text-align: center;
    width: 214px;
  }

  /* DropImage para inputs que no son tipo Image */
  .dropImage {
    align-items: flex-start;
    background: none;
    border: 0;
    display: flex;
    height: 100%;
    justify-content: flex-end;
    left: 0;
    overflow: hidden;
    padding: 10px;
    position: absolute;
    top: 0;

    .fileThumb {
      left: 0;
      position: absolute;
      top: 0;
    }

    .textButton {
      display: flex;
      z-index: 10;
    }

    button {
      align-items: center;
      display: flex;
      height: 25px;
      justify-content: center;
      margin-left: 5px;
      width: 22px;

      &.full {
        visibility: hidden;
      }

      &.btn-clean {
        right: 10px;
        top: 10px;
        transform: none;
        z-index: 10;
      }
    }
  }
`

const Thumb = styled.div`
  background: url(${props => props.files.length && props.files[0].preview})
    center / contain no-repeat;
  border-radius: 5px;
  height: inherit;
  position: relative;
  width: 100%;
  z-index: 1;
`
