// @External Dependencies
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

class StoreMenu extends React.Component {
  state = {
    urls: [],
    loading: true,
    dropdownOpen: false
  }
  componentDidMount () {
    const { match, store } = this.props
    this.setState({
      urls: [
        {
          href: store.urlView,
          text: (
            <>
              {' '}
              <i className='fa fa-home' /> Información Tienda{' '}
            </>
          ),
          active:
            match.path.includes('detail') ||
            match.path.includes('edit') ||
            match.path === '/store'
        },
        {
          href: store.urlTournaments,
          text: (
            <>
              <i className='fa fa-trophy' /> Torneos Realizados
            </>
          ),
          active:
            match.path.includes('tournaments') || match.path.includes('round')
        }
      ],
      loading: false
    })
  }

  render () {
    const { urls, loading } = this.state
    if (loading) return ''
    return (
      <Wrapper className='noStyledList'>
        <ul>
          <>
            {urls
              .filter(u => u)
              .map((url, key) => {
                return (
                  <li key={key}>
                    <Link to={url.href} className={url.active ? 'active' : ''}>
                      {url.text}
                    </Link>
                  </li>
                )
              })}
          </>
        </ul>
      </Wrapper>
    )
  }
}

// @Proptypes
StoreMenu.propTypes = {
  store: PropTypes.object.isRequired
}

export default StoreMenu

// @Styles
const Wrapper = styled.section`
  background-color: #333;
  margin-top: 40px;
  width: 100%;
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    li {
      float: left;
      a {
        display: block;
        color: white;
        text-align: center;
        padding: 14px 16px;
        text-decoration: none;
        &:hover:not(.active) {
          background-color: #111;
        }
      }
      .active {
        background-color: #4caf50;
      }
    }
  }
`
