// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../constants'
import { getToken, axiosError } from '../../../../Services'

const RoundReset = data => {
  return dispatch => {
    getToken()
    const query = `
      mutation RoundReset($RoundResetInput: RoundResetInput!) {
        RoundReset(input: $RoundResetInput) {
          status {
            success
            name
            detail
          }
        }
      }
    `
    const variables = {
      RoundResetInput: data
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then(response => {
        if (response.data.errors) {
          return response.data.errors[0].message
        } else {
          const { RoundReset } = response.data.data
          return RoundReset
        }
      })
      .catch(err => axiosError(err))
  }
}

export default RoundReset
