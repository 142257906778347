import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, Link } from 'react-router-dom'
import { getSurveyView, SurveyCreate } from '../../Redux/Actions'
import { Row, Col, Container } from 'reactstrap'
import { WrapperView } from '../WrapperViews'
import { LoadingSVG } from '../../Components/UI'

import { format } from 'date-fns'
import { es } from 'date-fns/locale'

import FormSurvey from './Form'
/**
 * Vista con la encuesta de satisfacción de torneo
 */
export const SurveyView = () => {
  const dispatch = useDispatch()
  const { playerId } = useParams()
  const [survey, setSurvey] = useState()
  const [loading, setLoading] = useState(true)
  const [message, setMessage] = useState('')
  const [buttonBack, setButtonBack] = useState('')
  const [title, setTitle] = useState('')
  const [completed, setCompleted] = useState(false)
  const [average, setAverage] = React.useState(0)

  const search = useCallback(async () => {
    setLoading(true)
    setMessage('Buscando datos de la encuesta')
    const resolve = await dispatch(getSurveyView(playerId))
    if (resolve.status && resolve.status.success) {
      if (resolve.Survey) {
        setCompleted(true)
      }
      setSurvey(resolve)
      setLoading(false)
      setMessage('')
    } else {
      setTitle(resolve.status.name)
      setMessage(resolve.status.detail)
      setButtonBack('/')
    }
  }, [dispatch, playerId])

  const onSubmit = useCallback(async (e, data) => {
    e.preventDefault()
    if (data.firstQuestion && data.secondQuestion && data.thirdQuestion) {
      setTitle('Guardando resultados')
      setMessage('Estamos guardando tu evaluación.')
      setLoading(true)
      const resolve = await dispatch(
        SurveyCreate({ ...data, playerId: Number(playerId) })
      )
      if (resolve.status.success) {
        setMessage(resolve.status.detail)
        setTimeout(() => {
          search()
        }, 2000)
      } else {
        setTitle(resolve.status.name)
        setMessage(resolve.status.detail)
        setTimeout(() => {
          setLoading(false)
        }, 3000)
      }
    }
  }, [playerId, dispatch, search])

  useEffect(() => {
    search()
  }, [search])

  if (loading) {
    return (
      <LoadingSVG message={message} buttonBack={buttonBack} title={title} />
    )
  }
  return (
    <WrapperView>
      <Container>
        <Row>
          <Col md={12}>
            <h2 className='text-center'>Encuesta experiencia</h2>
            <div className='grid secondary'>
              <div>
                <p>
                  Hola {survey.Player.Person.name}, esta es la encuesta de
                  experiencia por tu participación en el torneo "
                  {survey.Player.Tournament.name}" de{' '}
                  <b>{survey.Player.Tournament.Game.name}</b> organizado por{' '}
                  <b>{survey.Player.Tournament.Store.name}</b> el día{' '}
                  <u>
                    {format(
                      new Date(survey.Player.Tournament.startDate),
                      `EEEE dd 'de' MMMM y`,
                      {
                        locale: es
                      }
                    )}
                  </u>
                </p>
                <p>
                  Recuerda que en los siguientes enlaces podrás encontrar{' '}
                  <Link to={survey.Player.Tournament.urlView} className='link'>
                    los detalles generales del torneo
                  </Link>
                  {' y las '}
                  <Link
                    to={survey.Player.Tournament.urlRounds}
                    className='link'>
                    rondas con sus posiciones finales
                  </Link>
                </p>
              </div>
              <div className='text-center'>
                <h4>Estado actual</h4>
                <p
                  className={
                    survey.status.code === 300 ? 'text-success' : 'text-danger'
                  }>
                  {survey.status.code === 300 ? 'RESPONDIDA' : 'PENDIENTE'}
                </p>
                <p>
                  <Stars average={average} />
                </p>
              </div>
            </div>
            <FormSurvey
              onSubmit={onSubmit}
              loading={loading}
              alertContent={message}
              isEdit={survey.status.code !== 300}
              survey={survey.Survey}
              completed={completed}
              setAverage={setAverage}
            />
          </Col>
        </Row>
      </Container>
    </WrapperView>
  )
}

const Stars = ({ average }) => {
  let stars = []
  for (let index = 0; index < average; index++) {
    stars.push(1)
  }
  return stars.map(t => {
    return <i className={`fa fa-star${!t ? '-o' : ''}`} key={t} />
  })
}
