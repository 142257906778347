import React from 'react'
import Wrapper from './Styles'
import { Container } from 'reactstrap'
import { LoadingSVG, PaginationList } from '../../Components/UI'

import { FiltrosTorneo } from './Filtros'
import { TournamentListTable } from './Table'
import { useTournaments } from './useTournaments'

const TournamentList = ({ isMobile }) => {
  const {
    states,
    filters,
    tournaments,
    loading,
    message,
    handleSubmit,
    handleResetFilters,
    handlePagination,
    handleOnChange
  } = useTournaments()

  if (loading) {
    return <LoadingSVG message={message} />
  }
  return (
    <Wrapper>
      <Container>
        <FiltrosTorneo
          enableButton={true}
          filters={filters}
          states={states}
          data={tournaments}
          handleSubmit={handleSubmit}
          handleResetFilters={handleResetFilters}
          handlePagination={handlePagination}
          handleOnChange={handleOnChange}
        />
        {tournaments.total > 0 ? (
          <>
            <TournamentListTable
              data={tournaments.Tournaments}
              isMobile={isMobile}
            />
            <PaginationList
              active={filters.page}
              total={tournaments.total}
              pages={tournaments.pages}
              handle={handlePagination}
              centered
            />
          </>
        ) : (
          <p className='text-center'>No se encontraron resultados.</p>
        )}
      </Container>
    </Wrapper>
  )
}

export default TournamentList
