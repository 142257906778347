// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { getToken, axiosError } from '../../../Services'

const TournamentMatchUpdate = data => {
  return dispatch => {
    getToken()
    const query = `
      mutation TournamentMatchUpdate($TournamentMatchUpdateInput: TournamentMatchUpdateInput!) {
        TournamentMatchUpdate(input: $TournamentMatchUpdateInput) {
          status {
            success
            name
            detail
          }
          Match {
            id
            tournamentId
            roundId
            table
            reported
            draw
            dropPlayer
            dropOpponent
            winnerVictories
            loserVictories
            playerId
            Player {
              id
              Person {
                id
                avatar
                fullName
                urlAvatar
                code
              }
            }
            opponentId
            Opponent {
              id
              Person {
                id
                avatar
                fullName
                urlAvatar
                code
              }
            }
            Winner {
              id
              Person {
                id
                avatar
                fullName
                urlAvatar
                code
              }
            }
            Loser {
              id
              Person {
                id
                avatar
                fullName
                urlAvatar
                code
              }
            }
          }
        }
      }
    `
    const variables = {
      TournamentMatchUpdateInput: {
        id: data.id,
        tournamentId: data.tournamentId,
        playerId: data.playerId,
        opponentId: data.opponentId,
        reset: data.reset
      }
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then(response => {
        const { TournamentMatchUpdate } = response.data.data
        return TournamentMatchUpdate
      })
      .catch(err => axiosError(err))
  }
}

export default TournamentMatchUpdate
