// @External Dependencies
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { Jumbotron } from 'reactstrap'

// @Dependencies
import { FormatCreate, getUser, getGames } from '../../Redux/Actions'
import { Button, LoadingSVG } from '../../Components/UI'
import { axiosAbort } from '../../Services'

import FormFormat from './Form'

// @Component
class FormatCreateView extends Component {
  state = {
    alertContent: '',
    alertState: 'warning',
    loading: true,
    errors: true,
    Format: {
      name: '',
      description: '',
      status: true,
      gameId: 0
    },
    Games: []
  }

  async componentDidMount () {
    await this.props.getUser()
    if (!this.props.loggedUser.isAdmin) {
      this.setState({
        loading: true,
        loadingMessage: 'Solo administradores pueden ver esta sección.',
        buttonBack: '/'
      })
    } else {
      const Games = await this.props.getGames()
      this.setState({
        loading: false,
        Games: Games.map(game => {
          return {
            id: game.id,
            name: game.name,
            status: false
          }
        })
      })
    }
  }

  componentWillUnmount () {
    axiosAbort()
  }

  onSubmit = async event => {
    event.preventDefault()
    const { FormatCreate, history } = this.props
    const { Format, Games } = this.state

    this.setState({
      alertContent: 'Estamos creando el tipo de torneo torneo...',
      loading: true
    })

    const { errors } = this.state
    /**
     * Si no hay errores de validación entonces cremos el formato de juego
     */
    if (!errors) {
      const resolver = await FormatCreate(Format, Games)
      if (!resolver.status.success) {
        this.setState({
          alertContent: resolver.status.detail
        })
        setTimeout(() => {
          this.setState({ loading: false })
        }, 2000)
      } else {
        /**
         * Si se registra correctamente el formato entonces redireccionamos a su detalle
         */
        this.setState({
          alertContent: resolver.status.name,
          alertState: 'success',
          errors: true
        })
        setTimeout(() => {
          history.push('/formats')
        }, 3000)
      }
    } else {
      this.setState({
        errors,
        alertContent: 'Hay errores en el formulario',
        loadingRegister: false
      })
    }
  }

  /**
   * Controla los cambios de estados de los inputs
   */
  onChange = async e => {
    const { Format } = this.state
    const target = e.target

    let { errors } = this.state
    if (target && target.id === 'name') {
      Format.name = target.value.trim()
      errors = !(Format.name.length > 0)
    }

    if (target && target.id === 'description') {
      Format.description = target.value.trim()
      errors = !(Format.description.length > 0)
    }

    if (target && target.id === 'gameId') {
      if (target.value.length > 0) {
        Format.gameId = parseInt(target.value, 10)
      }
    }

    this.setState({ Format, errors })
  }

  onChangeModalToggle = e => {
    e.preventDefault()
    const target = e.target
    const { Format } = this.state

    if (target.id === 'status') {
      Format.status = !Format.status
    }
    const gamesToggle = this.state.Games.map(game => {
      const replaceId = Number(target.id.replace('game', ''))
      if (game.id === replaceId) {
        game.status = !game.status
      }
      return game
    })

    this.setState({ Format, Games: gamesToggle })
  }

  handleDismissAlert = () => {
    this.setState({ alertContent: '' })
  }

  /**
   * Render vista
   */
  render () {
    const { loading, alertContent, errors } = this.state
    const { t } = this.props
    if (loading) return <LoadingSVG message={alertContent} />
    return (
      <Wrapper>
        <div className='BaseContent'>
          <div className='BaseLeft' />

          <div className='BaseRight'>
            {this.props.loggedUser.isAdmin && (
              <Link to='/formats' className='padding-20'>
                <i className='fa fa-arrow-left' /> Listado de Formatos
              </Link>
            )}
            <div className='BaseRight__Form'>
              <Jumbotron>
                <h1>{t('Registro nuevo Formato de Juego')}</h1>
                <p>
                  {t(
                    'Ingrese todos los datos del formulario para inscribir un nuevo Formato de Juego.'
                  )}
                </p>
                <FormFormat
                  {...this.props}
                  {...this.state}
                  onSubmit={this.onSubmit}
                  onChange={this.onChange}
                  handleDismissAlert={this.handleDismissAlert}
                  onChangeModalToggle={this.onChangeModalToggle}
                />

                <div className='BaseRight__Bottom__actions'>
                  <Button
                    id='btn_Tournament1'
                    disabled={loading || errors}
                    type='submit'
                    text={t('Crear Tipo Torneo')}
                    state='primary'
                    form='FormCreate'
                    className='btn-register'
                    style={{ margin: 'auto' }}
                  />
                </div>
              </Jumbotron>
            </div>
          </div>
        </div>
      </Wrapper>
    )
  }
}

// @Proptypes
FormatCreateView.propTypes = {
  loggedUser: PropTypes.object
}

/*
  @Store Connection: connect
  @Export Component
*/

const mapStateToProps = state => ({
  loggedUser: state.loggedUser
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      FormatCreate,
      getGames,
      getUser
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(FormatCreateView))

// @Styles
const Wrapper = styled.div.attrs({ className: 'Tournament' })`
  height: inherit;
`
