// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { getToken, axiosError } from '../../../Services'

export default data => {
  const requestId = 'GameRegister'
  return async dispatch => {
    getToken()
    const query = `
      mutation GameRegister($GameRegisterInput: GameRegisterInput!) {
        GameRegister(input: $GameRegisterInput) {
          status {
            success
            name
            detail
          }
          Game {
            id
            name
            urlUpdate
          }
        }
      }
    `
    const variables = {
      GameRegisterInput: {
        name: data.name,
        description: data.description
      }
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables }, { requestId })
      .then(response => {
        return response.data.data.GameRegister
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}
