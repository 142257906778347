export default {
  firstQuestion: [
    {
      id: 1,
      name: 'Muy mala organización de la Tienda'
    },
    {
      id: 2,
      name: 'La organización de la Tienda podría mejorar'
    },
    {
      id: 3,
      name: 'La organización de la Tienda fue suficiente'
    },
    {
      id: 4,
      name: 'Muy buena organización de la Tienda'
    },
    {
      id: 5,
      name: 'Excelente organización de la Tienda'
    }
  ],
  secondQuestion: [
    {
      id: 1,
      name: 'Muy mal ambiente de juego en la Tienda'
    },
    {
      id: 2,
      name: 'El ambiente en la Tienda podría ser mejor'
    },
    {
      id: 3,
      name: 'El ambiente de la Tienda no era ni bueno ni malo'
    },
    {
      id: 4,
      name: 'La Tienda tiene un buen ambiente de juego'
    },
    {
      id: 5,
      name:
        'La Tienda tiene una excelente comunidad y un cálido ambiente de juego'
    }
  ],
  thirdQuestion: [
    {
      id: 1,
      name: 'No la recomiendo'
    },
    {
      id: 2,
      name: 'Poco probable que la recomiende'
    },
    {
      id: 3,
      name: 'Es probable que la recomiende'
    },
    {
      id: 4,
      name: 'Muy probable que la recomiende'
    },
    {
      id: 5,
      name: 'La recomiendo completamente'
    }
  ]
}
