// @Dependencies
import { SET_MEDIAQUERY } from '../../../constants'

const setMediaquery = value => {
  return dispatch => {
    dispatch({
      type: SET_MEDIAQUERY,
      payload: value
    })
  }
}

export default setMediaquery
