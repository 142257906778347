// Imagenes de error
const error = require('./error')
const login = require('./login')

/**
 * Export module
 */
module.exports = {
  error,
  login
}
