// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { axiosError, getToken } from '../../../Services'

const requestId = 'getUser'

const getUser = personId => {
  return dispatch => {
    const token = getToken()
    if (!token) {
      return {}
    }

    const query = `
      query Person {
        Person(id: ${Number(personId)}) {
          id
          name
          firstSurname
          lastSurname
          fullName
          birthday
          genderId
          categoryId
          code
          active
          email
          phone
          avatar
          urlAvatar
          Address {
            id
            name
            address
            countryId
            regionId
            locationId
            modified
            Country {
              id
              name
            }
            Region {
              id
              fullName
            }
            Location {
              id
              name
            }
          }
          PersonCategory {
            id
            name
          }
          Gender {
            id
            name
          }
          Store {
            id
            name
            active
            urlView
          }
        }
      }

    `

    return axios
      .post(`${apiURL}/graphql`, { query }, { requestId })
      .then(response => {
        if (response.status === 200 && response.data.data.Person) {
          return response.data.data
        }
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}

export default getUser
