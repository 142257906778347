import { UIVars } from '../'

const fontSize = (obj, fontSizeBase, className = false) => {
  /**
    @param {object} type
    @param {number} fontSizeBase
    @param {bool} className
  */
  let css = ''
  Object.keys(obj).forEach(key => {
    const hadClassName = className ? '&.' : ''
    const attribute = hadClassName + key
    css =
      css +
      `
    ${attribute}{ font-size: ${obj[key] / fontSizeBase}rem;}
    `
  })
  return css
}

const Text = `
  body, html {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6, p{
    &.active{
      color: ${UIVars.setColors.deepSkyBlue}
    }
    &.disabled{
      color: ${UIVars.setColors.brownGreyTwo}
    }
  }

  ${fontSize(UIVars.hSize.normal, UIVars.fontSize)}

  ${fontSize(UIVars.hSize.normal, UIVars.fontSize, true)}

  .article {
    ${fontSize(UIVars.articleH.normal, UIVars.fontSize)}
  }

  .tab{
    &.active{
      color: ${UIVars.setColors.deepSkyBlue}
    }
    &.disabled{
      color: ${UIVars.setColors.brownGreyTwo}
    }
  }

  hr {
    border-top-color: #f5f5fb;
    margin: 20px 0px;
  }

  p {
    font-size: ${UIVars.pSize.medium / UIVars.fontSize}rem;
    ${fontSize(UIVars.pSize, UIVars.fontSize, true)}
  }

  a {
    color: ${UIVars.setColors.blackTwo};
    ${fontSize(UIVars.pSize, UIVars.fontSize, true)}

    &:hover {
      color: ${UIVars.setColors.primary};
      text-decoration: none;
    }
  }

  .alert{
    ${fontSize(UIVars.alertFontSize, UIVars.fontSize, true)}
    &.warning{
      background: #856404;
    }
    &.info{
      background: ${UIVars.setColors.deepSeaBlue};
    }
  }


  @media screen and (max-width: 480px) {
    body, html {
      // font-size: 14px;
    }
    ${fontSize(UIVars.hSize.mobile, UIVars.fontSizeMobile)}
    .article {
      ${fontSize(UIVars.articleH.mobile, UIVars.fontSizeMobile)}
    }
  }
`

export default Text
