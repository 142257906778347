// @External Dependencies
import React from 'react'
import styled from 'styled-components'
import { Col } from 'reactstrap'
import { Alert, Toggle, Input, Select, Textarea } from '../../Components/UI'

const FormTournamentType = ({
  alertContent,
  alertState,
  loading,
  t,
  TournamentType,
  image,
  onSubmit,
  onChange,
  handleDismissAlert,
  onChangeModalToggle,
  uploadImage
}) => {
  return (
    <Wrapper>
      <form onSubmit={e => onSubmit(e)} acceptCharset='utf-8' id='FormCreate'>
        {alertContent.length > 0 && (
          <Alert color={alertState} close={e => handleDismissAlert(e)}>
            {alertContent}
          </Alert>
        )}
        <hr />
        <h2>Datos del Torneo</h2>
        <Input
          disabled={loading}
          inputSize={9}
          labelSize={3}
          name='name'
          placeholder={t('Nombre')}
          onChange={e => onChange(e)}
          value={TournamentType.name}
          type='input'
          label={t('Nombre')}
          required
        />
        <Textarea
          disabled={loading}
          inputSize={9}
          labelSize={3}
          name='description'
          placeholder={t('Descripción')}
          label={t('Descripción')}
          onChange={e => onChange(e)}
          value={TournamentType.description}
          required
        />
        <Input
          disabled={loading}
          inputSize={9}
          labelSize={3}
          label={'Imagen'}
          name='image'
          onChange={e => uploadImage(e)}
          type='file'
        />
        {image && (
          <div>
            <img
              src={image}
              alt={TournamentType.name}
              style={{ width: '60%', margin: 'auto', display: 'block' }}
              className='img-thumbnail'
            />
          </div>
        )}
        <Toggle
          label='Estado'
          name='status'
          labelSize={3}
          onChange={e => onChangeModalToggle(e)}
          checked={TournamentType.status}
        />

        <Toggle
          label='Privado'
          name='private'
          labelSize={3}
          onChange={e => onChangeModalToggle(e)}
          checked={TournamentType.private}
        />
        <Toggle
          label='Requiere link Sociales'
          name='socialRequired'
          labelSize={3}
          onChange={e => onChangeModalToggle(e)}
          checked={TournamentType.socialRequired}
        />

        <Input
          disabled={loading}
          name='minPlayers'
          label='Jugadores mínimos'
          onChange={e => onChange(e)}
          type='number'
          min={2}
          value={TournamentType.minPlayers}
          required
          inputSize={9}
          labelSize={3}
        />

        <Select
          disabled={loading}
          inputSize={9}
          labelSize={3}
          name='maxTopPlayers'
          label={t('Máximo en TOP')}
          onChange={e => onChange(e)}
          select={TournamentType.maxTopPlayers}
          options={[0, 2, 4, 8, 16, 32, 64].map(p => {
            return {
              id: p,
              name: `${p} jugadores`
            }
          })}
        />
        <hr />
        <h2>Puntajes</h2>
        <p>
          Define los puntajes por ronda que dará al jugador el Tipo de Torneo
        </p>
        <Col>
          <Select
            disabled={loading}
            inputSize={9}
            labelSize={3}
            name='multiplier'
            label={t('Multiplicador al Ranking')}
            onChange={e => onChange(e)}
            select={TournamentType.multiplier}
            options={[0, 1, 2, 3].map(p => {
              return {
                id: p,
                name: `${p} x`
              }
            })}
            required
          />

          <Select
            disabled={loading}
            inputSize={9}
            labelSize={3}
            name='winnerPoints'
            label={t('Partida ganada')}
            onChange={e => onChange(e)}
            select={TournamentType.winnerPoints}
            options={[0, 1, 2, 3, 4, 5, 6].map(p => {
              return {
                id: p,
                name: `${p} pts.`
              }
            })}
            required
          />
          <Select
            disabled={loading}
            inputSize={9}
            labelSize={3}
            name='drawPoints'
            label={t('Partida empatada')}
            onChange={e => onChange(e)}
            select={TournamentType.drawPoints}
            options={[0, 1, 2].map(p => {
              return {
                id: p,
                name: `${p} pts.`
              }
            })}
            required
          />
          <Select
            disabled={loading}
            inputSize={9}
            labelSize={3}
            name='loserPoints'
            label={t('Partida perdida.')}
            onChange={e => onChange(e)}
            select={TournamentType.loserPoints}
            options={[0, 1, 2].map(p => {
              return {
                id: p,
                name: `${p} pts.`
              }
            })}
            required
          />
        </Col>
        <hr />
        <h2>Tiendas</h2>
        <p>
          Define cuantos van a ser los torneos por defecto que puede crear una
          tienda en un mes.
        </p>
        <Col>
          <Input
            disabled={loading}
            name='storeTournaments'
            label={t('Torneos')}
            onChange={e => onChange(e)}
            type='number'
            min={0}
            max={99}
            value={TournamentType.storeTournaments || 0}
            inputSize={9}
            labelSize={3}
            required
          />
        </Col>
        <Col>
          <Toggle
            label='Permite fechas dobles'
            name='canBeDouble'
            labelSize={3}
            onChange={e => onChangeModalToggle(e)}
            checked={TournamentType.canBeDouble > 0}
          />
        </Col>
      </form>
    </Wrapper>
  )
}

export default FormTournamentType

// @Styles
const Wrapper = styled.section`
  .img-thumbnail {
    max-width: 480px;
  }
`
