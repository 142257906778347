// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL, GET_USER } from '../../../constants'
import { axiosError, getToken } from '../../../Services'

const requestId = 'getUser'

const getUser = () => {
  return dispatch => {
    const token = getToken()
    if (!token) {
      return {}
    }

    const query = `
      query {
        UserInfo {
          User {
            id
            Person {
              id
              name
              firstSurname
              lastSurname
              fullName
              nickname
              email
              phone
              emailValid
              birthday
              active
              code
              categoryId
              codeOld
              genderId
              created
              avatar
              urlAvatar
              PersonProfiles {
                profileId
              }
              Gender {
                name
              }
              Store {
                id
                name
                description
                logo
                cover
                phone
                email
                active
                siteUrl
                facebookUrl
                instagramUrl
                twitterUrl
                youtubeUrl
                urlView
                urlTournaments
                urlPastTournaments
                Person {
                  id
                  avatar
                  fullName
                  urlAvatar
                  code
                }
                Address {
                  id
                  name
                  address
                  countryId
                  regionId
                  locationId
                  Country {
                    iso
                    name
                  }
                  Region {
                    fullName
                  }
                  Location {
                    name
                  }
                }
                Level {
                  name
                }
                StoreTournamentTypes {
                  typeId
                  quantity
                }
              }
              Address {
                id
                name
                address
                countryId
                regionId
                locationId
                modified
                Country {
                  id
                  name
                  iso
                }
                Region {
                  id
                  name
                  alias
                  fullName
                }
                Location { name }
              }
            }
          }
        }
      }
    `

    return axios
      .post(`${apiURL}/graphql`, { query }, { requestId })
      .then(response => {
        if (response.status === 200 && response.data.data.UserInfo.User) {
          const person = {
            id: response.data.data.UserInfo.User.id,
            user: response.data.data.UserInfo.User.Person
          }

          dispatch({
            type: GET_USER,
            payload: person
          })

          return 'SET_USER_OK'
        }
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}

export default getUser
