const initialState = {}

// @Export Reducer
export const countries = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case 'LIST_COUNTRIES':
      return {
        ...payload
      }
    default:
      return state
  }
}
