// @External Dependencies
import React from 'react'
import styled from 'styled-components'
import { Alert, Input, Textarea } from '../../Components/UI'

const FormGame = ({
  alertContent,
  alertState,
  loading,
  Game,
  image,
  onSubmit,
  onChange,
  handleDismissAlert,
  uploadImage,
  isEdit = false
}) => {
  return (
    <Wrapper>
      <form onSubmit={e => onSubmit(e)} acceptCharset='utf-8' id='FormCreate'>
        {alertContent.length > 0 && (
          <Alert color={alertState} close={e => handleDismissAlert(e)}>
            {alertContent}
          </Alert>
        )}
        <hr />
        <h2>Datos del Juego</h2>
        <Input
          disabled={loading}
          inputSize={9}
          labelSize={3}
          name='name'
          placeholder='Nombre'
          onChange={e => onChange(e)}
          value={Game.name}
          type='input'
          label='Nombre'
          required
        />
        <Textarea
          disabled={loading}
          inputSize={9}
          labelSize={3}
          name='description'
          placeholder='Descripción'
          label='Descripción'
          onChange={e => onChange(e)}
          value={Game.description}
        />

        {isEdit && (
          <div>
            <Input
              disabled={loading}
              inputSize={9}
              labelSize={3}
              label='Imagen'
              name='image'
              onChange={e => uploadImage(e)}
              type='file'
            />
            {image && (
              <img
                src={image}
                alt={Game.name}
                style={{ width: '60%', margin: 'auto', display: 'block' }}
                className='img-thumbnail'
              />
            )}
          </div>
        )}
      </form>
    </Wrapper>
  )
}

export default FormGame

// @Styles
const Wrapper = styled.section`
  .img-thumbnail {
    max-width: 480px;
  }
`
