// @External Dependencies
import React from 'react'
import PropTypes from 'prop-types'
import CircularProgressbar from 'react-circular-progressbar'
import styled from 'styled-components'

// @Dependencies

// @Component
const Circularbar = ({ percentage, strokeWidth, strokeLinecap }) => (
  <CircularProgressbarStyle>
    <CircularProgressbar
      percentage={percentage}
      strokeWidth={strokeWidth}
      styles={{
        path: {
          strokeLinecap
        }
      }}
    />
  </CircularProgressbarStyle>
)

// @Proptypes
Circularbar.propTypes = {
  defaultOption: PropTypes.string,
  strokeWidth: PropTypes.number,
  strokeLinecap: PropTypes.string
}

Circularbar.defaultProps = {
  strokeWidth: 15,
  strokeLinecap: 'butt'
}

// @Export Component
export default Circularbar

const CircularProgressbarStyle = styled.div``
