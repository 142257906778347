// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../constants'
import { getToken, axiosError } from '../../../../Services'

const RoundStanding = roundId => {
  return dispatch => {
    getToken()
    const tournamentInfo = `
      Tournament {
        id
        name
        urlView
        urlAddPlayers
        urlRounds
        urlStandings
        startDate
        rounds
        validDates
        isPresential
        statusId
        TournamentOrganizers {
          personId
        }

        Store {
          id
          name
          urlView
          Level {
            id
            name
            description
          }
        }
        Format {
          name
          isFullRacial
        }
        TournamentStatus {
          id
          name
        }
        TournamentType {
          name
          description
          winnerPoints
          loserPoints
          drawPoints
          multiplier
          urlImage
        }
        TournamentStatus {
          name
          description
          style
        }
        Season {
          id
          name
          rankingUrl
        }
        Game {
          id
          name
          urlImage
          RaceGames {
            raceId
            sortOrder
            Race {
              name
            }
          }
        }
        TotalTournamentRounds {
          id
          name
          sortOrder
          urlView
          urlStanding
          TournamentRoundStatus {
            id
            name
          }
        }
      }
    `
    const tournamentRound = `
      TournamentRound {
        name
        sortOrder
        urlView
        urlStanding
        TournamentRoundType {
          id
          name
        }
        TournamentRoundStatus {
          id
          name
        }
      }
    `
    const query = `
      query TournamentStanding($TournamentStandingInput: TournamentStandingInput!) {
        TournamentStanding(input: $TournamentStandingInput) {
          status {
            success
            name
            detail
          }
          ${tournamentInfo}
          ${tournamentRound}
          Standing {
            id
            playerId
            position
            points
            omw
            pgw
            ogw
            TournamentPlayer {
              id
              drop
              raceId
              TournamentRoundDrop {
                name
              }
              Person {
                id
                avatar
                fullName
                urlAvatar
                code
              }
            }
          }
        }
      }
    `
    const variables = {
      TournamentStandingInput: {
        roundId: parseInt(roundId, 10),
        drop: true
      }
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then(response => {
        const { TournamentStanding } = response.data.data
        return TournamentStanding
      })
      .catch(err => axiosError(err))
  }
}

export default RoundStanding
