// @External Dependencies
import React from 'react'
import Tooltip from 'reactstrap/lib/Tooltip'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// @Dependencies
import variables from '../variables'

// @Component
const Button = ({
  id,
  border,
  disabled,
  form,
  icon,
  link,
  style,
  blank,
  onClick,
  size,
  state,
  text,
  tooltip,
  type,
  className
}) => {
  const renderButton = () => (
    <StyledButton
      id={id}
      className={className}
      border={border}
      disabled={disabled}
      form={form}
      onClick={onClick}
      size={size}
      state={state}
      type={type}
      style={style}>
      {icon && (
        <StyledIcon
          icon={icon}
          text={text}
          className={`nucleo-icon nucleo-icon-${icon}`}
        />
      )}
      {text}
    </StyledButton>
  )

  if (link && !tooltip) {
    return blank ? (
      <a target='_blank' rel='noopener noreferrer' href={link}>
        {renderButton()}
      </a>
    ) : (
      <Link to={link}>{renderButton()}</Link>
    )
  } else if (!link && tooltip) {
    return (
      <Tooltip
        placement='right'
        isOpen={this.state.tooltipOpen}
        target='TooltipExample'
        toggle={this.toggle}>
        {renderButton()}
      </Tooltip>
    )
  } else if (link && tooltip) {
    return blank ? (
      <a target='_blank' rel='noopener noreferrer' href={link}>
        <Tooltip
          placement='right'
          isOpen={this.state.tooltipOpen}
          target='TooltipExample'
          toggle={this.toggle}>
          {renderButton()}
        </Tooltip>
      </a>
    ) : (
      <Link to={link}>
        <Tooltip
          placement='right'
          isOpen={this.state.tooltipOpen}
          target='TooltipExample'
          toggle={this.toggle}>
          {renderButton()}
        </Tooltip>
      </Link>
    )
  } else {
    return renderButton()
  }
}

// @Proptypes
Button.propTypes = {
  border: PropTypes.bool,
  disabled: PropTypes.bool,
  form: PropTypes.string,
  icon: PropTypes.string,
  link: PropTypes.string,
  blank: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.string,
  state: PropTypes.string,
  text: PropTypes.string,
  tooltip: PropTypes.string,
  type: PropTypes.string
}

Button.defaultProps = {
  state: 'primary',
  text: 'Button'
}

// @External Dependencies
export default Button

// @Styles
const StyledButton = styled.button`
  background: ${props => buttonBackground(props)};
  border-radius: 4px;
  border: ${props => (props.size === 'sm' ? '1px' : '2px')} solid
    ${props => (props.border ? buttonColor(props) : buttonBackground(props))};
  color: ${props => buttonColor(props)};
  cursor: pointer;
  // display: flex;
  justify-content: center;
  ${props => buttonSize(props.size)}
  margin-right: 10px;
  padding: 0 10px;
  text-align: center;
  transition: all 0.25s;

  ${props => style(props)}

  &:active,
  &:focus {
    outline: none;
  }
  &[disabled] {
    cursor: no-drop;
    opacity: 0.4;
  }

  &:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    color: white;
    &[disabled] {
      cursor: no-drop;
      opacity: 0.4;
    }
  }
`
const StyledIcon = styled.span`
  line-height: 0;
  margin-right: ${props => (props.icon && props.text ? '5px' : '0')};
  vertical-align: middle;
`

const buttonColor = props => {
  if (props.border) {
    return buttonState(props.state)
  } else if (props.state === 'link') {
    return variables.setColors.primary
  } else {
    return 'white'
  }
}

const style = props => {
  if (props.style) {
    return props.style
  }
}

const buttonBackground = props => {
  if (props.border || props.state === 'link') {
    return 'transparent'
  } else {
    return buttonState(props.state)
  }
}

const buttonState = state => {
  let color

  switch (state) {
    case 'primary':
      color = variables.setColors.primary
      break
    case 'info':
      color = variables.setColors.info
      break
    case 'success':
      color = variables.setColors.buttons.success
      break
    case 'warning':
      color = variables.setColors.warning
      break
    case 'danger':
      color = variables.setColors.danger
      break
    default:
      color = variables.setColors.default
  }
  return color
}

const buttonSize = size => {
  let btnSize

  switch (size) {
    case 'lg':
      btnSize = { height: '30px', fontSize: '14px', width: 'auto' }
      break
    case 'sm':
      btnSize = { height: '30px', fontSize: '14px', width: 'auto' }
      break
    case 'xs':
      btnSize = { height: '30px', fontSize: '14px', width: 'auto' }
      break
    default:
      btnSize = { height: '38px', fontSize: '1rem', width: '180px' }
  }
  return btnSize
}
