// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { getToken, axiosError } from '../../../Services'

const getFormatList = () => {
  const requestId = 'getFormatList'
  return dispatch => {
    getToken()

    const query = `
      query {
        allFormats(paginate: 999, orderBy: name_ASC) {
          id
          name
          description
          image
          sortOrder
          status
          deckbuilder
          maxCopies
          minDeck
          maxDeck
          sideboard
          extraCards
          urlUpdate
          totalTournaments
          FormatGames {
            gameId
            formatId
            Game {
              id
              name
            }
          }
        }
      }
    `

    return axios
      .post(`${apiURL}/graphql`, { query }, { requestId })
      .then(response => {
        if (response.status === 200 && response.data.data.allFormats) {
          return response.data.data.allFormats
        } else {
          return []
        }
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}

export default getFormatList
