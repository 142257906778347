// @External Dependencies
import React, { Component } from 'react'
// import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import validator from 'validator'
import 'moment/locale/es'
import moment from 'moment'
import { Link } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'
import { bgBackground } from '../../../assets/img/login'
import { Jumbotron, Col, Container } from 'reactstrap'
import RelatedTournament from './RelatedTournaments'
// @Dependencies
import {
  TournamentEdit,
  getTournamentInfo,
  getFormats,
  getTournamentTypes,
  GameList,
  getUser,
  getSeasons,
  UploadImage,
  getStoreList
} from '../../Redux/Actions'
import {
  Alert,
  Button,
  Input,
  Select,
  Textarea,
  LoadingSVG,
  Datapicker
} from '../../Components/UI'
import { axiosAbort } from '../../Services'
import TournamentMenu from './TournamentMenu'

// @Component
class TournamentEditView extends Component {
  state = {
    alertContent: '',
    alertState: 'danger',
    loading: false,
    loadingEdit: true,
    havePermissions: false,
    tournament: {
      name: '',
      description: '',
      image: '',
      eventUrl: '',
      gameId: 0,
      addressId: 0,
      /** Temporada de juego. */
      seasonId: 0,
      typeId: 0,
      formatId: 0,
      maxPlayers: 0,
      rounds: 2,
      startDate: '0000-00-00 00:00',
      storeId: 0,
      validDates: 1,
      private: 0,
      isPresential: 1
    },
    tournamentRelateds: [],
    socialRequired: false,
    games: [],
    seasons: [],
    formats: [],
    types: [],
    stores: [],
    typesRequiredUrl: [],
    maxPlayers: [],
    addresses: [],
    rounds: [],
    errors: false,
    coverImage: '',
    TournamenDoubleDates: [],
    validDates: [
      {
        id: 1,
        name: 'Simple ✋ Sólo descuenta un torneo mensual de los disponibles'
      },
      {
        id: 2,
        name: 'Doble 🙌  Es válido por dos torneos mensuales de los disponibles'
      }
    ],
    visibilities: [
      {
        id: 0,
        name: '🟢 Público'
      },
      {
        id: 1,
        name: '🔴 Privado'
      }
    ],
    presentials: [
      {
        id: 1,
        name: '📍 Torneo Presencial'
      },
      {
        id: 0,
        name: '🖥️ Torneo Remoto'
      }
    ]
  }

  /**
   * - Al iniciar busca los juegos, formatos, tipos de torneo
   * - Si no tengo permisos para crear torneos entonces mostramos alerta y enviamos al home
   */
  async componentDidMount () {
    await this.props.getUser()
    const {
      GameList,
      getFormats,
      getTournamentTypes,
      getTournamentInfo,
      history,
      match
    } = this.props

    const tournament = await getTournamentInfo(match.params)
    if (tournament.status.success) {
      const {
        loggedUser: { isAdmin },
        loggedUser
      } = this.props
      const havePermissions =
        loggedUser.user && loggedUser.user.Store
          ? tournament.storeId === loggedUser.user.Store.id ||
            loggedUser.isAdmin
          : tournament.TournamentOrganizers.some(organizer => {
              if (loggedUser.user) {
                return organizer.personId === loggedUser.user.id
              }
              return false
            })

      /**
       * Si tiene permisos para editar la tienda
       */
      let loadingEdit = false
      let alertContent = ''
      /**
       * Si no tiene permisos entonces lo mandamos al home
       */
      if (!havePermissions) {
        loadingEdit = true
        alertContent =
          loggedUser.user.Store && !loggedUser.user.Store.active
            ? 'Tu tienda no está activa aún para editar torneos.'
            : 'Sólo perfiles dueños de tienda pueden editar torneos.'
        setTimeout(() => {
          history.push(tournament.urlView)
        }, 2000)
      }
      if (!tournament.TournamentStatus.edit && !isAdmin) {
        alertContent =
          'El estado del torneo no permite que se edite su configuración básica.'
        loadingEdit = true
        setTimeout(() => {
          history.push(tournament.urlView)
        }, 2000)
      }
      const { formats } = await getFormats()
      if (havePermissions) {
        const { address, Region, Country } = tournament.Address
        const addresses = [
          {
            id: address.id,
            name: `${address}, ${Region.fullName}, ${Country.name}`
          }
        ]
        const TournamentTypes = await getTournamentTypes(tournament.storeId)
        const typesRequiredUrl = TournamentTypes.filter(
          ({ socialRequired }) => socialRequired
        )

        const types = TournamentTypes.map(t => {
          const total = t.storeTournaments
          return {
            id: t.id,
            name: `${total} por mes | ${t.name}`,
            total
          }
        }).sort((a, b) => b.total - a.total)

        const TournamenDoubleDates = TournamentTypes.filter(
          ({ canBeDouble }) => canBeDouble > 0
        )

        const startDate = moment(tournament.startDate).format(
          'YYYY-MM-DD kk:mm'
        )
        const tournamentData = {
          id: tournament.id,
          name: tournament.name,
          description: tournament.description,
          image: tournament.image,
          eventUrl: tournament.eventUrl,
          addressId: tournament.addressId,
          typeId: tournament.typeId,
          gameId: tournament.gameId,
          seasonId: tournament.seasonId,
          formatId: tournament.formatId,
          maxPlayers: tournament.maxPlayers,
          rounds: tournament.rounds,
          startDate,
          storeId: tournament.storeId,
          validDates: tournament.validDates,
          private: tournament.private,
          isPresential: tournament.isPresential,
          Store: tournament.Store
        }
        const { Stores } = await this.props.getStoreList({
          active: 1,
          limit: 3000
        })
        const { seasons } = await this.props.getSeasons()
        const games = await GameList(tournament.storeId)
        this.setState({
          stores: Stores.filter(store => store.StoreGames.length).map(
            ({ id, name }) => {
              return { id, name }
            }
          ),
          seasons: seasons
            .map(({ id, name }) => {
              return { id, name }
            })
            .filter(f => f.id === tournament.seasonId),
          tournament: tournamentData,
          tournamentData: tournament,
          tournamentRelateds: tournament.TournamentClasifications.map(t => {
            return {
              id: t.id,
              quotas: t.quotas,
              relatedId: t.relatedId,
              tournamentId: t.tournamentId,
              name: t.TournamentRelated.name
            }
          }),
          coverImage: tournament.urlImage,
          loadingEdit,
          alertContent,
          isAdmin,
          games: games.Games,
          formats: formats.map(({ id, name, FormatGames }) => {
            return { id, name, FormatGames }
          }),
          typesRequiredUrl,
          types,
          addresses,
          TournamenDoubleDates
        })
      }

      this.calcRounds()
      this.setState({
        havePermissions,
        loading: false
      })
    } else {
      const detailError = tournament.status.detail
      this.setState({
        buttonBack: '/tournaments',
        alertContent: `No se pudieron encontrar los datos del torneo. <br /> "${detailError}"`
      })
    }
  }

  componentWillUnmount () {
    axiosAbort()
  }

  /**
   * Calcula el total de rondas en base al maximo de jugadores
   */
  calcRounds (totalPlayers) {
    const { tournament } = this.state
    const maxPlayers = !totalPlayers ? tournament.maxPlayers : totalPlayers
    let maxRounds = Math.ceil(Math.log2(maxPlayers))
    if (maxRounds < 2) {
      maxRounds = 2
    }

    let rounds = []
    for (let k = 2; k <= maxRounds; k++) {
      rounds.push({
        id: k,
        name: `${k} Rondas`
      })
    }
    this.setState({ rounds })
  }

  onSubmit = async event => {
    event.preventDefault()
    const { TournamentEdit, history } = this.props
    const { tournament } = this.state

    this.setState({
      alertContent: 'Estamos actualizando el torneo...',
      loading: true,
      loadingEdit: true
    })

    let { errors } = this.state
    if (
      tournament.eventUrl.length > 0 &&
      !validator.isURL(tournament.eventUrl)
    ) {
      errors = true
    }

    /**
     * Si no hay errores de validación entonces editamos el Torneo
     */
    if (!errors) {
      const resolver = await TournamentEdit(tournament)
      if (!resolver.status.success) {
        this.setState({
          alertContent: resolver.status.detail,
          loading: false,
          loadingEdit: false
        })
      } else {
        /**
         * Si se registra correctamente el torneo entonces redireccionamos a su detalle
         */
        this.setState({
          alertContent: resolver.status.name,
          alertState: 'success'
        })

        setTimeout(async () => {
          history.push(
            `/tournament/detail/${resolver.tournament.id}/${
              resolver.tournament.name
            }`
          )
        }, 3000)
      }
    } else {
      this.setState({
        errors,
        alertContent: 'Hay errores en el formulario',
        loadingEdit: false
      })
    }
  }

  upload = e => {
    const files = e.target.files
    const file = files[0]
    if (['image/jpeg', 'image/png'].includes(file.type)) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = async e => {
        const response = await this.props.UploadImage({
          file: e.target.result,
          name: files[0].name,
          type: 'tournament.image',
          tournamentId: this.state.tournament.id
        })

        if (response.status.success) {
          this.setState({
            coverImage: response.Tournament.urlImage
          })
        }
      }
    } else {
      alert('Error en formato, sólo se permiten JPG o PNG')
    }
  }

  /**
   * Controla los cambios de estados de los inputs
   */
  onChange = async e => {
    const { tournament } = this.state
    const target = e.target
    let { socialRequired, games } = this.state

    if (target && target.id === 'name') {
      tournament.name = target.value
    }
    if (target && target.id === 'description') {
      tournament.description = target.value
    }
    if (target && target.id === 'typeId') {
      tournament.typeId = Number(target.value)
      socialRequired =
        this.state.typesRequiredUrl.filter(f => {
          return tournament.typeId === f.id
        }).length > 0
      tournament.validDates = 1
    }
    if (target && target.id === 'formatId') {
      tournament.formatId = Number(target.value)
    }
    if (target && target.id === 'validDates') {
      tournament.validDates = Number(target.value)
    }
    if (target && target.id === 'startDate') {
      tournament.startDate = target.value
    }
    if (target && target.id === 'private') {
      tournament.private = Number(target.value)
    }
    if (target && target.id === 'isPresential') {
      tournament.isPresential = Number(target.value)
    }
    if (target && target.id === 'maxPlayers') {
      if (target.value) {
        tournament.maxPlayers = Number(target.value)
      } else {
        tournament.maxPlayers = 4
      }
    }
    if (target && target.id === 'rounds') {
      tournament.rounds = Number(target.value)
    }
    if (target && target.id === 'storeId') {
      tournament.storeId = Number(target.value)
      tournament.gameId = 0
      const resolveGames = await this.props.GameList(tournament.storeId)
      games = resolveGames.Games
    }
    if (target && target.id === 'gameId') {
      tournament.gameId = Number(target.value)
      tournament.formatId = 0
    }
    // Datos Sociales
    if (target && target.id === 'addressId') {
      tournament.addressId = Number(target.value)
    }
    if (target && target.id === 'eventUrl') {
      tournament.eventUrl = target.value
    }
    if (target && target.id === 'image') {
      tournament.image = target.value
    }
    this.setState({
      tournament,
      socialRequired,
      errors: false,
      games
    })
    if (target && target.id === 'maxPlayers') {
      this.calcRounds()
    }
  }

  /**
   * Controla los cambios de los Datapicker
   */
  changeDate = (e, name) => {
    let key, value
    if (name) {
      key = name
      value = e
    } else {
      key = e.target.name
      value = parseInt(e.target.value, 10)
    }
    const { tournament } = this.state
    if (key === 'startDate') {
      tournament.startDate = value
    }

    this.setState({ tournament })
  }

  render () {
    let {
      loading,
      alertContent,
      loadingEdit,
      alertState,
      tournament,
      tournamentData,
      formats,
      types,
      seasons,
      games,
      socialRequired,
      errors,
      buttonBack,
      isAdmin
    } = this.state
    const { match } = this.props

    if (loadingEdit) {
      return <LoadingSVG message={alertContent} buttonBack={buttonBack} />
    }

    const status = tournamentData.TournamentStatus

    const formatsFiltered = formats.filter(format =>
      format.FormatGames.some(f => f.gameId === tournament.gameId)
    )

    return (
      <Wrapper>
        <Container>
          <TournamentMenu
            isMobile={this.props.isMobile}
            tournament={tournamentData}
            havePermissions={true}
            match={match}
          />
          <Jumbotron>
            <h2>Editar datos básicos del torneo</h2>
            <p>
              <span>Categoría de Tienda: </span>
              <span>
                <b>{tournament.Store.Level.name}</b>
                <br />
                <i>{tournament.Store.Level.description}</i>
              </span>
            </p>
            <hr />
            <p>
              <b className={`text-${status.style}`}>{status.name}:</b>{' '}
              <i>{status.description}</i>
            </p>
            <p>
              Ingrese todos los datos del formulario para actualizar los datos
              del Torneo.
            </p>
            <form
              onSubmit={this.onSubmit}
              acceptCharset='utf-8'
              id='StoreRegisterForm'>
              {alertContent.length > 0 && (
                <Alert color={alertState} close={this.handleDismissAlert}>
                  {ReactHtmlParser(alertContent)}
                </Alert>
              )}
              <Input
                disabled={loading}
                inputSize={9}
                labelSize={3}
                name='name'
                placeholder='Nombre Torneo'
                onChange={this.onChange}
                value={tournament.name}
                type='input'
                label='Nombre'
                required
              />
              <Textarea
                disabled={loading}
                inputSize={9}
                labelSize={3}
                name='description'
                placeholder='Descripción'
                label='Descripción'
                onChange={this.onChange}
                value={tournament.description}
                required
              />
              {isAdmin && (
                <Select
                  inputSize={9}
                  labelSize={3}
                  disabled={loading}
                  name='storeId'
                  label='Tienda'
                  onChange={this.onChange}
                  select={tournament.storeId}
                  options={this.state.stores}
                  required
                />
              )}
              <Select
                inputSize={9}
                labelSize={3}
                disabled={loading}
                name='gameId'
                label='Juego'
                onChange={this.onChange}
                select={tournament.gameId}
                options={games}
                required
              />
              {!games.length && (
                <p className='text-center text-danger'>
                  La tienda no tiene juegos asociados, antes de editar el
                  torneo, asocia juegos a la tienda.{' '}
                  <Link to={`/store/update/${tournament.storeId}`}>aquí.</Link>
                </p>
              )}
              <Select
                inputSize={9}
                labelSize={3}
                disabled
                name='seasonId'
                label='Temporada de Juego'
                onChange={this.onChange}
                select={tournament.seasonId}
                options={seasons}
                required
              />
              <Select
                inputSize={9}
                labelSize={3}
                disabled={loading}
                name='typeId'
                label='Tipo Torneo'
                onChange={this.onChange}
                select={tournament.typeId}
                options={types}
                required
              />
              <Select
                inputSize={9}
                labelSize={3}
                disabled={loading}
                name='formatId'
                label='Formato Juego'
                onChange={this.onChange}
                select={tournament.formatId}
                options={formatsFiltered}
                required
              />

              <Datapicker
                disabled={loading}
                name='startDate'
                label='Fecha Realización'
                onChange={this.changeDate}
                value={tournament.startDate}
                inputSize={9}
                labelSize={3}
                required
              />
              <Select
                disabled={
                  loading ||
                  !this.state.TournamenDoubleDates.find(
                    t => t.id === tournament.typeId
                  )
                }
                inputSize={9}
                labelSize={3}
                name='validDates'
                label='Tipo de fecha'
                onChange={this.onChange}
                select={tournament.validDates}
                options={this.state.validDates}
                required
              />
              <Select
                disabled={loading || !isAdmin}
                inputSize={9}
                labelSize={3}
                name='private'
                label='Visibilidad torneo'
                onChange={this.onChange}
                select={tournament.private}
                options={this.state.visibilities}
                required
              />
              <Select
                disabled={loading || !isAdmin}
                inputSize={9}
                labelSize={3}
                name='isPresential'
                label='Modalidad del torneo'
                onChange={this.onChange}
                select={tournament.isPresential}
                options={this.state.presentials}
                required
              />

              <div>
                <h2>Datos sociales</h2>
                <p>
                  Ingresa los datos para que los jugadores puedan encontrar o
                  compartir el evento.
                </p>
                {/* <Select
                      disabled={loading}
                      inputSize={10}
                      name='addressId'
                      placeholder="Selecciona dirección"
                      label="Dirección"
                      onChange={this.onChange}
                      options={addresses}
                      select={tournament.addressId}
                      required
                    /> */}
                <Input
                  disabled={loading}
                  inputSize={9}
                  labelSize={3}
                  name='eventUrl'
                  placeholder='Url evento redes sociales'
                  label='Url Social'
                  onChange={this.onChange}
                  value={tournament.eventUrl}
                  required={socialRequired}
                />
                <Col md={12}>
                  <Input
                    disabled={loading}
                    inputSize={9}
                    labelSize={3}
                    label='Imagen promocional'
                    name='photo'
                    placeholder='Imagen promocional'
                    value=''
                    onChange={this.upload}
                    type='file'
                  />
                  {this.state.coverImage.length > 0 && (
                    <React.Fragment>
                      <img
                        src={this.state.coverImage}
                        alt={tournament.name}
                        style={{
                          margin: '0 auto',
                          display: 'block',
                          width: '30%'
                        }}
                      />
                      <br />
                    </React.Fragment>
                  )}
                </Col>
              </div>
            </form>

            <div className='BaseRight__Bottom__actions'>
              <Button
                disabled={loading || errors}
                type='submit'
                text='Actualizar Torneo'
                state='success'
                form='StoreRegisterForm'
                style={{ margin: 'auto' }}
              />
            </div>
            <br />
            {isAdmin && (
              <RelatedTournament
                tournamentId={this.state.tournament.id}
                tournaments={this.state.tournamentRelateds}
                isMobile={this.props.isMobile}
                loading={this.props.isMobile}
                onChange={this.onChange}
              />
            )}
          </Jumbotron>
        </Container>
      </Wrapper>
    )
  }
}

// @Proptypes
TournamentEditView.propTypes = {
  loggedUser: PropTypes.object
}

/*
  @Store Connection: connect
  @Export Component
*/

const mapStateToProps = state => ({
  loggedUser: state.loggedUser,
  countries: state.countries
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      TournamentEdit,
      UploadImage,
      getFormats,
      GameList,
      getSeasons,
      getStoreList,
      getTournamentInfo,
      getTournamentTypes,
      getUser
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(TournamentEditView))

// @Styles
const Wrapper = styled.div.attrs({ className: 'Tournament' })`
  height: inherit;
  background: url(${bgBackground('fondo1')}) 50% 0;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding: 20px 0px;
`
