// @External Dependencies
import React from 'react'
import TabContent from 'reactstrap/lib/TabContent'
import TabPane from 'reactstrap/lib/TabPane'
import Nav from 'reactstrap/lib/Nav'
import NavItem from 'reactstrap/lib/NavItem'
import NavLink from 'reactstrap/lib/NavLink'
import styled from 'styled-components'
import PropTypes from 'prop-types'

// @Dependencies
import { CircularProgressbar } from '../'
import vars from '../variables'

// @Component
const TabsComponent = ({
  links,
  id,
  container,
  active,
  onSelect,
  vertical,
  className
}) => {
  const onChange = id => onSelect && onSelect(id)

  const classNames = `${className || ''} ${vertical ? 'vertical' : ''}`

  return (
    <Wrapper className={classNames}>
      <Nav tabs>
        {links.map((tab, index) => (
          <NavItem
            key={index}
            className={`${active === index + 1 && 'active'} ${
              tab.disabled ? 'disabled' : 'enabled'
            }`}>
            <NavLink
              onClick={() => {
                onChange(index + 1)
              }}>
              {tab.title}
              {tab.alertIcon && (
                <i className={`nucleo-icon nucleo-icon-${tab.alertIcon}`} />
              )}
              {tab.progress >= 0 && (
                <CircularProgressbar percentage={tab.progress} />
              )}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={active} id={id} onSelect={onChange}>
        {/* {links &&
          links.map((tab, index) => (
            <TabPane
              tabId={index + 1}
              key={tab.title}
              title={
                <div>
                  {tab.title}
                  {tab.alertIcon && (
                    <i className={`nucleo-icon nucleo-icon-${tab.alertIcon}`} />
                  )}
                </div>
              }
              disabled={tab.disabled}
            />
          ))} */}

        {container &&
          container.map((section, index) => (
            <TabPane
              tabId={index + 1}
              // title={
              //   <p>
              //     {section.title}
              //     {!section.titleSpan && (
              //       <span className={section.titleSpan > 0 ? 'active' : ''}>
              //         {section.titleSpan}
              //       </span>
              //     )}
              //   </p>
              // }
              key={index}
              disabled={section.disabled}>
              {section.content}
            </TabPane>
          ))}
      </TabContent>
    </Wrapper>
  )
}

// @Proptypes
TabsComponent.propTypes = {
  active: PropTypes.number.isRequired,
  links: PropTypes.array,
  id: PropTypes.string,
  vertical: PropTypes.bool,
  container: PropTypes.array,
  onSelect: PropTypes.func
}

// @Export Component
export default TabsComponent

// @Styles
const Wrapper = styled.div`
  .nav-tabs {
    border-bottom: 1px solid #dedee8;
    justify-content: center;

    li {
      margin-bottom: 0;
      &.disabled {
        position: relative;
        &:after {
          background: rgba(255, 255, 255, 0.3);
          content: '';
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }
      }

      a {
        border-radius: 0;
        border: 0;
        border-width: 0;
        color: ${vars.setColors.text};
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        margin-right: 0;
        padding: 15px 30px 7px;
        text-decoration: none;
        text-transform: uppercase;
        :hover {
          border: 0;
        }
        :focus {
          border-top-width: 5px;
          outline: none;
        }

        .fa {
          color: ${vars.setColors.danger};
          font-size: 17px;
          margin-left: 7px;
        }
      }
      &.active {
        background: #fff;
        text-decoration: none;
        a {
          border: 0;
          color: ${vars.setColors.primary};
          position: relative;
          &:after {
            background: ${vars.setColors.primary};
            bottom: 0;
            content: '';
            height: 3px;
            left: 50%;
            margin-left: -11.5px;
            position: absolute;
            width: 23px;
          }
        }
      }
    }
  }
  &.vertical {
    > div {
      display: flex;
      border: 2px solid #dedee8;
      border-radius: 5px;
      height: 500px;
      overflow: hidden;
      .nav-tabs {
        border: none;
        border-right: 2px solid #dedee8;
        display: flex;
        flex-direction: column;
        margin: 0;
        width: 250px;
        li {
          background: transparent;
          border: none;
          margin-bottom: 0;
          a {
            background: transparent;
            border: 0px;
            padding: 15px 45px 15px 15px;
          }
          &.active {
            background: ${vars.setColors.primary};
            a {
              color: #fff;
              margin-left: 0;
              width: 100%;
            }
          }
          &.disabled {
            cursor: not-allowed;
          }
        }
        span {
          align-items: center;
          background: #dedee8;
          border-radius: 50%;
          color: #fff;
          display: flex;
          font-size: 0.8rem;
          height: 1.7rem;
          justify-content: center;
          position: absolute;
          top: 1rem;
          right: 1rem;
          width: 1.7rem;
          &.active {
            background: #f47143;
          }
        }
      }
      .tab-content {
        overflow: hidden;
        width: 100%;
      }
    }
  }

  .CircularProgressbar {
    flex-shrink: 0;
    margin-left: 10px;
    width: 15px;
  }
`
