import React, { useState, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'

import { Table } from 'reactstrap'
import { Input, Button, Avatar, Select, Alert } from '../../../Components/UI'
import styled from 'styled-components'

import {
  UserFind,
  TournamentPlayerRegister,
  getTournamentOrganizers,
  DeleteTournamentOrganizer
} from '../../../Redux/Actions'

export const TournamentOrganizers = () => {
  const dispatch = useDispatch()
  const { tournamentId } = useParams()
  const [search, setSearch] = useState('')
  const [error, setError] = useState('')
  const [errorType, setErrorType] = useState('warning')
  const [users, setUsers] = useState([])
  const [organizers, setOrganizers] = useState([])
  const [organizerId, setOrganizerId] = useState(0)
  const [loading, setLoading] = useState(false)
  const {
    ui: { isMobile },
    loggedUser: { id: personId }
  } = useSelector(store => store)

  /** Busca los organizadores. */
  const onSearch = useCallback(async () => {
    setLoading(true)
    const users = await dispatch(
      UserFind(search, [], Number(tournamentId), true)
    )
    const listUsers = users.Persons.map(p => {
      return {
        id: p.id,
        name: `${p.fullName} (${p.code}) - ${p.email}`
      }
    })
    if (!listUsers.length) {
      setError('No se encontraron usuarios con el criterio de búsqueda.')
      setErrorType('warning')
    } else {
      setError('')
    }
    setUsers(listUsers)
    setLoading(false)
  }, [search, tournamentId, dispatch, UserFind])

  /** Agrega un organizador. */
  const tournamentOrganizerSaveAdd = useCallback(async () => {
    setLoading(true)
    const data = {
      tournamentId: Number(tournamentId),
      personId: organizerId,
      organizer: true
    }
    const newOrganizer = await dispatch(TournamentPlayerRegister(data))
    if (newOrganizer.status.success) {
      setError('')
      setOrganizerId(0)
      setUsers([])
      setSearch('')
      setOrganizers(newOrganizer.TournamentOrganizers)
      setError('Se agregó al usuario exitosamente a la lista de organizadores')
      setErrorType('success')
    } else {
      setError(`${newOrganizer.status.name} ${newOrganizer.status.detail}`)
      setErrorType('danger')
    }
    setLoading(false)
  }, [tournamentId, dispatch, TournamentPlayerRegister, organizerId])

  const searchOrganizadores = useCallback(async () => {
    const resolve = await dispatch(
      getTournamentOrganizers(Number(tournamentId))
    )
    if (resolve.status.success) {
      setOrganizers(resolve.Organizers)
    } else {
      setError('Ocurrió un error al buscar organizadores')
      setErrorType('warning')
    }
  }, [tournamentId])

  const onDelete = useCallback(async paramDeleteId => {
    setLoading(true)
    setOrganizerId(0)
    setUsers([])
    const resolve = await dispatch(DeleteTournamentOrganizer(paramDeleteId))

    if (resolve.status.success) {
      searchOrganizadores()
      setErrorType('success')
      setError('Se eliminó correctamente el organizador del torneo.')
    } else {
      setErrorType('danger')
      setError('Ocurrió un error al eliminar el organizador del torneo.')
    }
    setLoading(false)
  }, [dispatch, searchOrganizadores])

  useEffect(() => {
    searchOrganizadores()
  }, [searchOrganizadores])

  return (
    <Wrapper body>
      {error.length > 0 && (
        <Alert color={errorType} close={e => setError('')}>
          {ReactHtmlParser(error)}
        </Alert>
      )}
      <div className='search'>
        <Input
          inputSize={12}
          name='search'
          placeholder='Buscar un jugador Nombre, Código o email.'
          value={search}
          onChange={e => setSearch(e.target.value.trim())}
          disabled={loading}
        />
        <Button
          text='Buscar'
          onClick={onSearch}
          disabled={loading || !search.length}
        />
      </div>
      {users.length > 0 && (
        <div className='results'>
          <Select
            inputSize={12}
            name='personId'
            options={users}
            onChange={e => setOrganizerId(Number(e.target.value))}
            select={organizerId}
          />
          <Button
            text='Asociar Organizador'
            onClick={tournamentOrganizerSaveAdd}
            disabled={loading}
          />
        </div>
      )}
      {organizers.length > 0 && (
        <Table
          className={`table-bordered ${isMobile ? 'table-responsive' : ''}`}>
          <thead>
            <tr>
              <th className='text-center'>#</th>
              <th colSpan={2}>Organizador</th>
              <th>Código</th>
              <th className='text-center'>
                <i className='fa fa-cogs' />
              </th>
            </tr>
          </thead>
          <tbody>
            {organizers.map((organizer, key) => {
              return (
                <tr key={key}>
                  <td className='text-center'>{key + 1}</td>
                  <td className='text-center'>
                    <Avatar person={organizer.Person} size={40} fontSize={20} />
                  </td>
                  <td>{organizer.Person.fullName}</td>
                  <td>{organizer.Person.code}</td>
                  <td className='text-center'>
                    {organizer.personId !== personId && (
                      <Button
                        text='Eliminar'
                        onClick={e => onDelete(organizer.id)}
                        disabled={loading}
                      />
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      )}
      {!organizers.length && (
        <p className='text-center'>
          De momento no tienes organizadores inscritos en el torneo.
        </p>
      )}
    </Wrapper>
  )
}

// @Styles
const Wrapper = styled.div.attrs({ className: 'Organizers' })`
  .search,
  .results {
    display: grid;
    grid-template-columns: 3fr 1fr;
    button {
      margin: 7px;
    }
  }
`
