// @External Dependencies
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter as Router } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'

// @Dependencies
import { store, persistor } from './app/Redux/Store'
import AppRoutes from './app/routes'
import i18n from './app/I18n'

// @Render
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <I18nextProvider i18n={i18n}>
          <AppRoutes />
        </I18nextProvider>
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)
