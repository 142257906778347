import styled from 'styled-components'

import { bgBackground } from '../../assets/img/login'
// @Styles
export const WrapperView = styled.div.attrs({
  className: 'ViewComponent'
})`
  background: url(${bgBackground('fondo2')}) 50% 0;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding: 80px 0px;

  .container {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
  }

  .link {
    color: #0189ff;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 20px;
    padding: 20px 15px;
    @media (min-width: 426px) and (max-width: 768px) {
      grid-template-columns: 1fr;
    }
    @media (max-width: 426px) {
      grid-template-columns: 1fr;
    }
    &.secondary {
      grid-template-columns: 3fr 1fr;
    }
  }
`
