const initialState = {}

// @Export Reducer
export const loggedUser = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case 'LOGIN':
      return {
        ...payload
      }
    case 'GET_USER':
      return {
        ...state,
        ...payload,
        isAdmin:
          payload.user.PersonProfiles.filter(p => {
            return p.profileId === 3
          }).length > 0
      }
    case 'LOGOUT':
      return {}
    default:
      return state
  }
}
