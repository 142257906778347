// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { getToken, axiosError } from '../../../Services'

const register = data => {
  return dispatch => {
    getToken()
    const query = `
      mutation StoreRegister($StoreRegisterInput: StoreRegisterInput!) {
        StoreRegister(input: $StoreRegisterInput) {
          status  {
            success
            name
            detail
          }
          store {
            id
            name
          }
        }
      }
    `
    const variables = {
      StoreRegisterInput: {
        name: data.name,
        description: data.description,
        inChargePerson: data.inChargePerson,
        logo: data.logo,
        cover: data.cover,
        phone: data.phone,
        email: data.email,
        siteUrl: data.siteUrl,
        facebookUrl: data.facebookUrl,
        instagramUrl: data.instagramUrl,
        youtubeUrl: data.youtubeUrl,
        twitterUrl: data.twitterUrl,
        address: data.address
      }
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then(response => {
        if (response.data.errors) {
          return response.data.errors[0].message
        } else {
          const { StoreRegister } = response.data.data
          if (response.status === 200 && StoreRegister.status.success) {
            const payload = StoreRegister
            return payload
          } else {
            return StoreRegister
          }
        }
      })
      .catch(err => axiosError(err))
  }
}

export default register
