// @External Dependencies
import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from 'reactstrap/lib/Tooltip'
import styled from 'styled-components'

// @Dependencies
import vars from '../variables'
import { NucleoIcon } from '../../../Components/UI'

// @Component
const TooltipTrigger = ({ msg, isAlert }) => (
  <Tooltip placement='top' overlay={<Tooltip id='tooltip'>{msg}</Tooltip>}>
    {isAlert ? (
      <StyledAlertIcon className='fa fa-exclamation-circle' />
    ) : (
      <NucleoIcon icon='info' />
    )}
  </Tooltip>
)

// @Proptypes
TooltipTrigger.propTypes = {
  msg: PropTypes.string,
  isAlert: PropTypes.bool
}

// @Export Component
export default TooltipTrigger

// @Styles
const StyledAlertIcon = styled.i`
  align-self: flex-start;
  color: ${vars.setColors.danger} !important;
  margin: 15px 0 0 7px;
  position: absolute;
  right: -30px;
`
