// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { axiosError, getToken } from '../../../Services'

const register = data => {
  return dispatch => {
    getToken()
    const query = `
      mutation UserEdit($UserEditInput: UserEditInput!) {
        UserEdit(input: $UserEditInput) {
          status  {
            success
            name
            detail
          }
          person {
            id
            avatar
            fullName
            urlAvatar
            urlEdit
            code
          }
        }
      }
    `
    const variables = {
      UserEditInput: {
        id: data.person.id,
        name: data.person.name,
        firstSurname: data.person.firstSurname,
        lastSurname: data.person.lastSurname,
        nickname: '',
        birthday: data.person.birthday,
        genderId: data.person.genderId,
        passwd: data.person.passwd,
        email: data.person.email,
        active: data.person.active,
        categoryId: Number(data.person.categoryId) || 0,
        phone: data.person.phone,
        address: {
          id: data.address.id,
          name: data.address.name,
          address: data.address.address,
          countryId: data.address.countryId,
          regionId: data.address.regionId,
          locationId: data.address.locationId
        }
      }
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then(response => {
        if (response.data.errors) {
          return response.data.errors[0].message
        } else {
          const { UserEdit } = response.data.data
          if (response.status === 200 && UserEdit.status.success) {
            return UserEdit
          } else {
            return UserEdit
          }
        }
      })
      .catch(err => axiosError(err))
  }
}

export default register
