// @Dependencies
const initialState = {}

// @Export Reducer
export const ui = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_MEDIAQUERY':
      return { ...state, isMobile: action.payload }
    case 'SET_ALERT':
      const newState = {
        ...state,
        alert: action.payload ? action.payload : {}
      }
      return newState
    default:
      return state
  }
}
