// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { getToken, axiosError } from '../../../Services'

const getLevels = () => {
  const requestId = 'getLevels'
  return dispatch => {
    getToken()

    const query = `
      query {
        allLevels(paginate: 20) {
          id
          name
        }
      }
    `

    return axios
      .post(`${apiURL}/graphql`, { query }, { requestId })
      .then(response => {
        if (response.status === 200 && response.data.data.allLevels) {
          return response.data.data.allLevels
        } else {
          return []
        }
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}

export default getLevels
