// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { getToken, axiosError } from '../../../Services'

export default data => {
  const requestId = 'TournamentTypeUpdate'
  return async dispatch => {
    getToken()
    const query = `
      mutation TournamentTypeUpdate($TournamentTypeUpdateInput: TournamentTypeUpdateInput!) {
        TournamentTypeUpdate(input: $TournamentTypeUpdateInput) {
          status {
            success
            name
            detail
          }
          TournamentType {
            id
            name
            description
            sortOrder
            status
            minPlayers
            maxTopPlayers
            multiplier
            winnerPoints
            loserPoints
            drawPoints
            socialRequired
            urlImage
          }
        }
      }
    `
    const variables = {
      TournamentTypeUpdateInput: data
    }
    delete variables.TournamentTypeUpdateInput.urlImage

    return axios
      .post(`${apiURL}/graphql`, { query, variables }, { requestId })
      .then(response => {
        return response.data.data.TournamentTypeUpdate
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}
