// @External Dependencies
import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { LoadingSVG } from '../../Components/UI'
import { getGames } from '../../Redux/Actions'
import { Row, Col, Container, Jumbotron } from 'reactstrap'
import { bgBackground } from '../../../assets/img/login'
import { GameCard } from './GameCard'

/**
 * Vista con ranking por juegos.
 */
export const RankingGamesListView = () => {
  const dispatch = useDispatch()
  const [games, setGames] = React.useState([])
  const [loading, setLoading] = React.useState(true)

  const search = React.useCallback(async () => {
    const resolve = await dispatch(getGames({ findSeasons: false }))
    if (!resolve.err) {
      setGames(resolve)
    }
    setLoading(false)
  }, [dispatch])

  React.useEffect(() => {
    search()
  }, [])

  if (loading) return <LoadingSVG message={'Estamos cargando el ranking'} />

  return (
    <Wrapper>
      <Container>
        <Row>
          <Col md={12}>
            <h2 className='texts-center'>Juegos</h2>
            <p>
              Selecciona un juego para ver el ranking de la temporada activa.
            </p>
            {games.length ? (
              <div className='Games_list'>
                {games.map((game, key) => {
                  return (
                    <div className='ListElement' key={key}>
                      <GameCard data={game} />
                    </div>
                  )
                })}
              </div>
            ) : (
              <Jumbotron className='text-center'>
                <h2 className='text-center'>
                  <i className='fa fa-user-secret' />
                  <br />
                  Aún no se han reportados torneos en esta temporada. Acércate a
                  tu tienda más cercana para participar en eventos.
                </h2>
              </Jumbotron>
            )}
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

// @Styles
const Wrapper = styled.div.attrs({ className: 'Ranking' })`
  background: url(${bgBackground('fondo2')}) 50% 0;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding: 80px 0px;

  .container {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    min-height: 70vh;
  }

  .Games_list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    @media (min-width: 426px) and (max-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 426px) {
      grid-template-columns: 1fr;
    }
    .ListElement {
      margin: 0px 10px;
      .card {
        margin: 20px auto;
        // box-shadow: 10px 5px 40px 20px #e9ecef;
        transition: 0.25s;
        padding: 0px;

        &:hover {
          box-shadow: 0px 0px 20px 10px #c9c9d3;
          cursor: pointer;

          .button-primary {
            transform: translate(10px, 0);
          }
        }
      }

      .card-header {
        height: 200px;
        width: 100%;
        padding: 0px;
        width: 100%;
        background-size: cover;
        background-position: center;
        color: #fff;
        h4 {
          font-size: 1rem;
          padding: 15px;
          background-color: rgba(244, 244, 244, 0.4);
        }
        &--title {
          text-transform: uppercase;
          margin: 0;
        }
      }

      .card-body {
        padding: 15px;
        background-color: #fff;
        width: 100%;
        h3 {
          // white-space: nowrap;
          // overflow: hidden;
          // text-overflow: ellipsis;
        }
        .date {
          font-size: 11px;
          font-weight: 600;
          color: grey;
        }
        .category {
          position: absolute;
          top: 160px;
          left: 0px;
          width: 100%;
          background: #e74c3c;
          padding: 10px 15px;
          color: #fff;
          font-size: 14px;
          font-weight: 600;
          text-transform: uppercase;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          i {
            margin-right: 10px;
          }
        }

        .body-content {
          margin-bottom: 10px;
          font-size: 13px;
          // height: 40px;
          overflow: hidden;
        }

        .button-primary {
          border: none;
          box-shadow: none;
          font-family: inherit;
          background-color: transparent;
          font-size: 15px;
          transition: 0.25s;

          i {
            padding-right: 4px;
          }
        }

        .designer-link {
          color: #fff;
          text-align: center;
          font-size: 12px;
          text-decoration: none;
          display: block;
        }
      }
    }
  }
`
