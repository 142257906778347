// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { axiosError, getToken } from '../../../Services'

const login = data => {
  return dispatch => {
    getToken()
    const query = `
      mutation UserLogin($UserLoginInput: UserLoginInput!) {
        UserLogin(input: $UserLoginInput) {
          token
          status {
            success
            name
            detail
          }
        }
      }
    `
    const variables = {
      UserLoginInput: {
        username: data.username,
        passwd: data.passwd,
        type: data.type
      }
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then(response => {
        if (response.data.errors) {
          return response.data.errors[0].message
        } else {
          const { UserLogin } = response.data.data
          if (response.status === 200 && UserLogin.status.success) {
            const payload = UserLogin
            dispatch({
              type: 'LOGIN',
              payload
            })
            return payload
          } else {
            return UserLogin
          }
        }
      })
      .catch(err => axiosError(err))
  }
}

export default login
