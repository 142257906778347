// @External Dependencies
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Jumbotron, Table } from 'reactstrap'
import styled from 'styled-components'
import { format, isValid } from 'date-fns'
import { es } from 'date-fns/locale'

/**
 * Tournament Info
 */
const TournamentInfo = ({ tournament, isMobile }) => {
  const [showInfo, setShowInfo] = useState(true)

  useEffect(() => {
    setShowInfo(!isMobile)
  }, [isMobile])

  const dateFormatted = isValid(new Date(tournament.startDate))
    ? format(new Date(tournament.startDate), `EEEE dd 'de' MMMM y`, {
        locale: es
      })
    : ''
  return (
    <Wrapper>
      <Jumbotron>
        <h3>Torneo: "{tournament.name}"</h3>
        <div className='grid'>
          <Table className='table-bordered'>
            <tbody>
              <tr>
                <th scope='row'>Estado</th>
                <td>
                  <span className={`text-${tournament.TournamentStatus.style}`}>
                    {tournament.TournamentStatus.name}:
                  </span>{' '}
                  {tournament.TournamentStatus.description}
                </td>
              </tr>
              {showInfo && (
                <>
                  <tr>
                    <th scope='row'>Fecha</th>
                    <td>{dateFormatted}</td>
                  </tr>
                  <tr>
                    <th scope='row'>Tienda</th>
                    <td>
                      <Link to={tournament.Store.urlView}>
                        <i>{tournament.Store.name}</i>
                      </Link>
                    </td>
                  </tr>
                </>
              )}
              <tr>
                <th>Categoría de Tienda</th>
                <td>
                  <b>{tournament.Store.Level.name}</b>
                  <br />
                  <i>{tournament.Store.Level.description}</i>
                </td>
              </tr>
            </tbody>
          </Table>
          <Table className='table-bordered'>
            <tbody>
              <tr>
                <th scope='row'>Juego</th>
                <td>
                  {!showInfo ? (
                    tournament.Game.name
                  ) : (
                    <img
                      src={tournament.Game.urlImage}
                      alt={tournament.Game.name}
                      className='img-thumbnail'
                      style={{ width: '200px' }}
                    />
                  )}
                </td>
              </tr>
              <tr>
                <th scope='row'>Temporada</th>
                <td>
                  <p>
                    <Link to={tournament.Season.rankingUrl}>
                      {tournament.Season.name}{' '}
                    </Link>
                  </p>
                </td>
              </tr>
              <tr>
                <th scope='row'>Tipo Torneo</th>
                <td>
                  {!showInfo ? (
                    tournament.TournamentType.name
                  ) : (
                    <img
                      src={tournament.TournamentType.urlImage}
                      alt={tournament.TournamentType.name}
                      className='img-thumbnail'
                      style={{ width: '200px' }}
                    />
                  )}
                </td>
              </tr>
              <tr>
                <th scope='row'>Formato</th>
                <td>
                  <p>
                    {tournament.Format.name}{' '}
                    <i>(Rondas Suizas: {tournament.rounds})</i>
                  </p>
                  {tournament.Format.description && (
                    <p>
                      <i>{tournament.Format.description}</i>
                    </p>
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        <button
          onClick={() => setShowInfo(!showInfo)}
          className='btn-show btn btn-link'>
          {showInfo ? 'Ocultar detalles' : 'Ver detalles'}{' '}
          <i className={`fa fa-chevron-${showInfo ? 'up' : 'down'}`} />
        </button>
      </Jumbotron>
    </Wrapper>
  )
}

// @Proptypes
TournamentInfo.propTypes = {
  tournament: PropTypes.object.isRequired
}

export default TournamentInfo

// @Styles
const Wrapper = styled.div.attrs({ className: 'TournamentInfo' })`
  .jumbotron {
    padding: 20px 10px;
    margin-bottom: 20px;
    h3 {
      text-align: center;
    }
    table,
    p {
      margin: 0px;
    }
    .btn-show {
      cursor: pointer;
      display: block;
      margin: auto;
    }
  }
`
