// @External Dependencies
import React from 'react'
import Modal from 'reactstrap/lib/Modal'
import ModalHeader from 'reactstrap/lib/ModalHeader'
import ModalBody from 'reactstrap/lib/ModalBody'
import ModalFooter from 'reactstrap/lib/ModalFooter'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// @Component
const ModalBasic = ({
  body,
  children,
  className,
  error,
  subHeader,
  header,
  toggle,
  isOpen
}) => (
  <Modal isOpen={isOpen} toggle={toggle} className={className}>
    <StyledModal className={className}>
      <ModalHeader toggle={toggle}>
        {header}
        {subHeader && <p className='subHeader'>{subHeader}</p>}
      </ModalHeader>
      <ModalBody>{body}</ModalBody>
      {(error || children) && (
        <ModalFooter>
          {error && (
            <span className='modal__message--error'>
              Error en nuestro servidor, intentelo más tarde
            </span>
          )}
          {children}
        </ModalFooter>
      )}
    </StyledModal>
  </Modal>
)

// @Proptypes
ModalBasic.propTypes = {
  body: PropTypes.object,
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  error: PropTypes.bool,
  header: PropTypes.string,
  subHeader: PropTypes.string,
  toggle: PropTypes.func,
  className: PropTypes.string
}
ModalBasic.defaultProps = {
  btnClose: true,
  error: false
}

// @Export Component
export default ModalBasic

const StyledModal = styled.section`
  &.modal-disabled {
    &:after {
      content: '';
      background: rgba(255, 255, 255, 0.4);
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 10;
    }
  }

  .modal__message--error {
    margin-right: 1rem;
    color: red;
  }
  .subHeader {
    margin-bottom: 0;
  }

  .DraftEditor__component {
    margin-top: 15px;
  }
`
