// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { getToken, axiosError } from '../../../Services'

export default data => {
  const requestId = 'SurveyCreate'
  return async dispatch => {
    getToken()
    const query = `
      mutation SurveyCreate($SurveyCreateInput: SurveyCreateInput!) {
        SurveyCreate(input: $SurveyCreateInput) {
          status {
            success
            code
            name
            detail
          }
        }
      }
    `

    const variables = {
      SurveyCreateInput: data
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables }, { requestId })
      .then(response => {
        const data = response.data.data.SurveyCreate
        if (data) {
          return data
        } else {
          return {
            status: {
              success: false
            }
          }
        }
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}
