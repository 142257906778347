// @External Dependencies
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// @Dependencies
import BaseForm from './BaseForm'

// @Component
class InputRut extends Component {
  state = {
    value: this.props.value ? this.props.value : '',
    digit: this.props.digit ? this.props.digit : '',
    deleteState: false
  }

  handleChange = e => {
    let btnDelete
    this.setState({ value: e.target.value })
    e.target.value.length ? (btnDelete = true) : (btnDelete = false)
    this.setState({ deleteState: btnDelete })
    this.Change()
  }

  handleBlur = e => {
    const { onBlur } = this.props
    onBlur !== undefined && onBlur(e)
  }

  handleChangeDigit = e => {
    this.setState({ digit: e.target.value })
    this.Change()
  }

  Change = () => {
    const { onChange } = this.props
    const { value, digit } = this.state
    setTimeout(() => {
      onChange !== undefined &&
        onChange({ target: { name: 'rut', value: [value, digit] } })
    }, 10)
  }

  cleanInput = e => {
    const { onChange } = this.props
    this.setState({ value: '', deleteState: false })
    onChange !== undefined && onChange(e)
  }

  render () {
    const {
      disabled,
      info,
      icon,
      inputSize,
      label,
      name,
      required,
      size,
      tooltip
    } = this.props
    const { deleteState, value, digit } = this.state
    return (
      <StyledInputRut>
        <BaseForm
          icon={icon}
          info={info}
          inputSize={inputSize}
          label={label}
          name={name}
          required={required}
          tooltip={tooltip}>
          <div className='rut'>
            <div>
              <input
                className={`form-control input-${size}`}
                disabled={disabled}
                id={name}
                name={name}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                placeholder={!value ? '12345678' : value}
                required={required}
                type='text'
                value={value}
              />
              {deleteState && !disabled && (
                <i
                  className='glyphicon glyphicon-remove btn-clean'
                  onClick={this.cleanInput}
                />
              )}
            </div>
            <input
              className={`form-control rut-digit input-${size}`}
              disabled={disabled}
              maxLength='1'
              name={`${name}_digit`}
              onChange={this.handleChangeDigit}
              onBlur={this.handleBlur}
              placeholder={digit || 'k'}
              type='text'
              required={required}
              value={digit}
            />
          </div>
        </BaseForm>
      </StyledInputRut>
    )
  }
}

// @Proptypes
InputRut.propTypes = {
  digit: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  info: PropTypes.string,
  inputSize: PropTypes.number,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  size: PropTypes.string,
  tooltip: PropTypes.string,
  value: PropTypes.node
}

// @Export Component
export default InputRut

// @Styles
const StyledInputRut = styled.div`
  .rut {
    display: flex;
    & > div {
      position: relative;
      width: 100%;
    }
    &-digit {
      font-size: 1rem;
      margin-left: 16px;
      width: 3em;
      padding: 0.7143em 1.0714em;
      &.input-sm {
        padding: 0.384em 1em;
        width: 3em;
      }
    }
  }
  .input-group {
    .rut-digit {
      padding: 0.7143em 1.0714em;
    }
    .input-sm {
      padding: 0.384em 1em;
      padding-left: 60px;
      &.rut-digit {
        padding-left: 1.0714em;
      }
    }
  }
`
