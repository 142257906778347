import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { UserLogout } from '../../../Redux/Actions'
import { LoadingSVG } from '../../../Components/UI'

/**
 * Componente Logout de Usuarios, limpia la sesión de redux con el logout
 */
const LogoutView = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    setTimeout(() => {
      dispatch(UserLogout({ user: {} }))
      history.push('/')
    }, 3000)
  }, [history, dispatch])

  return <LoadingSVG />
}

export default LogoutView
