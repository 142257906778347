// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../constants'
import { getToken, axiosError } from '../../../../Services'

const getTournamentRound = roundId => {
  return dispatch => {
    getToken()
    const query = `
      query {
        TournamentRound(id: ${roundId}) {
          id
          name
          sortOrder
          description
          urlView
          urlStanding
          minutes

          Tournament {
            id
            name
            rounds
            urlEdit
            urlView
            urlAddPlayers
            urlRounds
            urlStandings
            eventUrl
            startDate
            rounds
            isPresential
            showPersonalInfo
            TournamentOrganizers {
              personId
            }
            TournamentType {
              id
              name
              winnerPoints
              multiplier
              loserPoints
              multiplier
              drawPoints
              multiplier
              maxTopPlayers
              urlImage
            }
            Format {
              name
            }
            TournamentStatus {
              id
              name
              description
              style
            }
            Store {
              id
              name
              urlView
              Level {
                name
                description
              }
            }
            TournamentRounds(orderBy: sortOrder_DESC, paginate: 1) {
              id
              name
            }
            TotalTournamentRounds {
              id
              name
              sortOrder
              urlView
              TournamentRoundStatus {
                id
                name
              }
            }
            Season {
              name
              rankingUrl
            }
            Game {
              id
              name
              urlImage
            }
            TournamentPlayers (paginate: 99999) {
              id
              drop
              Person {
                id
                avatar
                fullName
                urlAvatar
                phone
                code
              }
            }
          }
          TournamentRoundType {
            id
            name
          }
          TournamentRoundStatus {
            id
            name
          }
          TournamentMatches(paginate: 99999) {
            id
            tournamentId
            roundId
            table
            reported
            draw
            dropPlayer
            dropOpponent
            winnerVictories
            loserVictories
            playerId
            TournamentRound {
              name
              TournamentRoundType {
                id
                name
              }
              TournamentRoundStatus {
                id
                name
              }
            }
            Player {
              id
              drop
              Person {
                id
                avatar
                fullName
                urlAvatar
                phone
                code
              }
            }
            opponentId
            Opponent {
              id
              drop
              Person {
                id
                avatar
                fullName
                urlAvatar
                phone
                code
              }
            }
            Winner {
              id
              Person {
                id
                avatar
                fullName
                urlAvatar
                code
              }
            }
            Loser {
              id
              Person {
                id
                avatar
                fullName
                urlAvatar
                code
              }
            }
          }
        }
      }
    `
    return axios
      .post(`${apiURL}/graphql`, { query })
      .then(response => {
        if (response.data.errors || !response.data.data.TournamentRound) {
          const error = response.data.errors
            ? response.data.errors[0].message
            : 'Ronda no existe o no ha sido creada.'
          return {
            status: {
              success: false,
              detail: error
            }
          }
        } else {
          const { TournamentRound } = response.data.data
          return {
            status: {
              success: true,
              name: 'Petición exitosa'
            },
            ...TournamentRound
          }
        }
      })
      .catch(err => axiosError(err))
  }
}

export default getTournamentRound
