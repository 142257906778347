import Variables from '../variables'

const Boostrap = `
  span[data-toggle="tooltip"]{
    color: ${Variables.setColors.deepSkyBlue};
    cursor: pointer;
    padding-right: 22px;
    position: relative;
    :before{
      cursor: pointer;
      right: 0;
      position: absolute;
      top: -4px;
    }

    &:hover {
      color: #333;
    }
  }
  .tooltip{
    font-size: 12px;
    z-index: 10;
    &.show{
      opacity: 1;
    }


  }
`
export default Boostrap
