// @External Dependencies
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

// @Dependencies
import BaseForm from './BaseForm'
import vars from '../variables'

// @Component
const Toggle = ({
  checked,
  disabled,
  info,
  label,
  name,
  onChange,
  size,
  required,
  inputSize,
  tooltip,
  labelSize
}) => {
  const handleChange = e => onChange !== undefined && onChange(e)

  return (
    <StyledToggle>
      <BaseForm
        info={info}
        label={label}
        tooltip={tooltip}
        required={required}
        labelSize={labelSize}
        inputSize={inputSize}>
        <div className='toggle-box'>
          <label className={disabled ? 'is-disabled' : ''}>
            <input
              id={name}
              disabled={disabled}
              checked={checked}
              name={name}
              onChange={handleChange}
              type='checkbox'
            />
            <span className={size}>
              <small />
            </span>
          </label>
        </div>
      </BaseForm>
    </StyledToggle>
  )
}

// @Proptypes
Toggle.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  info: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  size: PropTypes.string,
  tooltip: PropTypes.string
}

Toggle.defaultProps = {
  size: 'md'
}

// @Export Component
export default Toggle

// @Styles
const StyledToggle = styled.div`
  .container-input {
    padding-right: 1rem;
    width: auto;
  }
  .input-box {
    width: auto;
  }
  .toggle-box {
    position: relative;
  }
  .sm {
  }
  .is-disabled {
    opacity: 0.3;
    span {
      cursor: no-drop;
    }
  }
  input {
    left: 50%;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    & + span {
      background-color: #f5f5fb;
      border-radius: ${vars.borderRadius};
      cursor: pointer;
      display: inline-block;
      height: 2.4286rem;
      margin: 0;
      overflow: hidden;
      padding: 0.2143rem;
      position: relative;
      top: 0;
      vertical-align: middle;
      width: 4.7143rem;

      &:before {
        color: #999;
        content: 'OFF';
        font-size: 0.9286rem;
        font-weight: normal;
        left: 0.2857rem;
        line-height: 100%;
        position: absolute;
        text-align: center;
        top: 50%;
        transition: all 0.2s ease;
        transform: translateY(-50%);
        width: 2.1429rem;
      }

      small {
        align-items: center;
        background-color: #c9c9d3;
        border-radius: ${vars.borderRadius};
        display: flex;
        height: 1.7143rem;
        justify-content: center;
        left: 2.6429rem;
        margin: 0;
        position: absolute;
        top: 50%;
        transition: all 0.2s ease;
        transform: translateY(-50%);
        width: 1.7143rem;
        &:after {
          color: white;
          content: '';
          font-family: FontAwesome;
          font-size: 1rem;
        }
      }
      &.sm {
        height: 2rem;
        width: 4.2857rem;
        :before {
          font-size: 0.7857rem;
          left: 0.0714rem;
        }
        small {
          height: 1.5714rem;
          left: 2.3571rem;
          width: 1.5714rem;
          &:after {
            font-size: 0.7857rem;
          }
        }
      }
    }

    &:checked {
      & + span {
        &:before {
          content: 'ON';
          left: 2.2143rem;
        }

        small {
          background-color: ${vars.setColors.primary};
          left: 0.3571rem;

          &:after {
            content: '';
          }
        }
      }
    }
  }
`
