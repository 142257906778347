// @External Dependencies
import React, { Component } from 'react'
// import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import validator from 'validator'
import { Jumbotron, Container } from 'reactstrap'
import { bgBackground } from '../../../assets/img/login'
import ReactHtmlParser from 'react-html-parser'
// @Dependencies
import {
  TournamentRegister,
  getFormats,
  getTournamentTypes,
  GameList,
  getUser
} from '../../Redux/Actions'
import {
  Alert,
  Button,
  Input,
  Select,
  Textarea,
  LoadingSVG,
  Datapicker
} from '../../Components/UI'
import { axiosAbort } from '../../Services'

// @Component
class TournamentRegisterView extends Component {
  state = {
    alertContent: '',
    alertState: 'danger',
    loading: true,
    loadingRegister: true,
    tournament: {
      name: '',
      description: '',
      image: '',
      eventUrl: '',
      addressId: 0,
      gameId: 0,
      typeId: 0,
      formatId: 0,
      maxPlayers: 2,
      rounds: 2,
      startDate: '',
      validDates: 1
    },
    socialRequired: false,
    games: [],
    formats: [],
    types: [],
    typesRequiredUrl: [],
    maxPlayers: [],
    addresses: [],
    errors: false,
    rounds: [],
    TournamenDoubleDates: [],
    validDates: [
      {
        id: 1,
        name: 'Simple ✋ Sólo descuenta un torneo mensual de los disponibles'
      },
      {
        id: 2,
        name: 'Doble 🙌  Es válido por dos torneos mensuales de los disponibles'
      }
    ]
  }

  /**
   * - Al iniciar busca los juegos, formatos, tipos de torneo
   * - Si no tengo permisos para crear torneos entonces mostramos alerta y enviamos al home
   */
  async componentDidMount () {
    await this.props.getUser()
    const {
      loggedUser,
      GameList,
      getFormats,
      getTournamentTypes,
      history
    } = this.props

    let loadingRegister = false
    let alertContent = ''
    if (!loggedUser.user.Store || loggedUser.user.Store.active !== 1) {
      loadingRegister = true
      alertContent = !loggedUser.user.Store.active
        ? 'Tu tienda no está activa aún para crear torneos.'
        : 'Sólo perfiles dueños de tienda pueden crear torneos.'
      setTimeout(() => {
        history.push('/ranking')
      }, 2000)
    }

    const { formats, status } = await getFormats()
    if (status.success && loggedUser.user.Store) {
      this.calcRounds()
      const { id, address, Region, Country } = loggedUser.user.Store.Address
      const addresses = [
        {
          id,
          name: `${address}, ${Region.fullName}, ${Country.name}`
        }
      ]
      const resolveGameList = await GameList(loggedUser.user.Store.id)
      const TournamentTypes = await getTournamentTypes(loggedUser.user.Store.id)
      const typesRequiredUrl = TournamentTypes.filter(
        ({ socialRequired }) => socialRequired
      )
      const TournamenDoubleDates = TournamentTypes.filter(
        ({ canBeDouble }) => canBeDouble > 0
      )

      const types = TournamentTypes.map(t => {
        const total = t.storeTournaments
        return {
          id: t.id,
          name: `${total} por mes | ${t.name}`,
          total
        }
      }).sort((a, b) => b.total - a.total)

      this.setState({
        tournament: {
          ...this.state.tournament,
          addressId: addresses.length === 1 ? addresses.find(z => z).id : 0,
          gameId:
            resolveGameList.Games.length === 1
              ? resolveGameList.Games.find(z => z).id
              : 0
        },
        loadingRegister,
        alertContent,
        games: resolveGameList.Games,
        formats: formats.map(({ id, name, FormatGames }) => {
          return { id, name, FormatGames }
        }),
        typesRequiredUrl,
        types,
        addresses,
        TournamenDoubleDates,
        loading: !status.success
      })
    } else {
      this.setState({
        alertContent:
          'Ocurrió un error al buscar los datos para crear el torneo.'
      })
    }
  }

  componentWillUnmount () {
    axiosAbort()
  }

  onSubmit = async event => {
    event.preventDefault()
    const { TournamentRegister, history } = this.props
    const { tournament } = this.state

    this.setState({
      alertContent: 'Estamos creando el torneo...',
      loading: false,
      loadingRegister: true
    })

    let { errors } = this.state
    if (tournament.eventUrl.length && !validator.isURL(tournament.eventUrl)) {
      errors = true
    }

    /**
     * Si no hay errores de validación entonces cremos el Torneo
     */
    if (!errors) {
      const resolver = await TournamentRegister(tournament)
      if (!resolver.status.success) {
        this.setState({
          alertContent: resolver.status.detail,
          loading: false,
          loadingRegister: false
        })
      } else {
        /**
         * Si se registra correctamente el torneo entonces redireccionamos a su detalle
         */
        this.setState({
          alertContent: resolver.status.name,
          alertState: 'success'
        })

        setTimeout(async () => {
          history.push(resolver.tournament.urlView)
        }, 3000)
      }
    } else {
      this.setState({
        errors,
        alertContent: 'Hay errores en el formulario',
        loadingRegister: false
      })
    }
  }

  /**
   * Controla los cambios de estados de los inputs
   */
  onChange = async e => {
    const { tournament } = this.state
    const target = e.target
    let { socialRequired } = this.state

    if (target && target.id === 'name') {
      tournament.name = target.value
    }
    if (target && target.id === 'description') {
      tournament.description = target.value
    }
    // Juego -> Formato -> Tipo
    if (target && target.id === 'gameId') {
      tournament.gameId = Number(target.value)
      tournament.formatId = 0
    }
    if (target && target.id === 'formatId') {
      tournament.formatId = Number(target.value)
    }
    if (target && target.id === 'validDates') {
      tournament.validDates = Number(target.value)
    }
    if (target && target.id === 'typeId') {
      tournament.typeId = Number(target.value)
      socialRequired =
        this.state.typesRequiredUrl.filter(({ id }) => tournament.typeId === id)
          .length > 0
      tournament.validDates = 1 // Simple
    }
    // end Juego -> Formato -> Tipo

    if (target && target.id === 'maxPlayers') {
      if (target.value) {
        tournament.maxPlayers = Number(target.value)
      }
    }
    if (target && target.id === 'rounds') {
      tournament.rounds = Number(target.value)
    }

    // Datos Sociales
    if (target && target.id === 'addressId') {
      tournament.addressId = Number(target.value)
    }
    if (target && target.id === 'eventUrl') {
      tournament.eventUrl = target.value
    }
    if (target && target.id === 'image') {
      tournament.image = target.value
    }
    this.setState({
      tournament,
      socialRequired,
      errors: false
    })
    if (target && target.id === 'maxPlayers') {
      this.calcRounds()
    }
  }

  /**
   * Calcula el total de rondas en base al maximo de jugadores
   */
  calcRounds (totalPlayers) {
    const { tournament } = this.state
    const maxPlayers = !totalPlayers ? tournament.maxPlayers : totalPlayers
    let maxRounds = Math.ceil(Math.log2(maxPlayers))
    if (maxRounds < 2) {
      maxRounds = 2
    }

    let rounds = []
    for (let k = 2; k <= maxRounds; k++) {
      rounds.push({
        id: k,
        name: `${k} Rondas`
      })
    }
    this.setState({
      rounds
    })
  }

  /**
   * Controla los cambios de los Datapicker
   */
  changeDate = (e, name) => {
    let key, value
    if (name) {
      key = name
      value = e
    } else {
      key = e.target.name
      value = Number(e.target.value)
      if (e.target.name === 'commentLeaving') {
        value = e.target.value
      }
    }
    const { tournament } = this.state
    if (key === 'startDate') {
      tournament.startDate = value
    }
    this.setState({ tournament })
  }

  render () {
    const {
      loading,
      alertContent,
      loadingRegister,
      alertState,
      tournament,
      formats,
      types,
      games,
      addresses,
      socialRequired,
      errors
    } = this.state

    if (loadingRegister) return <LoadingSVG message={alertContent} />

    const formatsFiltered = formats.filter(format =>
      format.FormatGames.some(f => f.gameId === tournament.gameId)
    )

    return (
      <Wrapper>
        <Container>
          <Jumbotron>
            <h2>Registro nuevo Torneo</h2>
            <p>
              Ingrese todos los datos del formulario para inscribir un nuevo
              torneo.
            </p>
            <form
              onSubmit={this.onSubmit}
              acceptCharset='utf-8'
              id='StoreRegisterForm'>
              {alertContent.length > 0 && (
                <Alert color={alertState} close={this.handleDismissAlert}>
                  {ReactHtmlParser(alertContent)}
                </Alert>
              )}
              <Input
                disabled={loading}
                inputSize={9}
                labelSize={3}
                name='name'
                placeholder='Nombre Torneo'
                onChange={this.onChange}
                value={tournament.name}
                type='input'
                label='Nombre'
                required
              />
              <Textarea
                disabled={loading}
                inputSize={9}
                labelSize={3}
                name='description'
                placeholder='Descripción'
                label='Descripción'
                onChange={this.onChange}
                value={tournament.description}
                required
              />
              {/** Juego */}
              <Select
                disabled={loading}
                name='gameId'
                label='Juego'
                inputSize={9}
                labelSize={3}
                onChange={this.onChange}
                select={tournament.gameId}
                options={games}
                required
              />
              {/** Tipo torneo */}
              <Select
                disabled={loading}
                inputSize={9}
                labelSize={3}
                name='typeId'
                label='Tipo Torneo'
                onChange={this.onChange}
                select={tournament.typeId}
                options={types}
                required
              />
              <Select
                disabled={loading}
                inputSize={9}
                labelSize={3}
                name='formatId'
                label='Formato Juego'
                onChange={this.onChange}
                select={tournament.formatId}
                options={formatsFiltered}
                required
              />

              <Datapicker
                name='startDate'
                label='Fecha Realización'
                onChange={this.changeDate}
                inputSize={9}
                labelSize={3}
                required
              />

              <Select
                disabled={
                  loading ||
                  !this.state.TournamenDoubleDates.find(
                    t => t.id === tournament.typeId
                  )
                }
                inputSize={9}
                labelSize={3}
                name='validDates'
                label='Tipo de fecha'
                onChange={this.onChange}
                select={tournament.validDates}
                options={this.state.validDates}
                required
              />
              {/* <Select
                    disabled={loading}
                    inputSize={10}
                    name='rounds'
                    label={t('Rondas máximas')}
                    onChange={this.onChange}
                    select={tournament.rounds}
                    options={rounds}
                    required
                  /> */}

              <div>
                <h2>Datos sociales</h2>
                <p>
                  Ingresa los datos para que los jugadores puedan encontrar o
                  compartir el evento.
                </p>
                <Select
                  disabled={loading}
                  inputSize={9}
                  labelSize={3}
                  name='addressId'
                  placeholder='Selecciona dirección'
                  label='Dirección'
                  onChange={this.onChange}
                  options={addresses}
                  select={tournament.addressId}
                  required
                />
                <Input
                  disabled={loading}
                  inputSize={9}
                  labelSize={3}
                  name='eventUrl'
                  placeholder='Url evento redes sociales'
                  label='Url Social'
                  onChange={this.onChange}
                  value={tournament.eventUrl}
                  required={socialRequired}
                />
              </div>
            </form>

            <div className='BaseRight__Bottom__actions'>
              <Button
                id='btn_Tournament1'
                disabled={loading || errors}
                type='submit'
                text='Registrar Torneo'
                state='primary'
                form='StoreRegisterForm'
                className='btn-register'
                style={{ margin: 'auto' }}
              />
            </div>
          </Jumbotron>
        </Container>
      </Wrapper>
    )
  }
}

// @Proptypes
TournamentRegisterView.propTypes = {
  loggedUser: PropTypes.object
}

/*
  @Store Connection: connect
  @Export Component
*/

const mapStateToProps = state => ({
  loggedUser: state.loggedUser,
  countries: state.countries
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      TournamentRegister,
      getFormats,
      GameList,
      getTournamentTypes,
      getUser
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(TournamentRegisterView))

// @Styles
const Wrapper = styled.div.attrs({ className: 'Tournament' })`
  height: inherit;
  background: url(${bgBackground('fondo1')}) 50% 0;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding: 20px 0px;
`
