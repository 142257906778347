// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { axiosError, getToken } from '../../../Services'

const setPasswdByToken = params => {
  return async dispatch => {
    getToken()
    const query = `
      mutation UserPasswdUpdateAnon($UserPasswdUpdateInput: UserPasswdUpdateInput!) {
        UserPasswdUpdateAnon(input: $UserPasswdUpdateInput) {
          status  {
            success
            name
            detail
          }
          user {
            Person {
              id
              avatar
              fullName
              urlAvatar
              code
            }
          }
        }
      }
    `
    const variables = {
      UserPasswdUpdateInput: {
        userId: params.userId,
        passwd: params.passwd,
        token: params.token
      }
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then(response => {
        if (response.data.errors) {
          return response.data.errors[0].message
        } else {
          const { UserPasswdUpdateAnon } = response.data.data
          return UserPasswdUpdateAnon
        }
      })
      .catch(err => axiosError(err))
  }
}

export default setPasswdByToken
