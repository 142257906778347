import { injectGlobal } from 'styled-components'
import base from './base'
import Datapicker from './Datapicker'
import boostrap from './boostrap'
import fancyBox from './fancyBox'
import formGroup from './formGroup'
import helpers from './helpers'
import modal from './modal'
import popover from './popover'
import text from './text'

const styles = injectGlobal`
  ${boostrap}
  ${base}
  ${Datapicker}
  ${text}
  ${formGroup}
  ${modal}
  ${popover}
  ${helpers}
  ${fancyBox}
`
export default styles
