import { bgBackground } from '../../../../assets/img/login/'

const { BaseLeft } = require('../../../../assets/img/login')

const sections = {
  home: BaseLeft.Image1,
  ranking: BaseLeft.Image2,
  profile: BaseLeft.Image3,
  login: BaseLeft.Image4,
  store: BaseLeft.Image5,
  tournament: BaseLeft.Image6,
  admin: BaseLeft.Image7,
  tournament_round: BaseLeft.Image8,
  error: BaseLeft.Image9
}

const loginBase = () => {
  return `
    .Section {
      &__home .BaseLeft {
        background: url(${sections.home}) 50% 0;
      }
      &__ranking .BaseLeft {
        background: url(${sections.ranking}) 50% 0;
      }
      &__profile .BaseLeft {
        background: url(${sections.profile}) 30% 0;
      }
      &__login .BaseLeft {
        background: url(${sections.login}) 50% 0;
      }
      &__store .BaseLeft {
        background: url(${sections.store}) 50% 0;
      }
      &__tournament .BaseLeft {
        background: url(${sections.tournament}) 50% 0;
      }
      &__admin .BaseLeft {
        background: url(${sections.admin}) 50% 0;
      }
      &__tournament_round .BaseLeft {
        background: url(${sections.tournament_round}) 50% 0;
      }
      &__error .BaseLeft {
        background: url(${sections.error}) 50% 0;
      }

    }

    .BaseContent {
      display: flex;
      width: 100%;
    }
    .jumbotron {
      padding: 2rem 2rem;
    }
    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 425px) {
      .grid {
        grid-template-columns: 1fr;
      }
    }

    .BaseLeft {
      align-items: center;
      background: url(${bgBackground('zeus')}) 50% 0;
      display: flex;
      height: inherit;
      padding: 0 30px;
      position: relative;
      width: 0%;

      &:before {
        background-color: rgba(0, 0, 0, 0.35);
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }

      div {
        flex-basis: 100%;
        z-index: 1;
        padding: 5px;
        border-radius: 5px;
      }

      img {
        position: absolute;
        top: 0;
        width: 170px;
      }

      h1,
      h2 {
        color: #fff;
        text-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
      }

      h1 {
        font-size: 70px;
        font-weight: 300;
        margin-bottom: 0;
        margin-left: 0px;
      }

      hr {
        border-top: 2px solid white;
        margin: 20px 0;
        opacity: 0.5;
      }

      h2 {
        font-size: 40px;
        font-weight: 300;
        color: #c9c9c9;
        margin-bottom: 0;
      }
      p {
        margin: 0px;
        margin-top: 20px;
        color: #c9c9c9;
      }
    }

    .BaseRight {
      display: flex;
      flex-direction: column;
      height: inherit;
      justify-content: space-around;
      padding: 0 25px;
      position: relative;
      width: 100%;

      &__Form {
        form {
          margin-top: 30px;
        }
        .ImagenAvatar {
          display: block;
          text-align: center;
          margin: 20px;
        }

        .username {
          & > div {
            display: flex;
            width: 100%;

            & > div {
              width: 100%;
            }

            .Select {
              margin-right: 7.5px;

              select {
                border-radius: 4px;
              }
            }
            .container-input {
              width: 100%;
            }
          }
        }

        .InputUI {
          input {
            border-radius: 4px;
          }
        }

        i.fa {
          font-size: 1.1857rem;
        }

        .form-group {
          margin-bottom: 15px;
        }

        h2 {
          color: #7c7781;
          font-size: 26px;
          margin-top: 20px;
        }
        h4 {
          font-size: 16px;
          font-weight: 400;
        }
        .jumbotron {
          padding: 2rem 1rem;
          a {
            // margin: 10px 0px;
          }
          .lead {
            a {
              margin-right: 20px;
              i {
                margin-right: 5px;
              }
            }
          }
        }
      }

      &__Bottom {
        bottom: 20px;
        color: #c9c9d3;
        display: flex;
        justify-content: space-between;
        left: 50px;
        position: absolute;
        right: 50px;

        div {
          align-items: center;
          display: flex;
        }

        span {
          font-size: 0.8571rem;

          &:last-child {
            float: right;
            vertical-align: bottom;
          }
        }

        img {
          margin-left: 5px;
          max-width: 60px;
        }

        &__actions {
          button {
            display: block;
            margin: 20px auto;
          }
          a {
            margin: 10px auto;
          }
        }
      }

      .jumbotron .buttons {
        margin: 10px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        a {
          margin: 0px 10px;
        }
      }
    }

    @media screen and (max-width: 1023px) {
      .BaseContent {
        flex-direction: column;
        height: 100%;
      }

      .BaseLeft,
      .BaseRight {
        width: 100%;
      }

      .language {
        max-width: 100%;
        position: relative;
      }

      .BaseLeft {
        align-items: flex-end;
        background-position: 50% 0;
        background-size: cover;
        min-height: 490px;
        padding-bottom: 20px;
      }

      .BaseRight {
        align-items: center;
        justify-content: flex-start;
        min-height: 100%;
        padding-bottom: 70px;

        &__Form,
        &__Apps {
          // width: 350px;
        }

        & > div {
          width: 100%;
        }

        &__Form {
          form,
          h2 {
            margin-top: 30px;
          }
        }

        &__Bottom {
          border-top: 1px solid #c9c9c9;
          margin-top: 30px;
          padding: 20px 30px;
          position: static;
        }
      }
    }

    @media screen and (min-width: 1024px) {
      .BaseRight {
        min-height: 100vh;
      }
      .language {
        position: absolute;
      }
    }

    @media screen and (max-width: 540px) {
      .language {
        justify-content: center;
        padding: 20px 0;
      }
      .BaseContent {
        display: block;
        height: auto;
      }

      .BaseLeft,
      .BaseRight {
        padding-left: 0px;
        padding-right: 0px;
      }

      .BaseLeft {
        background: none;
        padding-bottom: 0;
        min-height: auto;

        img {
          position: relative;
          left: 50%;
          transform: translateX(-50%);
        }

        &:before,
        h1,
        hr,
        h2 {
          display: none;
        }
      }

      .btn-login {
        margin: 0 auto;
      }

      .BaseRight {
        align-items: center;
        justify-content: flex-start;
        padding-bottom: 0;

        &__Form {
          width: 100%;

          .username {
            & > div {
              display: block;
            }
          }
        }

        & > div {
          width: 100%;
        }

        &__Apps {
          margin-top: 30px;
        }

        &__Bottom {
          align-items: center;
          border-color: #e4e4e4;
          height: auto;
          margin-top: 20px;
          padding: 20px 0;
          & > span {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }

    @media screen and (max-width: 425px) {
      .BaseRight {
        .jumbotron .buttons {
          flex-direction: column;
          a, button {
            margin: 5px 0px;
            width: 100%;
          }
        }
      }
    }
    @media screen and (max-width: 320px) {
      .BaseLeft,
      .BaseRight {
        padding-left: 5px;
        padding-right: 5px;
      }

      .BaseLeft {
        img {
          width: 130px;
        }
      }
      .btn-login {
        width: 100%;
      }
      .BaseRight {
        &__Form {
          h2,
          form {
            margin-top: 30px;
          }
        }
      }
    }
  `
}

const Base = `
  .padding-20 {
    padding: 20px;
  }
  body {
    background-color: #FFF;
  }
  .nucleo-icon {
    vertical-align: middle;
  }

  select{
    appearance: none
  }

  article {
    padding: 15px 0;
  }

  footer {
    padding: 15px 30px;
  }

  .form-control {
    color: #777781;

    &::-webkit-input-placeholder {
      color: #dedee8;
    }
  }

  svg {
    flex-shrink: 0;
  }

  .animationContainer{
    overflow: hidden;
  }
  ${loginBase()}

  .ReportResult {
    .result {
      font-size: 44px;
    }
  }

  table {
    td {
      padding: 5px 4px !important;
      vertical-align: middle !important;
    }
  }
`

export default Base
