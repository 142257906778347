const FancyBox = `
  .fancybox{
    &-container{
      > .fancybox-bg{
        background: #fff;
      }
      > .fancybox-inner{
        .fancybox-button{
          background: rgba(255,255,255,.6);
          color: black;
        }
      }
    }
  }
`
export default FancyBox
