// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { getToken, axiosError } from '../../../Services'

export default (seasonId, filters, gameId = 0) => {
  return dispatch => {
    getToken()
    const query = `
      query TournamentList($TournamentListInput: TournamentListInput!) {
        TournamentList(input: $TournamentListInput) {
          status {
            success
            name
            detail
          }
          total
          pages

          Tournaments {
            id
            name
            description
            urlView
            startDate
            maxPlayers
            rounds
            image
            urlImage
            validDates
            isPresential
            Store {
              urlView
              name
            }
            Format {
              name
            }
            TournamentType {
              name
              urlImage
            }
            TournamentStatus {
              name
              description
              style
              icon
            }
            Game {
              name
            }
            Address {
              Country {
                name
                iso
              }
            }
            Season {
              id
              name
              isActive
              rankingUrl
            }
          }
          Types {
            id
            name
          }
          Stores {
            id
            name
          }
          Games {
            id
            name
          }
          Seasons {
            id
            name
            isActive
            rankingUrl
          }
          Countries {
            id
            name
          }
          Regions {
            id
            fullName
          }
        }
      }
    `
    const variables = {
      TournamentListInput: {
        seasonId,
        gameId,
        filter: filters
      }
    }

    return (
      axios
        // @ts-ignore
        .post(`${apiURL}/graphql`, { query, variables }, {})
        .then(response => {
          if (response.status === 200) {
            return response.data.data.TournamentList
          } else {
            return []
          }
        })
        .catch(err => axiosError(err, 'TournamentList', dispatch))
    )
  }
}
