// @External Dependencies
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { Row, Col, Container, Jumbotron } from 'reactstrap'
import { Link } from 'react-router-dom'
import { bgBackground } from '../../../assets/img/login'
// @Dependencies
import { SeasonList, getGames, getUser } from '../../Redux/Actions'
import { axiosAbort } from '../../Services'
import { LoadingSVG, Select } from '../../Components/UI'
import ListSeasons from './List'

// @Component
class SeasonsView extends React.Component {
  state = {
    loading: true,
    loadingMessage: 'Estamos buscando las temporadas',
    buttonBack: '',
    Seasons: [],
    Games: [],
    gameId: 0
  }

  async componentDidMount () {
    await this.props.getUser()
    if (!this.props.loggedUser.isAdmin) {
      this.setState({
        loading: true,
        loadingMessage: 'Solo administradores pueden ver esta sección.',
        buttonBack: '/'
      })
    } else {
      this.search()
    }
  }
  componentWillUnmount () {
    axiosAbort()
  }

  search = async () => {
    this.setState({ loading: true })
    const Seasons = await this.props.SeasonList()
    const Games = await this.props.getGames()
    this.setState({
      Seasons,
      allSeasons: Seasons,
      Games,
      loading: false
    })
  }
  onChange = e => {
    let { allSeasons } = this.state
    const value = Number(e.target.value) || 0

    this.setState({
      gameId: value,
      Seasons: value
        ? allSeasons.filter(season => season.gameId === value)
        : allSeasons
    })
    return ''
  }

  render () {
    const { loading, Seasons } = this.state
    if (loading)
      return (
        <LoadingSVG
          message={this.state.loadingMessage}
          buttonBack={this.state.buttonBack}
        />
      )
    return (
      <Wrapper>
        <Container>
          <Row>
            <Col md={12}>
              <article className='Ranking'>
                <Jumbotron>
                  <Row>
                    <Col md={12}>
                      <h1 className='text-center'>Temporadas de Juego</h1>
                      <p>
                        Las temporadas de juego siempre están asociadas a un{' '}
                        <b>Juego</b>, y permiten diferenciar los Rankings
                      </p>
                      <p>
                        Cada vez que se crea un torneo y en base al{' '}
                        <b>Formato</b> que esté relacionado, dependerá a que
                        temporada se asocia.
                      </p>
                      <p>
                        Los Juegos permitidos en el sistema son los siguientes:
                      </p>
                      <ul>
                        {this.state.Games.map(game => {
                          return <li key={game.id}>{game.name}</li>
                        })}
                      </ul>
                    </Col>
                    <Col md={12} className='grid'>
                      <div>
                        <Link
                          to={'/admin/seasons/create'}
                          className='btn btn-primary btn-block'>
                          Crear Temporada de juego
                        </Link>
                        <p className='text-center'>
                          <b className='text-danger'>Importante:</b>{' '}
                          <u>
                            Sólo puede haber 1 temporada
                            <br />
                            DISPONIBLE por juego a la vez.
                          </u>
                        </p>
                      </div>
                      <Select
                        disabled={loading}
                        inputSize={8}
                        labelSize={4}
                        name='gameId'
                        label='Filtrar por juego'
                        onChange={e => this.onChange(e)}
                        select={this.state.gameId}
                        options={this.state.Games}
                        required
                      />
                    </Col>
                  </Row>
                </Jumbotron>
                {Seasons.length > 0 ? (
                  <ListSeasons
                    seasons={Seasons}
                    isMobile={this.props.isMobile}
                  />
                ) : (
                  <Jumbotron>
                    <h2>No se encontraron temporadas.</h2>
                  </Jumbotron>
                )}
              </article>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    )
  }
}

// @Proptypes
SeasonsView.propTypes = {
  loggedUser: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  loggedUser: state.loggedUser
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      SeasonList,
      getGames,
      getUser
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(SeasonsView))

// @Styles
const Wrapper = styled.div.attrs({ className: 'Seasons' })`
  background: url(${bgBackground('fondo2')}) 50% 0;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding: 80px 0px;

  .container {
    padding: 20px;
    background-color: white;
  }
  .Ranking {
    padding: 0px;
    &__table {
      td,
      th {
        padding: 3px;
        cursor: pointer;
        vertical-align: middle;
      }
    }
    &__footer {
      width: 100%;
      text-align: center;
      button {
        margin: 0 auto !important;
      }
    }
  }
  .Tournaments {
    .btn-link {
      display: block;
      text-align: right;
    }
  }
`
