// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../constants'
import { getToken, axiosError } from '../../../../Services'

const FindRelatedTournaments = tournamentId => {
  return async () => {
    getToken()
    const query = `
      query TournamentFindRelated($TournamentFindRelatedInput: TournamentFindRelatedInput!) {
        TournamentFindRelated(input: $TournamentFindRelatedInput) {
          status {
            success
            name
            detail
          }
          Tournaments {
            id
            name
            TournamentType {
              id
              name
              private
              urlImage
            }
          }
        }
      }

    `
    const variables = {
      TournamentFindRelatedInput: {
        tournamentId
      }
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then(response => {
        if (response.data.errors) {
          return response.data.errors[0].message
        } else {
          const { TournamentFindRelated } = response.data.data
          return TournamentFindRelated
        }
      })
      .catch(err => axiosError(err))
  }
}

export default FindRelatedTournaments
