// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../constants'
import { getToken, axiosError } from '../../../../Services'

const RoundReport = data => {
  return async dispatch => {
    getToken()
    const query = `
      mutation RoundReport($RoundReportInput: RoundReportInput!) {
        RoundReport(input: $RoundReportInput) {
          status {
            success
            name
            detail
          }
          Match {
            id
            table
            reported
          }
        }
      }


    `
    const variables = {
      RoundReportInput: {
        id: data.id,
        dropOpponent: data.dropOpponent,
        dropPlayer: data.dropPlayer,
        roundId: data.roundId,
        table: data.table,
        tournamentId: data.tournamentId,
        winnerId: data.winnerId,
        loserId: data.loserId,
        draw: data.draw,
        winnerVictories: data.winnerVictories,
        winnerDefeats: data.winnerDefeats,
        winnerDraws: data.winnerDraws,
        loserVictories: data.loserVictories,
        loserDefeats: data.loserDefeats,
        loserDraws: data.loserDraws
      }
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then(response => {
        if (response.data.errors) {
          return response.data.errors[0].message
        } else {
          const { RoundReport } = response.data.data
          return RoundReport
        }
      })
      .catch(err => axiosError(err))
  }
}

export default RoundReport
