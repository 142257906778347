// @External Dependencies
import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

// @Dependencies
import App from '../App'
import AppFrame from './Components/AppFrame'

// Users
import UserLogin from './Views/Users/login'
import UserLogout from './Views/Users/Logout/index'
import UserList from './Views/Users/List/'
import UserRecover from './Views/Users/recover'
import UserChangePasswd from './Views/Users/recover_change'
import UserRegister from './Views/Users/register'
import UserProfile from './Views/Users/profile'
import UserTournamentsView from './Views/Users/tournaments'
import UserUpdate from './Views/Users/Update'
import UserValidate from './Views/Users/validate'

// Tienda
import StoreView from './Views/Stores/view'
import StoreList from './Views/Stores/'
import StoreDetail from './Views/Stores/detail'
import StoreTournaments from './Views/Stores/tournaments'
import StoreRegister from './Views/Stores/register'
import StoreUpdate from './Views/Stores/update'

// Torneos
import Tournaments from './Views/Tournaments/index'
import TournamentRegister from './Views/Tournaments/register'
import TournamentEdit from './Views/Tournaments/edit'
import TournamentDetail from './Views/Tournaments/detail'
import TournamentPlayers from './Views/Tournaments/Players/'
import TournamentList from './Views/TournamentsList'

// Rondas
import TournamentRoundView from './Views/Tournaments/Rounds/view'
import TournamentRoundStandingView from './Views/Tournaments/Rounds/standing'
import TournamentRoundListView from './Views/Tournaments/Rounds/list'

// Ranking
import RankingView from './Views/Ranking/'
import { RankingGamesListView } from './Views/Ranking/games'

// Admin Section
import TournamentTypes from './Views/TournamentType/'
import TournamentTypeCreate from './Views/TournamentType/create'
import TournamentTypeUpdate from './Views/TournamentType/update'
import Formats from './Views/Formats/'
import FormatCreate from './Views/Formats/create'
import FormatUpdate from './Views/Formats/update'
import Seasons from './Views/Seasons/'
import SeasonCreate from './Views/Seasons/create'
import SeasonUpdate from './Views/Seasons/update'

import GameList from './Views/Games/index'
import GameCreate from './Views/Games/create'
import GameUpdate from './Views/Games/update'

import PageError404 from './Views/Errors/404'

// Lógica de encuestas.
import { SurveySupplant } from './Views/Survey/supplant'
import { SurveyView } from './Views/Survey/view'

// @Component
const AppRoutes = () => (
  <App>
    <Switch>
      <PublicRoute
        exact
        path='/'
        component={RankingGamesListView}
        section='home'
      />
      <PublicRoute
        exact
        path='/home'
        component={RankingGamesListView}
        section='home'
      />
      <PublicRoute
        exact
        path='/ranking'
        component={RankingGamesListView}
        section='ranking'
      />
      <PublicRoute
        exact
        path='/ranking/season/:gameId/:seasonId/:slug'
        component={RankingView}
        section='ranking'
      />
      {/* Usuarios */}
      <PrivateRoute
        exact
        path='/profile'
        component={UserProfile}
        section='profile'
      />
      <PrivateRoute exact path='/users' component={UserList} section='users' />
      <PrivateRoute
        exact
        path='/profile/update'
        component={UserUpdate}
        section='profile'
      />
      <PrivateRoute
        exact
        path='/user/edit/:personId/:slug'
        component={UserUpdate}
        section='profile'
      />
      <Route
        exact
        path='/users/validate/:token'
        component={UserValidate}
        section='login'
      />
      <PublicRoute exact path='/login' component={UserLogin} section='login' />
      <PrivateRoute
        exact
        path='/users/inactive'
        component={UserLogout}
        section='profile'
      />
      <PublicRoute
        exact
        path='/recover'
        component={UserRecover}
        section='login'
      />
      <PublicRoute
        exact
        path='/survey/:playerId/:token'
        component={SurveySupplant}
        section='survey'
      />
      <PrivateRoute
        exact
        path='/survey/:playerId'
        component={SurveyView}
        section='store'
      />
      <PublicRoute
        exact
        path='/change-passwd/:userId/:token'
        component={UserChangePasswd}
        section='login'
      />
      <PublicRoute
        exact
        path='/register'
        component={UserRegister}
        section='login'
      />
      {/* Tienda */}
      <PrivateRoute exact path='/store' component={StoreView} section='store' />
      <PublicRoute exact path='/stores' component={StoreList} section='store' />
      <PublicRoute
        exact
        path='/store/detail/:storeId/:slug'
        component={StoreDetail}
        section='store'
      />
      <PublicRoute
        exact
        path='/store/tournaments/:storeId/:slug'
        component={StoreTournaments}
        section='store'
      />
      <PublicRoute
        exact
        path='/store/tournaments_past/:storeId/:slug'
        component={StoreTournaments}
        section='store'
      />
      <PrivateRoute
        exact
        path='/store/update'
        component={StoreUpdate}
        section='store'
      />
      <PrivateRoute
        exact
        path='/store/update/:storeId'
        component={StoreUpdate}
        section='store'
      />
      <PrivateRoute
        exact
        path='/store/register'
        component={StoreRegister}
        section='store'
      />
      {/* Torneos */}
      <PublicRoute
        exact
        path='/tournament/detail/:tournamentId/:slug'
        component={TournamentDetail}
        section='tournament'
      />
      <PrivateRoute
        exact
        path='/tournament/players/:tournamentId/:slug'
        component={TournamentPlayers}
        section='tournament'
      />
      <PublicRoute
        exact
        path='/tournament/players/:tournamentId/:slug/:pdf'
        component={TournamentPlayers}
        section='tournament'
      />
      <PublicRoute
        exact
        path='/tournaments'
        component={Tournaments}
        section='tournament'
      />
      <PublicRoute
        exact
        path='/torneos'
        component={TournamentList}
        section='tournament'
      />
      <PublicRoute
        exact
        path='/tournaments/past'
        component={Tournaments}
        section='tournament'
      />
      <PublicRoute
        exact
        path='/tournaments/:storeId'
        component={Tournaments}
        section='tournament'
      />
      <PublicRoute
        exact
        path='/user/tournaments/:code'
        component={UserTournamentsView}
        section='tournament'
      />
      <PublicRoute
        exact
        path='/user/tournaments/:code/:organizer'
        component={UserTournamentsView}
        section='tournament'
      />
      <PrivateRoute
        exact
        path='/tournament/register'
        component={TournamentRegister}
        section='tournament'
      />
      <PrivateRoute
        exact
        path='/tournament/edit/:tournamentId/:slug'
        component={TournamentEdit}
        section='tournament'
      />

      {/* CRUD Tipos Torneos */}
      <PrivateRoute
        exact
        path='/tournament-types'
        component={TournamentTypes}
        section='admin'
      />
      <PrivateRoute
        exact
        path='/admin/tournament_type/create'
        component={TournamentTypeCreate}
        section='admin'
      />
      <PrivateRoute
        exact
        path='/admin/tournament_type/update/:id/:slug'
        component={TournamentTypeUpdate}
        section='admin'
      />

      <PrivateRoute exact path='/games' component={GameList} section='admin' />
      <PrivateRoute
        exact
        path='/admin/games/create'
        component={GameCreate}
        section='admin'
      />
      <PrivateRoute
        exact
        path='/admin/games/update/:id/:slug'
        component={GameUpdate}
        section='admin'
      />

      {/* CRUD Formatos de Juego */}
      <PrivateRoute
        exact
        path='/admin/seasons'
        component={Seasons}
        section='admin'
      />
      <PrivateRoute
        exact
        path='/admin/seasons/create'
        component={SeasonCreate}
        section='admin'
      />
      <PrivateRoute
        exact
        path='/admin/seasons/update/:id/:slug'
        component={SeasonUpdate}
        section='admin'
      />
      <PrivateRoute exact path='/formats' component={Formats} section='admin' />
      <PrivateRoute
        exact
        path='/admin/formats/create'
        component={FormatCreate}
        section='admin'
      />
      <PrivateRoute
        exact
        path='/admin/formats/update/:id/:slug'
        component={FormatUpdate}
        section='admin'
      />

      {/* Rondas */}
      <PublicRoute
        exact
        path='/tournament/rounds/:tournamentId/:slug'
        component={TournamentRoundListView}
        section='tournament_round'
      />
      <PublicRoute
        exact
        path='/tournament/round/:roundId/:slug'
        component={TournamentRoundView}
        section='tournament_round'
      />
      <PublicRoute
        exact
        path='/tournament/round/:roundId/:slug/:pdf'
        component={TournamentRoundView}
        section='tournament_round'
      />
      <PrintRoute
        exact
        path='/tournament/round/:roundId/:slug/pdf/:tables'
        component={TournamentRoundView}
        section='tournament_round'
      />
      <PublicRoute
        exact
        path='/tournament/standing/:roundId/:slug'
        component={TournamentRoundStandingView}
        section='tournament_round'
      />
      <PublicRoute
        exact
        path='/tournament/standing/:roundId/:slug/:pdf'
        component={TournamentRoundStandingView}
        section='tournament_round'
      />
      <Route component={PageError404} section='error' />
    </Switch>
  </App>
)

// @Export Component
export default AppRoutes

const CustomRoute = ({ component: Component, loggedUser, t, ui, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      Object.keys(loggedUser).length ? (
        <AppFrame {...props} loggedUser={loggedUser} t={t} ui={ui} {...rest}>
          <Component {...props} />
        </AppFrame>
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location, protected: true }
          }}
        />
      )
    }
  />
)

const CustomPublicRoute = ({
  component: Component,
  loggedUser,
  t,
  ui,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => (
      <AppFrame {...props} loggedUser={loggedUser} t={t} ui={ui} {...rest}>
        <Component {...props} />
      </AppFrame>
    )}
  />
)

const Print = ({ component: Component, loggedUser, t, ui, ...rest }) => (
  <Route {...rest} render={props => <Component {...props} />} />
)

// @Store Connection: connect
const mapStateToProps = state => ({
  loggedUser: state.loggedUser,
  ui: state.ui
})

const PrivateRoute = connect(
  mapStateToProps,
  null
)(withTranslation()(CustomRoute))

const PublicRoute = connect(
  mapStateToProps,
  null
)(withTranslation()(CustomPublicRoute))

const PrintRoute = connect(
  mapStateToProps,
  null
)(withTranslation()(Print))
