// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { axiosError, getToken } from '../../../Services'

const requestId = 'allTournamentStatus'

export default () => {
  return dispatch => {
    getToken()
    const query = `
      query {
        allTournamentStatuss(orderBy: id_ASC) {
          id
          name
          description
        }
      }
    `

    return axios
      .post(`${apiURL}/graphql`, { query }, { requestId })
      .then(response => {
        if (response.status === 200) {
          return response.data.data.allTournamentStatuss
        } else {
          return []
        }
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}
