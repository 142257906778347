// @External Dependencies
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

// @Dependencies
import BaseForm from './BaseForm'
import vars from '../variables'

// @Component
export class Select extends Component {
  state = {
    currentValue: this.props.select || 0,
    placeholder: ''
  }

  static getDerivedStateFromProps (nextsProps) {
    let placeholder = nextsProps.t('Seleccionar')
    if (nextsProps.placeholder) {
      placeholder = nextsProps.placeholder
    }

    return {
      placeholder,
      currentValue: nextsProps.select || 0
    }
  }

  handleChange = e => {
    const { onChange } = this.props
    this.setState({ currentValue: e.target.value })
    if (onChange !== undefined) {
      onChange(e)
    }
  }

  setValue = (option, key) => {
    if (option.id >= 0) {
      return option.id
    } else {
      return key + 1
    }
  }

  setText = option => {
    if (option.nombre || option.name) {
      return option.nombre || option.name
    } else {
      return option
    }
  }

  render () {
    const {
      disabled,
      icon,
      info,
      inputSize,
      labelSize,
      label,
      loading,
      name,
      noDefaultValue,
      options,
      required,
      size,
      state,
      t,
      tooltip
    } = this.props
    const { currentValue, placeholder } = this.state
    return (
      <StyledSelect>
        <BaseForm
          icon={icon}
          info={info}
          inputSize={inputSize}
          label={label}
          labelSize={labelSize}
          loading={loading || (!options || (!options.length && currentValue))}
          name={name}
          required={required}
          tooltip={tooltip}>
          <select
            className={`form-control input-${size}`}
            disabled={disabled || loading}
            id={name}
            name={name}
            onChange={this.handleChange}
            required={required}
            state={state}
            value={currentValue}>
            {/* cuando no trae opciones y trae valor ó cuando esta cargando */}
            {(!options ||
              (options.length === 0 && currentValue) ||
              loading) && <option value=''>{t('Cargando...')}</option>}
            {/* cuando no trae valor por defecto y no esta cargando */}
            {!loading && !noDefaultValue && (
              <option value=''>{placeholder}</option>
            )}

            {options &&
              options.length > 0 &&
              options.map((option, key) => {
                return (
                  <option
                    key={key}
                    name={`data[${name}]`}
                    value={this.setValue(option, key)}>
                    {this.setText(option)}
                  </option>
                )
              })}
          </select>
          <span className='caret' />
        </BaseForm>
      </StyledSelect>
    )
  }
}

// @Proptypes
Select.propTypes = {
  defaultOption: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  info: PropTypes.node,
  inputSize: PropTypes.number,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  noDefaultValue: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  select: PropTypes.node,
  size: PropTypes.string,
  state: PropTypes.string,
  tooltip: PropTypes.string
}

Select.defaultProps = {
  size: 'md'
}

// @Export Component
export default withTranslation()(Select)

// @Styles
const StyledSelect = styled.div`
  select {
    line-height: ${vars.lineHeight};
  }
  .caret {
    border-left: ${vars.borderRadiusBtn} solid transparent;
    border-right: ${vars.borderRadiusBtn} solid transparent;
    border-top: 7px solid;
    color: ${vars.setColors.default};
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
  }
`
