// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { getToken, axiosError } from '../../../Services'

const requestId = 'getTournamentInfo'
export default ({ tournamentId, listPlayers }) => {
  return dispatch => {
    const token = getToken()
    const TournamentPlayers = listPlayers
      ? `
      TournamentPlayers(paginate: 9999) {
        id
        byes
        drop
        TournamentRoundDrop {
          name
        }
        Person(orderBy: fullName_ASC) {
          id
          fullName
          code
        }
      }
    `
      : ''
    const query = `
      query {
        Tournament(id: ${tournamentId}) {
          ${TournamentPlayers}
          id
          description
          eventUrl
          gameId
          formatId
          statusId
          storeId
          typeId
          seasonId
          private
          image
          urlImage
          maxPlayers
          name
          rankingValid
          rounds
          startDate
          urlAddPlayers
          urlEdit
          urlRounds
          urlStandings
          urlView
          validDates
          isPresential
          private
          Store {
            id
            name
            description
            phone
            email
            siteUrl
            logo
            urlView
            Level {
              name
              description
            }
            Person {
              id
              avatar
              fullName
              urlAvatar
              code
            }
          }
          addressId
          Address {
            name
            address
            Country {
              name
              iso
            }
            Region {
              fullName
            }
            Location {
              name
            }
          }
          seasonId
          Season {
            name
            description
            startDate
            endDate
            rankingUrl
            Game {
              id
              name
            }
          }
          Game {
            id
            name
            urlImage
            RaceGames {
              raceId
              sortOrder
              Race {
                name
              }
            }
          }
          TournamentStatus {
            id
            name
            style
            description
            edit
          }
          TournamentType {
            name
            description
            winnerPoints
            minPlayers
            maxTopPlayers
            loserPoints
            drawPoints
            multiplier
            status
            urlImage
          }
          Format {
            id
            name
            description
            isFullRacial
          }
          TournamentRounds(orderBy: sortOrder_DESC, page: 1, paginate: 1) {
            id
            name
            statusId
          }
          TournamentRelateds {
            id
            quotas
            tournamentId
            relatedId
            Tournament {
              name
              startDate
              urlView
              urlImage
              isPresential
              TournamentType {
                name
                urlImage
              }
              Format {
                name
              }
              Season {
                name
                rankingUrl
                Game {
                  name
                }
              }
              Address {
                Country {
                  iso
                }
              }
              TournamentStatus {
                name
                style
              }
            }
          }
          TournamentClasifications {
            id
            quotas
            tournamentId
            relatedId
            TournamentRelated {
              name
              startDate
              urlView
              urlImage
              TournamentType {
                name
                urlImage
              }
              Format {
                name
              }
              Season {
                name
                rankingUrl
                Game {
                  name
                }
              }
              Address {
                Country {
                  iso
                }
              }
              TournamentStatus {
                name
              }
            }
          }
          TournamentOrganizers {
            personId
          }
        }
      }
    `
    return axios
      .post(`${apiURL}/graphql`, { query }, { requestId })
      .then(response => {
        const data = Object.keys(response.data.data.Tournament)
        if (response.status === 200 && !response.data.errors && data.length) {
          const isPrivate = response.data.data.Tournament.private

          const organizers = response.data.data.Tournament.TournamentOrganizers.map(
            or => {
              return or.personId
            }
          )
          const isOrganizer =
            token && token.personId && organizers.includes(token.personId)
          const isAdmin = token && token.isAdmin

          let error = true
          if (isAdmin || isOrganizer) {
            error = false
          }
          if (isPrivate && (!token || error)) {
            throw new Error(
              'Este torneo es privado, contacta al organizador para ver las rondas y emparejamientos.'
            )
          }
          return {
            ...response.data.data.Tournament,
            status: {
              success: true,
              name: 'Respuesta Exitosa'
            }
          }
        } else {
          return {
            status: {
              success: false,
              name: response.data.errors[0].message
            }
          }
        }
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}
