// @External Dependencies
import React, { Component } from 'react'
// import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { Jumbotron, Col, Card, Container } from 'reactstrap'
import { Link } from 'react-router-dom'
import { bgBackground } from '../../../assets/img/login'
// @Dependencies
import {
  getStoreInfo,
  StoreUpdate,
  getStoreClasifications,
  getStoreLevels,
  getUser,
  getGames,
  getCountries,
  getRegions,
  getLocations,
  getTournamentTypeList,
  UploadImage
} from '../../Redux/Actions'
import {
  Alert,
  Button,
  Input,
  Select,
  Textarea,
  LoadingSVG,
  Toggle,
  UIVars
} from '../../Components/UI'
import { axiosAbort } from '../../Services'

// @Component
class StoreUpdateView extends Component {
  state = {
    alertContent: '',
    alertState: 'warning',
    loading: true,
    Store: {
      Person: {}
    },
    address: {
      name: '',
      address: '',
      countryId: 0,
      regionId: 0,
      locationId: 0
    },
    activeStatus: ['Activa', 'Inactiva'],
    countries: [],
    regions: [],
    clasifications: [],
    locations: [],
    daysToggle: [
      { id: 1, name: 'Lunes', status: false },
      { id: 2, name: 'Martes', status: !false },
      { id: 3, name: 'Miércoles', status: false },
      { id: 4, name: 'Jueves', status: false },
      { id: 5, name: 'Viernes', status: false },
      { id: 6, name: 'Sábado', status: false },
      { id: 7, name: 'Domingo', status: false }
    ],
    games: [],
    levels: [],
    storeLogo: '',
    isAdmin: false,
    TournamentTypes: []
  }

  /**
   * - Al iniciar busca los paises y datos de la tienda
   * - Si estoy logueado entonces me manda al home
   */
  async componentDidMount () {
    const {
      loggedUser,
      getStoreInfo,
      history,
      getCountries,
      getRegions,
      getLocations,
      match
    } = this.props

    if (!loggedUser && history) {
      history.push('/')
    }

    const isAdmin = loggedUser.isAdmin
    let storeId = 0
    const paramStore = Number(match.params.storeId)
    if (isAdmin && paramStore) {
      storeId = paramStore
    } else {
      storeId = loggedUser.user.Store.id
    }

    const { Store } = await getStoreInfo(storeId)
    const countries = await getCountries()
    const regions = await getRegions(Store.Address.countryId)
    const locations = await getLocations(Store.Address.regionId)
    let TournamentTypes = await this.props.getTournamentTypeList()
    TournamentTypes = TournamentTypes.map(tt => {
      const type = Store.StoreTournamentTypes.filter(
        ({ typeId }) => typeId === tt.id
      )
      return {
        id: 0,
        storeId: Store.id,
        name: tt.name,
        typeId: tt.id,
        quantity: type.length > 0 ? type[0].quantity : 0,
        default: tt.storeTournaments,
        private: tt.private
      }
    }).filter(f => !f.private)

    let { daysToggle } = this.state

    daysToggle = daysToggle.map(day => {
      const status =
        Store.StoreTournamentDays.filter(
          ({ TournamentDay }) => day.id === TournamentDay.id
        ).length > 0
      day.status = status
      return day
    })
    const clasifications = await this.props.getStoreClasifications()
    const levels = await this.props.getStoreLevels()
    let games = await this.props.getGames()
    games = games.map(({ id, name }) => {
      return {
        id,
        name,
        status: Store.StoreGames.some(({ gameId }) => gameId === id)
      }
    })
    this.setState({
      games,
      levels,
      countries,
      regions: regions.map(r => {
        return {
          id: r.id,
          name: r.fullName
        }
      }),
      locations,
      Store,
      address: Store.Address,
      loading: false,
      daysToggle,
      isAdmin,
      clasifications,
      TournamentTypes,
      storeLogo: Store.urlLogo
    })
  }
  componentWillUnmount () {
    axiosAbort()
  }

  handleDismissAlert = () => {
    this.setState({ alertContent: '' })
  }

  upload = e => {
    const files = e.target.files
    const file = files[0]
    if (['image/jpeg', 'image/png'].includes(file.type)) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = async e => {
        const response = await this.props.UploadImage({
          file: e.target.result,
          name: files[0].name,
          type: 'store.logo'
        })

        if (response.status.success) {
          this.setState({
            storeLogo: response.Person.Store.urlLogo
          })
        }
      }
    } else {
      alert('Error en formato, sólo se permiten JPG o PNG')
    }
  }

  onSubmit = async event => {
    event.preventDefault()
    const { StoreUpdate, history } = this.props
    const { Store, address, daysToggle, TournamentTypes, games } = this.state

    this.setState({ alertContent: '', loading: true })

    // Armar data para enviar Form
    const data = {
      ...Store,
      games: games.filter(({ status }) => status),
      address,
      tournamentDays: daysToggle.filter(({ status }) => status),
      tournamentTypes: TournamentTypes.filter(({ quantity }) => quantity > 0)
    }

    const resolver = await StoreUpdate(data)
    if (!resolver.status.success) {
      this.setState({
        alertContent: resolver.status.detail,
        loading: false
      })
    } else {
      this.setState({
        alertContent: resolver.status.name,
        alertState: 'success'
      })
      setTimeout(async () => {
        history.push(resolver.store.urlView)
      }, 3000)
    }
  }

  /**
   * Controla los cambios de estado del formulario
   */
  onChange = async e => {
    const { address, Store } = this.state
    const target = e.target

    if (target && target.id === 'name') {
      Store.name = target.value
    }
    if (target && target.id === 'description') {
      Store.description = target.value
    }
    if (target && target.id === 'active') {
      Store.active = Number(target.value)
    }
    if (target && target.id === 'email') {
      Store.email = target.value.trim()
    }
    if (target && target.id === 'phone') {
      Store.phone = target.value.trim()
    }
    if (target && target.id === 'siteUrl') {
      Store.siteUrl = target.value.trim()
    }
    if (target && target.id === 'facebookUrl') {
      Store.facebookUrl = target.value.trim()
    }
    if (target && target.id === 'inChargePerson') {
      Store.inChargePerson = target.value.trim()
    }
    if (target && target.id === 'client') {
      Store.client = target.value.trim()
    }
    if (target && target.id === 'clasificationId') {
      Store.clasificationId = Number(target.value)
    }
    if (target && target.id === 'levelId') {
      Store.levelId = Number(target.value)
    }

    if (target && target.id === 'addressName') {
      this.setState({
        address: {
          ...address,
          name: target.value
        }
      })
    }
    if (target && target.id === 'address') {
      this.setState({
        address: {
          ...address,
          address: target.value
        }
      })
    }
    this.setState({
      Store
    })

    /**
     * Actualiza datos de la dirección
     */
    if (target && target.id === 'regionId') {
      const regionId = Number(target.value)
      address.regionId = regionId
      address.locationId = 0
      const locations = await this.props.getLocations(regionId)
      this.setState({
        address,
        locations
      })
    }

    if (target && target.id === 'locationId') {
      const locationId = Number(target.value)
      address.locationId = locationId
      this.setState({
        address
      })
    }
  }

  onChangeModalToggle = e => {
    e.preventDefault()
    const target = e.target
    const { daysToggle, games } = this.state
    if (target.id === 'day0') {
      daysToggle[0].status = !daysToggle[0].status
    }
    if (target.id === 'day1') {
      daysToggle[1].status = !daysToggle[1].status
    }
    if (target.id === 'day2') {
      daysToggle[2].status = !daysToggle[2].status
    }
    if (target.id === 'day3') {
      daysToggle[3].status = !daysToggle[3].status
    }
    if (target.id === 'day4') {
      daysToggle[4].status = !daysToggle[4].status
    }
    if (target.id === 'day5') {
      daysToggle[5].status = !daysToggle[5].status
    }
    if (target.id === 'day6') {
      daysToggle[6].status = !daysToggle[6].status
    }

    const gamesToggle = games.map(game => {
      const replaceId = Number(target.id.replace('game', ''))
      if (game.id === replaceId) {
        game.status = !game.status
      }
      return game
    })

    this.setState({
      daysToggle,
      games: gamesToggle
    })
  }

  onChangeTT = (e, tournamentType) => {
    const target = e.target
    let { TournamentTypes } = this.state
    if (target.name === 'extraTournament') {
      const quantity = Number(target.value)

      if (quantity) {
        const type = TournamentTypes.filter(f => {
          return f.typeId === tournamentType.typeId
        })

        TournamentTypes = TournamentTypes.map(tt => {
          return {
            ...tt,
            quantity:
              type.length > 0 && type[0].typeId === tt.typeId
                ? quantity
                : tt.quantity
          }
        })

        this.setState({ TournamentTypes })
      }
    }
  }

  /**
   * Render vista
   */
  render () {
    const {
      loading,
      alertContent,
      alertState,
      countries,
      regions,
      locations,
      activeStatus,
      Store,
      address,
      daysToggle,
      isAdmin
    } = this.state
    const { t } = this.props

    if (loading) return <LoadingSVG message={alertContent} />

    return (
      <Wrapper>
        <Container>
          <Jumbotron>
            <h1 className='text-center'>Actualización de datos Tienda</h1>
            <p>{t('Ingrese todos los datos del formulario')}</p>
            <p>{t('Los datos de contacto principal serán: ')}</p>
            <ul>
              <li>{`Contacto Principal: ${Store.Person.fullName} `}</li>
              <li>{`Email: ${Store.Person.email} `}</li>
            </ul>
            <form
              onSubmit={this.onSubmit}
              acceptCharset='utf-8'
              id='StoreUpdateForm'>
              {alertContent.length > 0 && (
                <Alert color={alertState} close={this.handleDismissAlert}>
                  {alertContent}
                </Alert>
              )}
              <Input
                disabled={loading}
                inputSize={10}
                name='name'
                placeholder={t('Nombre tienda')}
                type='input'
                label={t('Nombre')}
                onChange={this.onChange}
                value={Store.name}
                required
              />
              <Textarea
                disabled={loading}
                inputSize={10}
                name='description'
                placeholder={t('Descripción')}
                label={t('Descripción')}
                onChange={this.onChange}
                value={Store.description}
                required
              />
              <Col>
                <Input
                  disabled={loading}
                  inputSize={10}
                  label={t('Logo Tienda')}
                  name='photo'
                  placeholder={t('Logo Tienda')}
                  value=''
                  onChange={this.upload}
                  type='file'
                />
                {this.state.storeLogo && (
                  <img
                    src={this.state.storeLogo}
                    alt={Store.name}
                    style={{
                      margin: '0 auto',
                      display: 'block',
                      width: '40%'
                    }}
                  />
                )}
              </Col>
              <Col>
                <h2>Días de Torneos</h2>
                <p>
                  <b>Detalla que días se realizan torneos. </b>
                  Este sirve para que los jugadores puedan encontrar tus
                  torneos.
                </p>
                <div className='Tournament__days'>
                  {daysToggle.map((day, key) => {
                    return (
                      <Toggle
                        key={key}
                        label={day.name}
                        name={`day${key}`}
                        labelSize={5}
                        onChange={this.onChangeModalToggle}
                        checked={day.status}
                      />
                    )
                  })}
                </div>
              </Col>
              <Input
                disabled={loading}
                inputSize={10}
                name='email'
                placeholder={t('Email')}
                type='email'
                label={t('Email')}
                value={Store.email}
                onChange={this.onChange}
                required
              />
              <Input
                disabled={loading}
                inputSize={10}
                name='inChargePerson'
                placeholder={t('Nombre persona encargada de la tienda.')}
                type='input'
                label={t('Encargado')}
                value={Store.inChargePerson}
                onChange={this.onChange}
                required
              />
              <Input
                disabled={loading}
                inputSize={10}
                name='phone'
                placeholder={t('Teléfono')}
                type='input'
                label={t('Teléfono')}
                value={Store.phone}
                onChange={this.onChange}
                required
              />
              <Input
                disabled={loading}
                inputSize={10}
                name='siteUrl'
                placeholder={t('Website')}
                type='input'
                label={t('Website')}
                value={Store.siteUrl}
                onChange={this.onChange}
                required
              />
              <Input
                disabled={loading}
                inputSize={10}
                name='facebookUrl'
                placeholder={t('Fanpage')}
                type='input'
                label={t('Fanpage')}
                value={Store.facebookUrl}
                onChange={this.onChange}
              />
              <div>
                <h2>{t('Dirección Principal')}</h2>
                <p>{t('Actualiza la dirección principal de la tienda')}</p>
                <Input
                  disabled
                  inputSize={10}
                  name='addressName'
                  placeholder={t('Nombre de la dirección')}
                  type='text'
                  label={t('Nombre')}
                  value={'Casa Matriz'}
                  onChange={this.onChange}
                  required
                />
                <Input
                  disabled={loading}
                  inputSize={10}
                  name='address'
                  placeholder={t('Dirección')}
                  type='text'
                  label={t('Dirección')}
                  value={address.address}
                  onChange={this.onChange}
                  required
                />
                <Select
                  disabled
                  name='countryId'
                  options={countries}
                  label={t('País')}
                  onChange={this.onChange}
                  inputSize={10}
                  select={address.countryId}
                  required
                />
                <Select
                  disabled={loading}
                  name='regionId'
                  options={regions}
                  label={t('Región')}
                  onChange={this.onChange}
                  inputSize={10}
                  select={address.regionId}
                  required
                />
                {locations.length > 0 && (
                  <Select
                    disabled={loading}
                    name='locationId'
                    options={locations}
                    label={t('Comuna')}
                    onChange={this.onChange}
                    inputSize={10}
                    select={address.locationId}
                    required
                  />
                )}
              </div>
              {isAdmin && (
                <Card body>
                  <h1 className='text-center'>
                    <i className='fa fa-cogs' /> Gestión Administrador
                  </h1>
                  <p className='text-center'>
                    Los siguientes ajustes solo los puede ver un perfil
                    administrador
                  </p>
                  <Col>
                    <Select
                      disabled={loading}
                      name='active'
                      options={activeStatus}
                      label={t('Estado Tienda')}
                      onChange={this.onChange}
                      labelSize={3}
                      select={Store.active}
                      currentValue={Store.active}
                      required
                    />
                    <Input
                      disabled={loading}
                      labelSize={3}
                      inputSize={8}
                      name='client'
                      placeholder={'Código interno cliente'}
                      type='input'
                      label={t('Cliente')}
                      value={Store.client}
                      onChange={this.onChange}
                    />
                    <Select
                      disabled={loading}
                      name='clasificationId'
                      options={this.state.clasifications}
                      labelSize={3}
                      label={t('Clasificación')}
                      onChange={this.onChange}
                      select={Store.clasificationId}
                      currentValue={Store.clasificationId}
                      required
                    />
                    <Select
                      disabled={loading}
                      name='levelId'
                      options={this.state.levels}
                      label={t('Nivel de Tienda')}
                      labelSize={3}
                      onChange={this.onChange}
                      select={Store.levelId}
                      currentValue={Store.levelId}
                      required
                    />
                  </Col>
                  <hr />
                  <Col>
                    <h2>Juegos asociados a la tienda</h2>
                    <p>
                      Asigna los juegos que tendrá disponibles la tienda para
                      realizar torneos
                    </p>
                    <p>
                      Por cada juego que asocies, la tienda podrá hacer X
                      torneos mensuales según como los configures en la sección
                      <b>Tipo de Torneos disponibles</b>
                    </p>
                    <div className='Tournament__games'>
                      {this.state.games.map(game => {
                        return (
                          <Toggle
                            key={game.id}
                            name={`game${game.id}`}
                            label={game.name}
                            onChange={this.onChangeModalToggle}
                            checked={game.status}
                            inputSize={6}
                            labelSize={6}
                          />
                        )
                      })}
                    </div>
                  </Col>
                  <hr />
                  <Col>
                    <h2>Tipos de Torneos disponibles</h2>
                    <p>
                      Si es necesario la tienda puede obtener torneos
                      adicionales para organizar cada mes. Agrega la cantidad
                      que sea necesaria en esta sección.
                    </p>
                    <p>
                      Recuerda que el valor agregado al tipo de Torneo{' '}
                      <b>se sumará a los valores por defecto</b> configurados en{' '}
                      <Link to='/tournament-types'>
                        la sección de administración
                      </Link>
                    </p>

                    <div className='Tournament__TournamentTypes'>
                      {this.state.TournamentTypes.map((t, key) => {
                        return (
                          <Col key={key}>
                            <Input
                              id={`extra${key}`}
                              disabled={loading}
                              inputSize={7}
                              labelSize={5}
                              name={'extraTournament'}
                              type='number'
                              min={0}
                              max={12}
                              label={t.name}
                              value={t.quantity}
                              onChange={e => this.onChangeTT(e, t)}
                              placeholder={`Defecto: [${t.default}]`}
                            />
                          </Col>
                        )
                      })}
                    </div>
                  </Col>
                </Card>
              )}
            </form>

            <div className='BaseRight__Bottom__actions buttons'>
              <Button
                disabled={loading}
                type='submit'
                text={t('Actualizar datos')}
                state='primary'
                form='StoreUpdateForm'
                className='btn-register'
              />
            </div>
          </Jumbotron>
        </Container>
      </Wrapper>
    )
  }
}

// @Proptypes
StoreUpdateView.propTypes = {
  loggedUser: PropTypes.object
}

/*
  @Store Connection: connect
  @Export Component
*/

const mapStateToProps = state => ({
  loggedUser: state.loggedUser,
  countries: state.countries
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      StoreUpdate,
      UploadImage,
      getCountries,
      getGames,
      getLocations,
      getRegions,
      getStoreClasifications,
      getStoreInfo,
      getTournamentTypeList,
      getStoreLevels,
      getUser
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(StoreUpdateView))

// @Styles
const Wrapper = styled.div.attrs({ className: 'StoreEdit' })`
  height: inherit;
  background: url(${bgBackground('fondo1')}) 50% 0;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding: 20px 0px;
  .isAdmin {
    background-color: ${UIVars.setColors.warning};
    padding: 5px;
    margin-bottom: 15px;
    .form-group {
      margin-bottom: 0px;
    }
  }
  .Tournament__days {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .Tournament__TournamentTypes,
  .Tournament__games {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`
