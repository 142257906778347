// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { getToken, axiosError } from '../../../Services'

const requestId = 'getTournamentPlayers'
const getTournamentPlayers = tournamentId => {
  return dispatch => {
    getToken()
    const query = `
      query TournamentPlayers($TournamentPlayersInput: TournamentPlayersInput!) {
        TournamentPlayers(input: $TournamentPlayersInput) {
          status {
            success
            name
            detail
          }
          Players {
            id
            byes
            drop
            points
            TournamentRoundDrop {
              name
              urlView
            }
            raceId
            Person {
              id
              avatar
              name
              email
              fullName
              emailValid
              urlAvatar
              code
              active
            }
            Classified {
              name
            }
          }
        }
      }
    `
    const variables = {
      TournamentPlayersInput: {
        tournamentId
      }
    }

    return (
      axios
        // @ts-ignore
        .post(`${apiURL}/graphql`, { query, variables }, { requestId })
        .then(response => {
          if (response.status === 200) {
            return response.data.data.TournamentPlayers
          } else {
            return []
          }
        })
        .catch(err => axiosError(err, requestId, dispatch))
    )
  }
}
export default getTournamentPlayers
