// @External Dependencies
import React from 'react'
import styled from 'styled-components'
import { Col } from 'reactstrap'

import { Alert, Toggle, Input, Textarea } from '../../Components/UI'
import { useTranslation } from 'react-i18next'

const FormFormat = ({
  alertContent,
  alertState,
  loading,
  Format,
  Games,
  onSubmit,
  onChange,
  handleDismissAlert,
  onChangeModalToggle
}) => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <form onSubmit={e => onSubmit(e)} acceptCharset='utf-8' id='FormCreate'>
        {alertContent.length > 0 && (
          <Alert color={alertState} close={e => handleDismissAlert(e)}>
            {alertContent}
          </Alert>
        )}
        <Input
          disabled={loading}
          inputSize={10}
          name='name'
          placeholder={t('Nombre')}
          onChange={e => onChange(e)}
          value={Format.name}
          type='input'
          label={t('Nombre')}
          required
        />
        <Textarea
          disabled={loading}
          inputSize={10}
          name='description'
          placeholder={t('Descripción')}
          label={t('Descripción')}
          onChange={e => onChange(e)}
          value={Format.description}
          required
        />
        <Toggle
          label={'Estado'}
          name={`status`}
          onChange={e => onChangeModalToggle(e)}
          checked={Format.status}
        />

        <Col>
          <hr />
          <h2>Juegos asociados al formato</h2>
          <p> Asigna los juegos en los que estará disponible el formato. </p>
          <div className='Games'>
            {Games.map(game => {
              return (
                <Toggle
                  key={game.id}
                  name={`game${game.id}`}
                  label={game.name}
                  onChange={e => onChangeModalToggle(e)}
                  checked={game.status}
                  inputSize={6}
                  labelSize={6}
                />
              )
            })}
          </div>
        </Col>
      </form>
    </Wrapper>
  )
}

// @Proptypes
FormFormat.propTypes = {
  // types: PropTypes.array.isRequired
}

export default FormFormat

// @Styles
const Wrapper = styled.section`
  .Games {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`
