const Image1 = require('./dingonek-min.png')
const Image2 = require('./fatim-beye-min.png')
const Image3 = require('./libela-min.png')
const Image4 = require('./mbokomu-min.png')
const Image5 = require('./mngwa-min.png')
const Image6 = require('./moremi-asojoro-min.png')
const Image7 = require('./nganga-min.png')
const Image8 = require('./obatala-min.png')
const Image9 = require('./shaka-zulu-min.png')
const Image10 = require('./shango-min.png')

module.exports = {
  Image1,
  Image2,
  Image3,
  Image4,
  Image5,
  Image6,
  Image7,
  Image8,
  Image9,
  Image10
}
