import React from 'react'

import { Row, Col, Jumbotron } from 'reactstrap'
import { Link } from 'react-router-dom'
import { Select, Button, Input } from '../../Components/UI'

export const FiltrosTorneo = ({
  enableButton,
  filters,
  states,
  handleSubmit,
  handleResetFilters,
  handleOnChange
}) => {
  return (
    <Jumbotron>
      <Row>
        <Col md={8}>
          <h1>Torneos</h1>
          <p>Puedes aplicar filtros para acotar tu búsqueda de Torneos.</p>
        </Col>
        {enableButton && (
          <Col md={4} xs={12} className='text-center'>
            <Link
              to='/tournament/register'
              className='btn btn-success'
              style={{
                margin: 'auto',
                marginBottom: '20px',
                display: 'block'
              }}>
              Crear nuevo torneo
            </Link>
          </Col>
        )}
      </Row>
      <div className='Tournaments__filter row'>
        <Col md={4} className='text-center'>
          <form onSubmit={handleSubmit} className=''>
            <Input
              inputSize={12}
              name='search'
              placeholder='Buscar por nombre del torneo'
              value={filters.search}
              onChange={handleOnChange}
            />
          </form>
        </Col>
        <Col md={4} className='text-center'>
          <Select
            inputSize={12}
            name='gameId'
            options={states.Games}
            placeholder={'Selecciona un Juego'}
            onChange={handleOnChange}
            select={filters.gameId}
          />
        </Col>
        <Col md={4} className='text-center'>
          <Select
            inputSize={12}
            name='seasonId'
            options={states.Seasons.filter(
              s => s.gameId === filters.gameId
            ).map(s => {
              return {
                id: s.id,
                name: `${s.isActive ? '✅ ' : '⚠️ '} ${s.name}`
              }
            })}
            disabled={filters.gameId === 0}
            placeholder={'Temporadas de juegos'}
            onChange={handleOnChange}
            select={filters.seasonId}
          />
        </Col>
        {/* <Col md={4} className="text-center">
          <Select
            inputSize={12}
            name="isActiveSeason"
            options={states.SeasonsTypes}
            placeholder={'Selecciona temporadas'}
            onChange={handleOnChange}
            select={filters.isActiveSeason}
          />
        </Col> */}
        <Col md={4} className='text-center'>
          <Select
            inputSize={12}
            name='countryId'
            options={states.Countries}
            placeholder={'Todos los Paises'}
            onChange={handleOnChange}
            select={filters.countryId}
          />
        </Col>
        <Col md={4} className='text-center'>
          <Select
            inputSize={12}
            name='regionId'
            options={states.Regions.filter(
              r => r.countryId === filters.countryId
            )}
            disabled={filters.countryId === 0}
            placeholder={'Todos las regiones'}
            onChange={handleOnChange}
            select={filters.regionId}
          />
        </Col>
        <Col md={4} className='text-center'>
          <Select
            inputSize={12}
            name='storeId'
            options={states.Stores.map(store => {
              return {
                id: store.id,
                name: `[${store.Level.name}] - ${store.name}`
              }
            })}
            placeholder={'Todas las tiendas'}
            onChange={handleOnChange}
            select={filters.storeId}
          />
        </Col>
        <Col md={3} className='text-center'>
          <Select
            inputSize={12}
            name='typeId'
            options={states.Types}
            placeholder='Todos los Tipos'
            onChange={handleOnChange}
            select={filters.typeId}
          />
        </Col>
        <Col md={3} className='text-center'>
          <Select
            inputSize={12}
            name='statusId'
            options={states.States}
            placeholder='Todos los estados de Torneos'
            onChange={handleOnChange}
            select={filters.statusId}
          />
        </Col>
        <Col md={3} className='text-center'>
          <Select
            inputSize={12}
            name='isPresential'
            options={states.Presentials}
            placeholder=''
            onChange={handleOnChange}
            select={filters.isPresential}
          />
        </Col>
        <Col md={3} className='text-center'>
          <Select
            inputSize={12}
            name='startDate'
            options={states.Years}
            placeholder={'Selecciona un año'}
            onChange={handleOnChange}
            select={filters.startDate}
          />
        </Col>
        <Col md={6} className='text-center'>
          <Button
            text='Limpiar filtros'
            onClick={handleResetFilters}
            state='default'
          />
        </Col>
        <Col md={6} className='text-center'>
          <Button
            text='Buscar Torneos'
            onClick={handleSubmit}
            state='success'
          />
        </Col>
      </div>
    </Jumbotron>
  )
}
