// @External Dependencies
import { combineReducers } from 'redux'

// @Dependencies
import { loggedUser } from './Users/login'
import { ui } from './UI'
import { countries } from './countries'

// @Export Reducers
export default combineReducers({
  loggedUser,
  ui,
  countries
})
