// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { getToken, axiosError } from '../../../Services'

const Edit = data => {
  return async dispatch => {
    getToken()
    const query = `
      query UserTournament($UserTournamentInput: UserTournamentInput!) {
        UserTournament(input: $UserTournamentInput) {
          status {
            success
            name
          }
          SeasonRank {
            points
            totalTournaments
            victories
            draws
            defeats
            Season {
              name
              startDate
              endDate
              rankingUrl
              Game {
                name
              }
            }
          }
          Person {
            id
            avatar
            fullName
            urlAvatar
            created
            urlEdit
            code
          }
          Tournaments {
            id
            name
            startDate
            urlView
            isPresential
            Store {
              name
            }
            TournamentStatus {
              name
            }
            TournamentType {
              name
              urlImage
            }
            UserPosition {
              wins
              losses
              draws
              rounds
              playerId
              position
              points
              TournamentRound {
                urlStanding
                Tournament {
                  isPresential
                  maxPlayers
                }
              }
            }
          }
        }
      }
    `
    const variables = {
      UserTournamentInput: data
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then(response => {
        if (response.data.errors) {
          return response.data.errors[0].message
        } else {
          const { UserTournament } = response.data.data
          return UserTournament
        }
      })
      .catch(err => axiosError(err))
  }
}

export default Edit
