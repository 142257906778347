// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { getToken, axiosError } from '../../../Services'

const find = (search, excludes = [], tournamentId = 0, organizer = false) => {
  return async dispatch => {
    getToken()
    const query = `
      query UserSearch($UserSearchInput: UserSearchInput!) {
        UserSearch(input: $UserSearchInput) {
          status {
            success
            name
            detail
          }
          Persons {
            id
            code
            email
            name
            fullName
          }
        }
      }
    `
    const variables = {
      UserSearchInput: {
        search: search.trim(),
        excludes,
        tournamentId,
        organizer
      }
    }
    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then(response => {
        const { UserSearch } = response.data.data
        return UserSearch
      })
      .catch(err => axiosError(err))
  }
}

export default find
