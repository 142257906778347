// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { getToken, axiosError } from '../../../Services'

const getTournamenTypesList = () => {
  const requestId = 'getTournamenTypesList'
  return dispatch => {
    getToken()

    const query = `
      query {
        allTournamentTypes(paginate: 99, orderBy: sortOrder_ASC) {
          id
          name
          description
          private
          status
          minPlayers
          urlImage
          maxTopPlayers
          multiplier
          winnerPoints
          loserPoints
          drawPoints
          socialRequired
          urlUpdate
          storeTournaments
          canBeDouble
          totalTournaments
        }
      }
    `

    return axios
      .post(`${apiURL}/graphql`, { query }, { requestId })
      .then(response => {
        if (response.status === 200 && response.data.data.allTournamentTypes) {
          return response.data.data.allTournamentTypes
        } else {
          return []
        }
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}

export default getTournamenTypesList
