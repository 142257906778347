// @External Dependencies
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Header from '../Header'
/**
 * Componente base para las rutas privadas.
 *
 * @example
 * <AppFrame>
 *    <Component {...props} />
 * </AppFrame>
 */
const AppFrame = ({ children, ...extra }) => {
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation()
  const {
    loggedUser,
    ui: { isMobile }
  } = useSelector(state => state)

  useEffect(() => {
    setLoading(false)
  }, [isMobile])

  if (loading) return null
  return (
    <section id='MainContainer'>
      <Header {...extra} isMobile={isMobile} />
      <div id='MainContainer'>
        {React.cloneElement(children, {
          isMobile,
          loggedUser,
          t
        })}
      </div>
    </section>
  )
}

export default AppFrame
