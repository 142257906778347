// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { getToken, axiosError } from '../../../Services'

const register = storeId => {
  return async dispatch => {
    getToken()
    const query = `
      mutation StoreDelete($StoreDeleteInput: StoreDeleteInput!) {
        StoreDelete(input: $StoreDeleteInput) {
          status  {
            success
            name
            detail
          }
        }
      }
    `
    const variables = {
      StoreDeleteInput: {
        storeId
      }
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then(response => {
        if (response.data.errors) {
          return response.data.errors[0].message
        } else {
          const { StoreDelete } = response.data.data
          if (response.status === 200 && StoreDelete.status.success) {
            return StoreDelete
          } else {
            return StoreDelete
          }
        }
      })
      .catch(err => axiosError(err))
  }
}

export default register
