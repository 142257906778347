// @External Dependencies
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// @Dependencies
import BaseForm from '../Forms/BaseForm'

// @Component
class Input extends Component {
  state = {
    currentValue: this.props.value || '',
    deleteState: this.props.value && this.props.value.length > 0,
    lastValue: this.props.value
  }

  static getDerivedStateFromProps (props, state) {
    if (props.value !== state.lastValue) {
      return {
        currentValue: props.value,
        deleteState: props.value && props.value.length > 0,
        lastValue: props.value
      }
    }
    return null
  }

  handleChange = e => {
    const { onChange } = this.props
    this.setState({
      currentValue: e.target.value,
      deleteState: e.target.value.length > 0
    })
    onChange !== undefined && onChange(e)
  }

  handleBlur = e => this.props.onBlur !== undefined && this.props.onBlur(e)

  cleanInput = () => {
    const { onChange, onBlur } = this.props
    this.setState({
      currentValue: '',
      deleteState: false
    })
    const e = {
      target: {
        value: '',
        name: this.props.name
      }
    }
    onChange !== undefined && onChange(e)
    onBlur !== undefined && onBlur(e)
  }

  onKeyPress = e => this.props.onKeyPress && this.props.onKeyPress(e)

  render () {
    const {
      id,
      autocomplete,
      disabled,
      min,
      max,
      maxlength,
      myRef,
      name,
      noDelete,
      onKeyUp,
      placeholder,
      required,
      size,
      type
    } = this.props
    let { deleteState, currentValue } = this.state

    let _size = size !== undefined ? size : 'md'

    return (
      <div className='InputUI'>
        <BaseForm {...this.props}>
          <input
            autoComplete={autocomplete}
            className={`form-control input-${_size}`}
            disabled={disabled}
            id={id || name}
            max={max}
            min={min}
            maxLength={maxlength}
            name={name}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            onKeyPress={this.onKeyPress}
            onKeyUp={onKeyUp}
            placeholder={placeholder}
            ref={myRef}
            required={required}
            type={type}
            value={currentValue}
          />
          {(deleteState && !noDelete && !disabled && type !== 'number') > 0 && (
            <i
              className='glyphicon glyphicon-remove btn-clean'
              onClick={this.cleanInput}
            />
          )}
        </BaseForm>
      </div>
    )
  }
}

// @Proptypes
Input.propTypes = {
  autocomplete: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  info: PropTypes.node,
  inputSize: PropTypes.number,
  label: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  maxlength: PropTypes.string,
  myRef: PropTypes.object,
  name: PropTypes.string.isRequired,
  noDelete: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  onKeyUp: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  size: PropTypes.string,
  submitEnter: PropTypes.bool,
  tooltip: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.node
}

Input.defaultProps = {
  autocomplete: 'on',
  submitEnter: false,
  type: 'text'
}

// @Export Component
export default Input
