// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { getToken, axiosError } from '../../../Services'

const register = data => {
  return dispatch => {
    getToken()
    const query = `
      mutation TournamentRegister($TournamentRegisterInput: TournamentRegisterInput!) {
        TournamentRegister(input: $TournamentRegisterInput) {
          status {
            success
            name
            detail
          }
          tournament {
            id
            name
            urlView
          }
        }
      }
    `
    const variables = {
      TournamentRegisterInput: data
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then(response => {
        if (response.data.errors) {
          return response.data.errors[0].message
        } else {
          const { TournamentRegister } = response.data.data
          return TournamentRegister
        }
      })
      .catch(err => axiosError(err))
  }
}

export default register
