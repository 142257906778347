// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { getToken, axiosError } from '../../../Services'

export default (seasonId, filters, gameId = 0) => {
  return dispatch => {
    getToken()
    const query = `
      query TournamentListV2($TournamentListInputV2: TournamentListInputV2!) {
        TournamentListV2(input: $TournamentListInputV2) {
          status {
            success
            name
            detail
          }
          total
          pages
          Tournaments {
            id
            name
            urlView
            startDate
            maxPlayers
            rounds
            isPresential
            Store {
              urlView
              name
            }
            Format {
              name
            }
            TournamentType {
              name
            }
            TournamentStatus {
              name
              description
              style
              icon
            }
            Game {
              name
              color
              icon
            }
            Address {
              countryName
              countryIso
              locationName
            }
          }
        }
      }
    `
    const variables = {
      TournamentListInputV2: {
        ...filters
      }
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables }, {})
      .then(response => {
        if (response.status === 200) {
          return response.data.data.TournamentListV2
        } else {
          return []
        }
      })
      .catch(err => axiosError(err, 'TournamentListV2', dispatch))
  }
}
