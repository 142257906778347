// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { getToken, axiosError } from '../../../Services'

export default data => {
  const requestId = 'SeasonUpdate'
  return async dispatch => {
    getToken()
    const query = `
      mutation SeasonUpdate($SeasonUpdateInput: SeasonUpdateInput!) {
        SeasonUpdate(input: $SeasonUpdateInput) {
          status {
            success
            name
            detail
          }
          Season {
            id
            name
            description
            isActive
            gameId
            rankingUrl
          }
        }
      }

    `
    const variables = {
      SeasonUpdateInput: data
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables }, { requestId })
      .then(response => {
        return response.data.data.SeasonUpdate
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}
