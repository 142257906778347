import { useCallback, useState, useEffect } from 'react'
import {
  queryTournamentList,
  queryTournamentFilters
} from '../../Redux/Actions'
import { initFilters, initStates } from './constants'

/**
 * Hook Tournaments.
 * @returns
 */
export const useTournaments = () => {
  const [message] = useState('Buscando torneos')
  const [loading, setLoading] = useState(true)
  const [canFind, setCanFind] = useState(true)
  const [filters, setFilters] = useState(initFilters)
  const [states, setStates] = useState(initStates)
  const [tournaments, setTournaments] = useState({
    Tournaments: [],
    pages: 0,
    total: 0
  })

  const onInit = useCallback(async () => {
    const resolverFilters = await queryTournamentFilters()()

    setStates({
      ...states,
      ...resolverFilters
    })
  }, [])

  const search = useCallback(async () => {
    setLoading(true)
    const resolve = await queryTournamentList(0, filters)()
    if (resolve.status.success) {
      setTournaments({
        ...tournaments,
        ...resolve
      })
      setLoading(false)
    } else {
      setLoading(false)
    }
  }, [filters])

  const handleSubmit = async () => {
    setCanFind(true)
    return search()
  }
  const handleResetFilters = async () => {
    setFilters(initFilters)
  }

  const handlePagination = async event => {
    if (filters.page !== event) {
      setFilters({
        ...filters,
        page: event
      })
    }
  }

  const handleOnChange = async e => {
    const target = e.target
    const localFilter = {
      ...filters
    }
    localFilter.page = 1
    setCanFind(true)
    if (target.id === 'search') {
      localFilter.search = target.value
      setCanFind(false)
    }
    if (target.id === 'countryId') {
      localFilter.countryId = Number(target.value)
    }
    if (target.id === 'regionId') {
      localFilter.regionId = Number(target.value)
    }
    if (target.id === 'gameId') {
      localFilter.gameId = Number(target.value)
    }
    if (target.id === 'seasonId') {
      localFilter.seasonId = Number(target.value)
    }
    if (target.id === 'isActiveSeason') {
      localFilter.isActiveSeason = Number(target.value)
    }
    if (target.id === 'formatId') {
      localFilter.formatId = Number(target.value)
    }
    if (target.id === 'typeId') {
      localFilter.typeId = Number(target.value)
    }
    if (target.id === 'limit') {
      localFilter.limit = Number(target.value)
    }
    if (target.id === 'statusId') {
      localFilter.statusId = [Number(target.value)]
    }
    if (target.id === 'storeId') {
      localFilter.storeId = Number(target.value)
    }
    if (target.id === 'isPresential') {
      localFilter.isPresential = Number(target.value)
    }
    if (target.id === 'startDate') {
      localFilter.startDate = Number(target.value)
    }
    if (target.id === 'sortDate') {
      localFilter.date = localFilter.date === 'ASC' ? 'DESC' : 'ASC'
    }
    if (target.id === 'sortPlayers') {
      localFilter.players = localFilter.players === 'ASC' ? 'DESC' : 'ASC'
    }
    setFilters(localFilter)
  }

  /** Al iniciar el hook busca los datos iniciales. */
  useEffect(() => {
    onInit()
  }, [])

  /** Dispara el cambio cuando se cambian los filtros. */
  useEffect(() => {
    if (canFind) {
      search()
    }
  }, [filters, canFind])

  return {
    states,
    filters,
    tournaments,
    loading,
    message,
    handleSubmit,
    handleResetFilters,
    handlePagination,
    handleOnChange
  }
}
