import styled from 'styled-components'
import { bgBackground } from '../../../assets/img/login'

// @Styles
const Wrapper = styled.div.attrs({ className: 'Tournaments' })`
  background: url(${bgBackground('fondo1')}) 50% 0;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding: 80px 0px;

  .container {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
  }

  .Tournaments__sort {
    text-align: right;
    span {
      margin-left: 10px;
    }
  }
  .row-tournament {
    cursor: pointer;
    .center {
      text-align: center;
    }
    .italic {
      font-style: italic;
    }
    .store {
      p {
        margin: 0px;
        text-transform: uppercase;
        font-weight: 500;
      }
      span {
        font-size: 0.7rem;
        color: #5a6268;
        padding: 0.275rem 0.55rem;
        background-color: #e0e0e0;
        border-color: #e0e0e0;
        &:hover {
          color: #fff;
          background-color: #5a6268;
          border-color: #545b62;
        }
      }
    }
    .state,
    .game {
      color: white;
      padding: 3px;
      border-radius: 5px;
      font-weight: bold;
      span {
        float: right;
        font-weight: normal;
        padding: 0px 5px;
      }
    }
  }
`

export default Wrapper
