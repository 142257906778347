// @External Dependencies
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { bgBackground } from '../../../../assets/img/login'

// @Style Dependencies
import { Row, Col, Container, Jumbotron } from 'reactstrap'

// @Dependencies
import {
  getUsersList,
  getSeasons,
  getUser,
  getCountries,
  getRegions
} from '../../../Redux/Actions'
import { axiosAbort } from '../../../Services'
import {
  LoadingSVG,
  Select,
  PaginationList,
  Button,
  Input
} from '../../../Components/UI'

import UserList from './UserList'

// @Component
class UsersListView extends Component {
  state = {
    loading: true,
    loadingMessage: 'Estamos buscando los jugadores...',
    showFilters: !this.props.isMobile,
    data: {
      total: 0,
      pages: 0,
      Users: [],
      Countries: [],
      Regions: []
    },
    validStates: [
      { id: 1, name: 'Email Validado' },
      { id: 2, name: 'Email aún no validado.' },
      { id: 3, name: 'Todos' }
    ],
    categories: [
      { id: 1, name: 'Casual' },
      { id: 2, name: 'Social' },
      { id: 3, name: 'Competitivo' },
      { id: 4, name: 'Aprendiz' }
    ],
    actives: [
      { id: 1, name: '✅ Jugadores activos' },
      { id: 2, name: '⛔️ Jugadores sancionados' }
    ],
    filters: {
      search: '',
      isValid: 3,
      countryId: 0,
      regionId: 0,
      categoryId: 0,
      active: 1,
      page: 1,
      limit: 20
    }
  }

  async componentDidMount () {
    await this.props.getUser()

    const { isAdmin } = this.props.loggedUser
    if (!isAdmin) {
      this.props.history.push('/')
    }

    this.setState({
      baseFilters: this.state.filters
    })

    await this.search()
  }

  componentWillUnmount () {
    axiosAbort()
  }

  /**
   * Trigger de búsqueda en componente
   */
  async search () {
    this.setState({
      loading: true
    })
    const { getUsersList } = this.props
    const users = await getUsersList(this.state.filters)

    if (users.status.success) {
      this.setState({
        data: {
          ...users,
          Regions: users.Regions.map(r => {
            return {
              id: r.id,
              name: r.fullName
            }
          })
        }
      })
    }
    this.setState({ loading: false })
  }

  /**
   * Paginación
   */
  handlePagination = async event => {
    const { filters } = this.state
    if (filters.page !== event) {
      filters.page = event
      this.setState({ filters })
      await this.search()
    }
  }

  /**
   * Reset filters
   */
  resetFilters = async () => {
    this.setState({
      filters: this.state.baseFilters
    })
    await this.search()
  }
  /**
   * On change filtros etc
   */
  onChange = async e => {
    const target = e.target
    let { filters, seasonId } = this.state
    let search = true
    if (target.id === 'search') {
      filters.search = target.value
      search = false
    }
    if (target.id === 'countryId') {
      filters.countryId = Number(target.value)
    }
    if (target.id === 'regionId') {
      filters.regionId = Number(target.value)
    }
    if (target.id === 'limit') {
      filters.limit = Number(target.value)
    }
    if (target.id === 'categoryId') {
      filters.categoryId = Number(target.value)
    }
    if (target.id === 'isValid') {
      filters.isValid = Number(target.value)
    }
    if (target.id === 'active') {
      filters.active = Number(target.value)
    }

    filters.page = 1
    this.setState({ filters, seasonId })
    if (search) {
      await this.search()
    }
  }

  /**
   * Evento para buscar texto
   */
  handleSubmit = async e => {
    e.preventDefault()
    const { search } = this.state.filters
    if (search.length > 1) {
      search && (await this.search())
    } else {
      if (!search.length) {
        this.resetFilters()
      }
      this.setState({
        loading: true,
        loadingMessage: 'Mínimo debes ingresar 1 caracteres para la búsqueda'
      })
      setTimeout(() => {
        this.setState({
          loading: false
        })
      }, 2000)
    }
  }

  render () {
    const { loading, loadingMessage, filters, data } = this.state
    const { isMobile } = this.props
    if (loading) return <LoadingSVG message={loadingMessage} />
    return (
      <Wrapper>
        <Container>
          <article>
            <Jumbotron>
              <Row>
                <Col md={12}>
                  <h1>Jugadores</h1>
                  <p>
                    Puedes aplicar filtros para acotar la búsqueda de jugadores
                    inscritos.
                  </p>
                </Col>
              </Row>
              <div className='Tournaments__filter row'>
                <form
                  onSubmit={this.handleSubmit}
                  className='col-md-4 col-sm-6 col-xs-6'>
                  <Input
                    inputSize={12}
                    name='search'
                    placeholder='Buscar por nombre, email o código.'
                    value={filters.search}
                    onChange={this.onChange}
                  />
                </form>
                {this.state.showFilters && (
                  <React.Fragment>
                    <Col md={4} className='text-center'>
                      <Select
                        inputSize={12}
                        name='countryId'
                        options={data.Countries}
                        placeholder={'Todos los Paises'}
                        onChange={this.onChange}
                        select={filters.countryId}
                      />
                    </Col>
                    <Col md={4} className='text-center'>
                      <Select
                        inputSize={12}
                        name='regionId'
                        options={data.Regions}
                        placeholder={'Todos las regiones'}
                        onChange={this.onChange}
                        select={filters.regionId}
                      />
                    </Col>
                    <Col md={4} className='text-center'>
                      <Select
                        inputSize={12}
                        name='isValid'
                        options={this.state.validStates}
                        placeholder={'Cuentas validadas'}
                        onChange={this.onChange}
                        select={filters.isValid}
                      />
                    </Col>
                    <Col md={4} className='text-center'>
                      <Select
                        inputSize={12}
                        name='categoryId'
                        options={this.state.categories}
                        placeholder={'Categoría Jugadores'}
                        onChange={this.onChange}
                        select={filters.categoryId}
                      />
                    </Col>
                    <Col md={4} className='text-center'>
                      <Select
                        inputSize={12}
                        name='active'
                        options={this.state.actives}
                        placeholder={'Estado jugadores'}
                        onChange={this.onChange}
                        select={filters.active}
                      />
                    </Col>
                    <Col md={12} className='text-center'>
                      <div className='buttons flex'>
                        <a href='/users' className='btn btn-link'>
                          Limpiar filtros
                        </a>
                      </div>
                    </Col>
                  </React.Fragment>
                )}
                {isMobile && (
                  <Button
                    text={
                      !this.state.showFilters
                        ? 'Mostrar Filtros'
                        : 'Ocultar Filtros'
                    }
                    state='info'
                    style={{
                      margin: 'auto',
                      marginTop: this.state.showFilters ? '20px' : ''
                    }}
                    onClick={() => {
                      this.setState({ showFilters: !this.state.showFilters })
                    }}
                  />
                )}
              </div>
            </Jumbotron>
            {data.Users.length > 0 ? (
              <Fragment>
                <UserList
                  users={data.Users}
                  isMobile={isMobile}
                  total={data.total}
                  limit={filters.limit}
                  page={filters.page}
                />
                <PaginationList
                  active={filters.page}
                  pages={data.pages}
                  total={data.total}
                  handle={this.handlePagination}
                  centered
                />
              </Fragment>
            ) : (
              <Fragment>
                <Jumbotron>
                  <h2 className='text-center'>
                    {' '}
                    No se encontraron jugadores.{' '}
                  </h2>
                  <a
                    href='/users'
                    style={{ margin: 'auto 30%', display: 'block' }}
                    className='btn btn-primary'>
                    Limpiar filtros
                  </a>
                </Jumbotron>
              </Fragment>
            )}
          </article>
        </Container>
      </Wrapper>
    )
  }
}

// @Proptypes
UsersListView.propTypes = {
  loggedUser: PropTypes.object
}

const mapStateToProps = state => ({
  loggedUser: state.loggedUser
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUsersList,
      getSeasons,
      getCountries,
      getRegions,
      getUser
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(UsersListView))

// @Styles
const Wrapper = styled.div.attrs({ className: 'Users' })`
  background: url(${bgBackground('fondo2')}) 50% 0;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding: 80px 0px;

  .container {
    padding: 20px;
    background-color: white;
  }

  article {
    h1 {
      text-align: left;
    }
  }
  .Tournaments__sort {
    text-align: right;
    span {
      margin-left: 10px;
    }
  }
`
