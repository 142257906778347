// @External Dependencies
import React from 'react'

import {
  CardHeader,
  CardBody,
  CardFooter
} from '../../Components/UI/SimpleCard'

export const TournamentCard = ({ tournament, isMobile }) => {
  if (!tournament) {
    return null
  }
  const image =
    tournament.urlImage ||
    (tournament.TournamentType && tournament.TournamentType.urlImage) ||
    ''

  const tournamentType =
    tournament && tournament.TournamentType
      ? tournament.TournamentType.name
      : '-- Sin Tipo --'
  const tournamentFormat =
    tournament && tournament.Format
      ? tournament.Format.name
      : '-- Sin Formato --'
  const tournamentSeason =
    tournament && tournament.Season
      ? tournament.Season.name
      : '-- Sin Temporada --'
  const tournamentGame =
    tournament && tournament.Game
      ? `<u>${tournament.Game.name}</u>`
      : '-- Sin Juego --'

  return (
    <article className='card'>
      <CardHeader image={image} title={tournament.Store.name} />
      <CardBody
        title={tournament.name}
        date={tournament.startDate}
        href={tournament.urlView}
        info={[
          { name: 'Juego', value: `${tournamentGame} - ${tournamentType}` },
          { name: 'Formato', value: tournamentFormat },
          {
            name: tournament.isPresential ? '' : 'Modalidad',
            value: tournament.isPresential
              ? '<i class="fa fa-users"></i> Presencial'
              : '<i class="fa fa-camera"></i> Remoto'
          },
          { name: '<i class="fa fa-bank"></i>', value: tournamentSeason },
          tournament.quotaText
            ? {
                name: 'Cupos',
                value: tournament.quotaText
              }
            : {}
        ]}
        status={{
          ...tournament.TournamentStatus,
          extra: tournament.validDates > 1 ? '⚠️ &nbsp; doble' : ''
        }}
      />
      <CardFooter
        href={tournament.urlView}
        isMobile={isMobile}
        country={tournament.Address.Country.iso}
      />
    </article>
  )
}

export default TournamentCard
