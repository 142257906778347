// @External Dependencies
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { Row, Col, Container, Jumbotron } from 'reactstrap'
import { Link } from 'react-router-dom'
import { bgBackground } from '../../../assets/img/login'

// @Dependencies
import { getGames, getUser } from '../../Redux/Actions'
import { axiosAbort } from '../../Services'
import { LoadingSVG } from '../../Components/UI'
import GameList from './List'

// @Component
class GameListView extends React.Component {
  state = {
    loading: true,
    loadingMessage: 'Estamos buscando los juegos del sistema',
    buttonBack: '',
    games: []
  }

  async componentDidMount () {
    await this.props.getUser()
    this.search()
  }
  componentWillUnmount () {
    axiosAbort()
  }

  search = async () => {
    this.setState({ loading: true })
    const games = await this.props.getGames()
    this.setState({
      games,
      loading: false
    })
  }

  render () {
    const { loading, games } = this.state
    if (loading)
      return (
        <LoadingSVG
          message={this.state.loadingMessage}
          buttonBack={this.state.buttonBack}
        />
      )
    const { isAdmin } = this.props.loggedUser
    return (
      <Wrapper>
        <Container>
          <Row>
            <Col md={12}>
              <article className='Juegos'>
                <Jumbotron>
                  <Row>
                    <Col md={12}>
                      <h1 className='text-center'>Juegos</h1>
                      <p>
                        Los juegos del sistema son variados y reflejan el
                        Ranking por temporadas de cada Juego de Mitos y Leyendas
                        y Humankind
                      </p>
                      <p>Nomenclatura importante</p>
                      <ul>
                        <li>
                          <b>Temporadas</b>
                          <ul>
                            <li>
                              <span role='img' aria-label='public'>
                                ✅{' '}
                              </span>
                              &nbsp; <b>Activa</b>: La temporada está activa y
                              los torneos creados serán asociados a esta
                              temporada para el ranking
                            </li>
                            <li>
                              <span role='img' aria-label='private'>
                                ⚠️{' '}
                              </span>
                              &nbsp; <b>Inactiva</b>: Representa una temporada
                              pasada.
                            </li>
                          </ul>
                        </li>
                      </ul>
                      {isAdmin && (
                        <div className='isAdmin'>
                          <h4>Herramientas administrador</h4>
                          <p>
                            Crea los tipos de torneos. Estos tienen los
                            multiplicadores que van directo al ranking de cada
                            temporada una vez reportados.
                          </p>
                          <Link
                            to={'/admin/games/create'}
                            className='btn btn-primary'>
                            Crear Juego
                          </Link>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Jumbotron>

                {games.length > 0 ? (
                  <GameList games={games} isAdmin={isAdmin} />
                ) : (
                  <Jumbotron>
                    <h2>No se encontraron juegos en el sistema.</h2>
                  </Jumbotron>
                )}
              </article>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    )
  }
}

// @Proptypes
GameListView.propTypes = {
  loggedUser: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  loggedUser: state.loggedUser
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getGames,
      getUser
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(GameListView))

// @Styles
const Wrapper = styled.div.attrs({ className: 'Games' })`
  background: url(${bgBackground('fondo2')}) 50% 0;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding: 80px 0px;

  .container {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
  }

  .isAdmin {
    padding: 20px;
    background-color: white;
  }

  .Ranking {
    padding: 0px;
    &__table {
      td,
      th {
        padding: 3px;
        cursor: pointer;
        vertical-align: middle;
      }
    }
    &__footer {
      width: 100%;
      text-align: center;
      button {
        margin: 0 auto !important;
      }
    }
  }
  .Tournaments {
    .btn-link {
      display: block;
      text-align: right;
    }
  }
`
