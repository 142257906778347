// @External Dependencies
import React, { Component } from 'react'
import Datetime from 'react-datetime'
import moment from 'moment'
import PropTypes from 'prop-types'

// @Dependencies
import BaseForm from './BaseForm'

// @Component
class Datapicker extends Component {
  state = {
    format: 'YYYY-MM-DD kk:mm',
    placeholder: '',
    value: ''
  }

  static getDerivedStateFromProps (props, state) {
    if (props.value) {
      let exp = new RegExp('/', 'g')
      return {
        value: moment(props.value.replace(exp, '-'), state.format, true)
      }
    }
    return null
  }

  componentDidMount () {
    const { date, birthDate, clock, value: pValue, placeholder } = this.props
    let format = 'YYYY-MM-DD kk:mm'
    let exp = new RegExp('/', 'g')
    if (date || birthDate) format = 'YYYY-MM-DD'
    if (clock) format = 'kk:mm'
    const value = pValue ? moment(pValue.replace(exp, '-'), format, true) : ''
    this.setState({
      placeholder: placeholder || moment(new Date()).format(format),
      format,
      value
    })
  }

  onChange = e => {
    let data = moment(e).format(this.state.format)
    let exp = new RegExp('/', 'g')
    this.setState(
      {
        value: moment(data.replace(exp, '-'), this.state.format, true)
      },
      () => this.props.onChange && this.props.onChange(data, this.props.name)
    )
  }

  onViewModeChange = e =>
    this.props.onViewModeChange && this.props.onViewModeChange(e)

  onNavigateBack = e =>
    this.props.onNavigateBack && this.props.onNavigateBack(e)

  onNavigateForward = e =>
    this.props.onNavigateForward && this.props.onNavigateForward(e)

  valid = current => {
    const yesterday = Datetime.xf.subtract(1, 'day')
    return current.isAfter(yesterday)
  }

  cleanInput = () => {
    this.setState(
      {
        value: ''
      },
      () => {
        this.props.onChange && this.props.onChange('', this.props.name)
      }
    )
  }

  render () {
    const { clock, date, birthDate, disabled, required, name } = this.props
    const { placeholder, value } = this.state

    return (
      <div>
        <BaseForm {...this.props} icon={clock ? 'clock-o' : 'calendar'}>
          <Datetime
            closeOnSelect={true}
            dateFormat={clock ? false : 'YYYY-MM-DD'}
            viewMode={value || !birthDate ? 'days' : 'years'}
            inputProps={{
              id: name,
              disabled,
              name,
              placeholder,
              required,
              readOnly: true
            }}
            onChange={this.onChange}
            onNavigateBack={this.onNavigateBack}
            onNavigateForward={this.onNavigateForward}
            onViewModeChange={this.onViewModeChange}
            timeFormat={(date || birthDate) && false}
            value={value}
          />
          {value._i && value._i.length > 0 && !disabled && (
            <i
              className='glyphicon glyphicon-remove btn-clean'
              onClick={this.cleanInput}
            />
          )}
        </BaseForm>
      </div>
    )
  }
}

// @Proptypes
Datapicker.propTypes = {
  birthDate: PropTypes.bool,
  clock: PropTypes.bool,
  date: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string
}

// @Export Component
export default Datapicker

/**
  Defecto:::
  <Datapicker name='datapicker' label='Datapicker'/>

  Tipos:::
  <Datapicker name='Date' label='Date' date />
  <Datapicker name='Clock' label='Clock' clock />
  <Datapicker name='BirthDate' label='Birth date' birthDate />

  Estados:::
  <Datapicker name='dataPickerData' label='data' date onChange={this.changeField} value={dataPickerData}/>
  <Datapicker name='Disabled' label='Disabled' disabled />
*/
