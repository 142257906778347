// @External Dependencies
import React, { Component } from 'react'
// import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import ReactCountryFlag from 'react-country-flag'
import { Jumbotron } from 'reactstrap'

// @Dependencies
import { StoreRegister, getLocations, getUser } from '../../Redux/Actions'
import {
  Alert,
  Button,
  Input,
  Select,
  Textarea,
  LoadingSVG
} from '../../Components/UI'
import { axiosAbort } from '../../Services'

// @Component
class StoreRegisterView extends Component {
  state = {
    alertContent: '',
    alertState: 'warning',
    loading: false,
    loadingRegister: false,
    address: {
      name: '',
      address: '',
      countryId: 0,
      regionId: 0,
      locationId: 0
    },
    countries: [],
    regions: [],
    locations: []
  }

  /**
   * - Al iniciar busca los paises
   * - Si estoy logueado entonces me manda al home
   */
  async componentDidMount () {
    const { loggedUser, getLocations } = this.props

    if (loggedUser.user.Store) {
      this.props.history.push('/store')
    }

    const locations = await getLocations(loggedUser.user.Address.Region.id)
    this.setState({
      countries: [loggedUser.user.Address.Country],
      regions: [
        {
          name:
            loggedUser.user.Address.Region.alias.length > 0
              ? `${loggedUser.user.Address.Region.name} - ${
                  loggedUser.user.Address.Region.alias
                }`
              : loggedUser.user.Address.Region.name,
          id: loggedUser.user.Address.Region.id
        }
      ],
      locations,
      address: {
        ...this.state.address,
        countryId: loggedUser.user.Address.Country.id,
        regionId: loggedUser.user.Address.Region.id
      }
    })
  }
  componentWillUnmount () {
    axiosAbort()
  }

  handleDismissAlert = () => {
    this.setState({ alertContent: '' })
  }

  handleRegister = async event => {
    event.preventDefault()
    const { StoreRegister, history } = this.props
    const form = new window.FormData(event.target)

    this.setState({
      alertContent: 'Estamos creando la tienda.',
      loading: true,
      loadingRegister: true
    })

    // Armar data para enviar Form
    const data = {
      name: form.get('name').trim(),
      description: form.get('description').trim(),
      email: form.get('email').trim(),
      phone: form.get('phone').trim(),
      siteUrl: form.get('siteUrl').trim(),
      inChargePerson: form.get('inChargePerson').trim(),
      facebookUrl: form.get('facebookUrl').trim(),
      address: {
        ...this.state.address,
        name: form.get('addressName').trim(),
        address: form.get('address').trim()
      }
    }

    const resolver = await StoreRegister(data)
    if (!resolver.status.success) {
      this.setState({
        alertContent: resolver.status.detail,
        loading: false
      })
    } else {
      /**
       * Si registra al usuario entonces lo logueamos en el sistema.
       */
      this.setState({
        alertContent: resolver.status.name,
        alertState: 'success'
      })

      setTimeout(async () => {
        const user = await this.props.getUser()
        if (user === 'SET_USER_OK') {
          if (this.props.loggedUser) {
            history.push('/store')
          }
        }
      }, 3000)
    }
  }

  handleAddress = async e => {
    const { address } = this.state
    const target = e.target

    if (target && target.id === 'regionId') {
      const regionId = parseInt(target.value, 10)
      address.regionId = regionId
      address.locationId = 0
      const locations = await this.props.getLocations(regionId)
      this.setState({
        address,
        locations
      })
    }

    if (target && target.id === 'locationId') {
      const locationId = parseInt(target.value, 10)
      address.locationId = locationId
      this.setState({
        address
      })
    }
  }

  render () {
    const {
      loading,
      alertContent,
      alertState,
      countries,
      regions,
      locations,
      address,
      loadingRegister
    } = this.state
    const { t, loggedUser } = this.props
    if (loadingRegister) return <LoadingSVG message={alertContent} />
    return (
      <Wrapper>
        <div className='BaseContent'>
          <div className='BaseLeft' />

          <div className='BaseRight'>
            <div className='BaseRight__Form'>
              <Jumbotron>
                <h1 className='text-center'>{t('Registro de nueva Tienda')}</h1>
                <p>
                  Cualquier jugador puede registrar una nueva tienda, pero solo
                  podrá hacer uso de la herramienta si está validado por algún
                  administrador. Te recomendamos solo llenar este formulario si
                  es que ya estás en conversaciones con el departamento de Juego
                  Organizado de Mitos y Leyendas.
                </p>
                <p>
                  Recuerda que en cualquier momento un administrador puede
                  aprobar o dar de baja una tienda.
                </p>
                <p className='text-info'>
                  Para registrar tu tienda, ingresa todos los datos en el
                  formulario
                </p>
                <p>Los datos de contacto principal serán:</p>
                <ul>
                  <li>{`Contacto Principal: ${loggedUser.user.fullName} `}</li>
                  <li>{`Email: ${loggedUser.user.email} `}</li>
                  <li>
                    {`Dirección Referencia: ${
                      loggedUser.user.Address.Region.name
                    } ${loggedUser.user.Address.Region.alias} `}
                    <ReactCountryFlag
                      code={loggedUser.user.Address.Country.iso}
                      svg
                    />
                  </li>
                </ul>
                <form
                  onSubmit={this.handleRegister}
                  acceptCharset='utf-8'
                  id='StoreRegisterForm'>
                  {alertContent.length > 0 && (
                    <Alert color={alertState} close={this.handleDismissAlert}>
                      {alertContent}
                    </Alert>
                  )}
                  <Input
                    disabled={loading}
                    inputSize={10}
                    name='name'
                    placeholder={t('Nombre tienda')}
                    type='input'
                    label={t('Nombre')}
                    required
                  />
                  <Textarea
                    disabled={loading}
                    inputSize={10}
                    name='description'
                    placeholder={t('Descripción')}
                    label={t('Descripción')}
                    required
                  />
                  <Input
                    disabled={loading}
                    inputSize={10}
                    name='email'
                    placeholder={t('Email')}
                    type='email'
                    label={t('Email')}
                    required
                  />
                  <Input
                    disabled={loading}
                    inputSize={10}
                    name='inChargePerson'
                    placeholder={t('Nombre persona encargada de la tienda.')}
                    type='input'
                    label={t('Encargado')}
                    required
                  />
                  <Input
                    disabled={loading}
                    inputSize={10}
                    name='phone'
                    placeholder={t('Teléfono contacto Tienda')}
                    type='input'
                    label={t('Teléfono')}
                    required
                  />
                  <Input
                    disabled={loading}
                    inputSize={10}
                    name='siteUrl'
                    placeholder={t('Website')}
                    type='input'
                    label={t('Website')}
                    required
                  />
                  <Input
                    disabled={loading}
                    inputSize={10}
                    name='facebookUrl'
                    placeholder={t('Fanpage')}
                    type='input'
                    label={t('Fanpage')}
                    required
                  />
                  <div>
                    <h2>{t('Dirección')}</h2>
                    <p>{t('Ingresa la dirección de la tienda')}</p>
                    <Input
                      disabled={loading}
                      inputSize={10}
                      name='addressName'
                      placeholder={t('Nombre de la dirección')}
                      type='text'
                      label={t('Nombre')}
                      required
                    />
                    <Input
                      disabled={loading}
                      inputSize={10}
                      name='address'
                      placeholder={t('Dirección')}
                      type='text'
                      label={t('Dirección')}
                      required
                    />
                    <Select
                      disabled
                      name='countryId'
                      options={countries}
                      label={t('País')}
                      onChange={this.handleAddress}
                      inputSize={10}
                      select={address.countryId}
                      required
                    />
                    <Select
                      disabled
                      name='regionId'
                      options={regions}
                      label={t('Región')}
                      onChange={this.handleAddress}
                      inputSize={10}
                      select={address.regionId}
                      required
                    />
                    {locations.length > 0 && (
                      <Select
                        disabled={loading}
                        name='locationId'
                        options={locations}
                        label={t('Comuna')}
                        onChange={this.handleAddress}
                        inputSize={10}
                        select={address.locationId}
                        required
                      />
                    )}
                  </div>
                </form>

                <div className='BaseRight__Bottom__actions'>
                  <Button
                    id='btn_login1'
                    disabled={loading}
                    type='submit'
                    text={t('Registrar Tienda')}
                    state='primary'
                    form='StoreRegisterForm'
                    className='btn-register'
                    style={{ margin: 'auto' }}
                  />
                </div>
              </Jumbotron>
            </div>
          </div>
        </div>
      </Wrapper>
    )
  }
}

// @Proptypes
StoreRegisterView.propTypes = {
  loggedUser: PropTypes.object
}

/*
  @Store Connection: connect
  @Export Component
*/

const mapStateToProps = state => ({
  loggedUser: state.loggedUser,
  countries: state.countries
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      StoreRegister,
      getLocations,
      getUser
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(StoreRegisterView))

// @Styles
const Wrapper = styled.div.attrs({ className: 'Login' })`
  height: inherit;
`
