let urlApi = ''
if (process.env.NODE_ENV === 'development') {
  urlApi = 'http://localhost:4000'
} else {
  urlApi = process.env.REACT_APP_API_URL || 'https://api2.myl.cl'
}
urlApi = 'https://api2.myl.cl'
export const apiURL = urlApi
export const GET_USER = 'GET_USER'
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const SET_MEDIAQUERY = 'SET_MEDIAQUERY'
