import React, { Component } from 'react'
import styled, { injectGlobal } from 'styled-components'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { login } from '../../../assets/img'
import ReactHtmlParser from 'react-html-parser'

class LoadingSVG extends Component {
  componentDidMount () {
    injectGlobal`
      body {
        overflow: hidden;
      }
    `
  }

  componentWillUnmount () {
    injectGlobal`
      body {
        overflow: visible;
      }
    `
  }

  render () {
    const { className, title, message, buttonBack } = this.props
    return (
      <div className={`LoadingSVG ${className}`}>
        <img src={login.logoMyl} alt='Mitos y Leyendas | Humankind' />
        <h5>{!title ? 'Estamos cargando la información' : title}</h5>
        {message && (
          <p style={{ textAlign: 'center' }}>{ReactHtmlParser(message)}</p>
        )}
        {buttonBack && (
          <Link to={buttonBack} className='btn btn-info'>
            <i className='fa fa-chevron-left' /> Volver
          </Link>
        )}
      </div>
    )
  }
}

const TranslatedComponent = withTranslation()(LoadingSVG)

export default styled(TranslatedComponent)`
  align-items: center;
  background: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 15px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;

  h5 {
    font-weight: bold;
    margin: 25px 0 0;
    text-align: center;
  }
  @media (max-width: 768px) {
    img {
      max-width: 80%;
    }
  }
`
