// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { axiosError } from '../../../Services'

export default playerId => {
  const requestId = 'SurveyView'
  return async dispatch => {
    const query = `
      query SurveyView($SurveyViewInput: SurveyViewInput!) {
        SurveyView(input: $SurveyViewInput) {
          status {
            success
            code
            name
            detail
          }
          Survey {
            id
            playerId
            firstQuestion
            secondQuestion
            thirdQuestion
            comment
          }
          Player {
            id
            personId
            Person {
              name
              fullName
              code
            }
            Tournament {
              id
              name
              urlRounds
              urlView
              urlImage
              startDate
              validDates
              isPresential
              Store {
                name
              }
              Game {
                name
              }
              Season {
                name
                rankingUrl
              }
              TournamentStatus {
                id
                name
                description
                style
                icon
              }
            }
          }
        }
      }
    `

    const variables = {
      SurveyViewInput: {
        playerId: Number(playerId)
      }
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables }, { requestId })
      .then(response => {
        const data = response.data.data.SurveyView
        if (data) {
          return data
        } else {
          return {
            status: {
              success: false
            }
          }
        }
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}
