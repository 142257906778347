// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { axiosError, getToken } from '../../../Services'

const requestId = 'getTournamentTypes'
export default (storeId = 0) => {
  return dispatch => {
    getToken()
    const query = `
      query TournamentTypesList($TournamentTypesInput: TournamentTypesInput!) {
        TournamentTypesList(input: $TournamentTypesInput) {
          status {
            name
            success
            detail
          }
          TournamentTypes {
            id
            name
            private
            status
            storeTournaments
            multiplier
            canBeDouble
          }
        }
      }
    `

    const variables = {
      TournamentTypesInput: {
        storeId
      }
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables }, { requestId })
      .then(response => {
        if (response.status === 200) {
          const data = response.data.data
          if (data.TournamentTypesList.status.success) {
            return data.TournamentTypesList.TournamentTypes
          } else {
            return []
          }
        } else {
          return []
        }
      })
      .catch(err => {
        return axiosError(err, requestId, dispatch)
      })
  }
}
