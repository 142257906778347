// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { axiosError, getToken } from '../../../Services'

const confirm = token => {
  getToken()
  return dispatch => {
    const query = `
      mutation UserConfirm($UserConfirmInput: UserConfirmInput!) {
        UserConfirm(input: $UserConfirmInput) {
          status {
            success
            name
            detail
          }
          person {
            id
            email
            avatar
            fullName
            urlAvatar
            code
          }
        }
      }

    `
    const variables = {
      UserConfirmInput: {
        token: token
      }
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then(response => {
        if (response.data.errors) {
          return response.data.errors[0].message
        } else {
          const { UserConfirm } = response.data.data
          return UserConfirm
        }
      })
      .catch(err => axiosError(err))
  }
}

export default confirm
