import React from 'react'

export const StoreAlertPoints = ({ tournament, isMobile }) => {
  let message = ''
  let state = 'info'
  if (tournament.validDates > 1 && tournament.Store.Level.id !== 1) {
    message = (
      <>
        Este torneo está creado como una <u>fecha doble</u>, por lo tanto el
        puntaje del Standing de cada jugador se sumará dos veces al puntaje del
        ranking temporada una vez <u>Reportado</u> el torneo.
      </>
    )
    state = 'warning'
  } else if (!tournament.isPresential) {
    message = (
      <>
        Este torneo fue considerado como <i className='fa fa-camera' /> Remoto{' '}
        <br />
        Por lo tanto cuando se reporte se considerará{' '}
        <u>sin puntaje para el Ranking</u> general de la temporada.
      </>
    )
    state = 'warning'
  } else if (tournament.Store.Level.id === 1) {
    message = (
      <>
        Este torneo está asociado a una tienda con nivel{' '}
        <u>{tournament.Store.Level.name}</u> por lo tanto cuando se reporte se
        considerará <u>sin puntaje para el Ranking</u> general de la temporada.
      </>
    )
    state = 'warning'
  } else {
    message = (
      <>
        <i className='fa fa-check' /> Cuando el torneo sea reportado, agregará
        el puntaje obtenido en el standing de la última ronda al Ranking general
        de la temporada.
      </>
    )
    state = 'default'
  }
  return (
    <tr>
      <th colSpan={isMobile ? 7 : 9} className={`text-center bg-${state}`}>
        {message}
      </th>
    </tr>
  )
}
