// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { getToken, axiosError } from '../../../Services'

const register = id => {
  return async dispatch => {
    getToken()
    const query = `
      query {
        Store(id: ${id}) {
          id
          name
          description
          addressId
          logo
          urlLogo
          cover
          phone
          email
          active
          siteUrl
          facebookUrl
          inChargePerson
          client
          clasificationId
          instagramUrl
          youtubeUrl
          twitterUrl
          levelId
          urlView
          urlTournaments
          urlPastTournaments
          levelId
          Level {
            id
            name
            description
          }
          Person {
            id
            avatar
            fullName
            urlAvatar
            active
            code
            name
            email
            urlEdit
          }
          StoreTournamentDays {
            TournamentDay {
              id
              name
            }
          }
          StoreTournamentTypes {
            id
            storeId
            typeId
            quantity
          }
          StoreGames {
            id
            gameId
            storeId
            Game { name }
          }
          StoreAddresses {
            addressId
            Address {
              id
              name
              address
              countryId
              regionId
              locationId
              Country {
                name
              }
              Region {
                fullName
              }
              Location {
                name
              }
            }
          }
          Address {
            id
            name
            address
            countryId
            regionId
            locationId
            Country {
              name
              iso
            }
            Region {
              fullName
            }
            Location {
              name
            }
          }
        }
      }
    `
    return axios
      .post(`${apiURL}/graphql`, { query })
      .then(response => {
        const data = Object.keys(response.data.data.Store)
        if (response.status === 200 && !response.data.errors && data.length) {
          return {
            Store: response.data.data.Store,
            status: {
              success: true,
              name: 'Respuesta Exitosa'
            }
          }
        } else {
          return {
            status: {
              success: false,
              name: response.data.errors[0].message
            }
          }
        }
      })
      .catch(err => axiosError(err))
  }
}

export default register
