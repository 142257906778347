// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { getToken, axiosError } from '../../../Services'

const register = data => {
  return dispatch => {
    getToken()
    const query = `
      mutation TournamentPlayerUpdate($TournamentPlayerUpdateInput: TournamentPlayerUpdateInput!) {
        TournamentPlayerUpdate(input: $TournamentPlayerUpdateInput) {
          status {
            success
            name
            detail
          }
          rounds
          TournamentPlayer {
            id
            byes
            raceId
            Person {
              id
              avatar
              fullName
              urlAvatar
              code
            }
          }
        }
      }
    `
    const variables = {
      TournamentPlayerUpdateInput: {
        ...data
      }
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then(response => {
        const { TournamentPlayerUpdate } = response.data.data
        return TournamentPlayerUpdate
      })
      .catch(err => axiosError(err))
  }
}

export default register
