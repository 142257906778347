// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { axiosError, getToken } from '../../../Services'

const register = data => {
  return async dispatch => {
    getToken()
    const query = `
      mutation UserRegister($UserRegisterInput: UserRegisterInput!) {
        UserRegister(input: $UserRegisterInput) {
          status  {
            success
            name
            detail
          }
          person {
            id
            fullName
            code
            nickname
            email
            avatar
            urlAvatar
          }
        }
      }
    `
    const variables = {
      UserRegisterInput: {
        ...data.person,
        address: data.address
      }
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then(response => {
        if (response.data.errors) {
          return response.data.errors[0].message
        } else {
          const { UserRegister } = response.data.data
          if (response.status === 200 && UserRegister.status.success) {
            const payload = UserRegister
            return payload
          } else {
            return UserRegister
          }
        }
      })
      .catch(err => axiosError(err))
  }
}

export default register
