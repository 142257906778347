// @External Dependencies
import React from 'react'
import PropTypes from 'prop-types'

// @Component
const Label = ({ id, name, value, required, sm }) => (
  <label
    htmlFor={id || name}
    className={`col-sm-${sm || 2} col-xs-12 label-defect ${
      required ? 'isRequired' : ''
    }`}>
    {value}
  </label>
)

// @Proptypes
Label.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string
}

// @Export Component
export default Label
