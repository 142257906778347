// @External Dependencies
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Alert, Button, Select, Textarea } from '../../Components/UI'

import Questions from './questions'

const FormSurvey = ({
  alertContent,
  alertState,
  loading,
  onSubmit,
  survey,
  handleDismissAlert,
  completed,
  isEdit = false,
  setAverage
}) => {
  const [firstQuestion, setFirstQuestion] = React.useState(
    !survey ? 0 : survey.firstQuestion
  )
  const [secondQuestion, setSecondQuestion] = React.useState(
    !survey ? 0 : survey.secondQuestion
  )
  const [thirdQuestion, setThirdQuestion] = React.useState(
    !survey ? 0 : survey.thirdQuestion
  )
  const [comment, setComment] = React.useState(!survey ? '' : survey.comment)

  const onChange = e => {
    if (e.target.id === 'firstQuestion') {
      setFirstQuestion(Number(e.target.value))
    }
    if (e.target.id === 'secondQuestion') {
      setSecondQuestion(Number(e.target.value))
    }
    if (e.target.id === 'thirdQuestion') {
      setThirdQuestion(Number(e.target.value))
    }
    if (e.target.id === 'comment') {
      setComment(e.target.value.trim())
    }
  }

  useEffect(() => {
    setAverage(Math.round((firstQuestion + secondQuestion + thirdQuestion) / 3))
  }, [firstQuestion, secondQuestion, thirdQuestion])

  return (
    <Wrapper>
      <form
        onSubmit={e =>
          onSubmit(e, {
            firstQuestion,
            secondQuestion,
            thirdQuestion,
            comment
          })
        }
        acceptCharset='utf-8'
        id='FormCreate'>
        {alertContent.length > 0 && (
          <Alert color={alertState} close={e => handleDismissAlert(e)}>
            {alertContent}
          </Alert>
        )}
        <hr />
        <h2>Preguntas</h2>
        <Select
          disabled={loading || completed}
          labelSize={5}
          inputSize={7}
          name='firstQuestion'
          label='¿Cómo evaluarías la organización del Torneo de la Tienda?'
          onChange={e => onChange(e)}
          select={firstQuestion}
          options={Questions.firstQuestion}
          required
        />
        <Select
          disabled={loading || completed}
          labelSize={5}
          inputSize={7}
          name='secondQuestion'
          label='¿Cómo evaluarías la comunidad y el ambiente de juego dentro de la Tienda?'
          onChange={e => onChange(e)}
          select={secondQuestion}
          options={Questions.secondQuestion}
          required
        />
        <Select
          disabled={loading || completed}
          labelSize={5}
          inputSize={7}
          name='thirdQuestion'
          label='¿Qué tan probable es que recomiendes a otros jugadores a participar en un Torneo de la Tienda?'
          onChange={e => onChange(e)}
          select={thirdQuestion}
          options={Questions.thirdQuestion}
          required
        />
        <Textarea
          disabled={loading || completed}
          labelSize={5}
          inputSize={7}
          name='comment'
          value={comment}
          label='Comentario adicional'
          onChange={e => onChange(e)}
        />

        {isEdit ? (
          <div className='BaseRight__Bottom__actions'>
            <Button
              id='btn_Tournament1'
              disabled={
                loading || !firstQuestion || !secondQuestion || !thirdQuestion
              }
              type='submit'
              text={'Responder'}
              state='primary'
              form='FormCreate'
              className='btn-register'
              style={{ margin: 'auto' }}
            />
          </div>
        ) : (
          <p className='text-center text-info'>
            ¡Muchas gracias por responder!
          </p>
        )}
      </form>
    </Wrapper>
  )
}

export default FormSurvey

// @Styles
const Wrapper = styled.section`
  .img-thumbnail {
    max-width: 480px;
  }
`
