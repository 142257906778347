// @External Dependencies
import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import 'moment/locale/es'
import moment from 'moment'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// @Dependencies
import BaseForm from './BaseForm'
import vars from '../variables'

// @Component
class Calendar extends Component {
  state = {
    date: null
  }

  static getDerivedStateFromProps (props) {
    if (props.value) {
      return { date: moment(props.value, 'DD-MM-YYYY') }
    }

    return null
  }

  handleDayChange = day => {
    const { onChange } = this.props
    this.setState({ date: day })
    setTimeout(() => {
      onChange && onChange(day)
    }, 1)
  }

  cleanInput = () => this.setState({ date: null })

  render () {
    const { minDate, maxDate } = this.props
    const { date } = this.state
    const { disabled, name, required, size, birth } = this.props
    const maxDatee = birth ? -18 : maxDate
    return (
      <StyledCalendar>
        <BaseForm icon='calendar' {...this.props}>
          <DatePicker
            className={`form-control input-${size}`}
            locale='es'
            id={name}
            name={name}
            required={required}
            disabled={disabled}
            selected={date}
            onChange={this.handleDayChange}
            placeholderText={moment().format('DD-MM-YYYY')}
            readOnly
            calendarClassName='rasta-stripes'
            showDisabledMonthNavigation
            showMonthDropdown
            showYearDropdown
            todayButton={'Hoy'}
            minDate={moment().subtract(minDate, 'years')}
            maxDate={moment().add(maxDatee, 'years')}
            scrollableYearDropdown
            yearDropdownItemNumber={minDate}
          />
          {date !== null && !disabled && (
            <i
              className='glyphicon glyphicon-remove btn-clean'
              onClick={this.cleanInput}
            />
          )}
        </BaseForm>
      </StyledCalendar>
    )
  }
}

// @Proptypes
Calendar.propTypes = {
  birth: PropTypes.bool,
  disabled: PropTypes.bool,
  info: PropTypes.string,
  inputSize: PropTypes.number,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  size: PropTypes.string,
  tooltip: PropTypes.string,
  value: PropTypes.string,
  minDate: PropTypes.number,
  maxDate: PropTypes.number
}

Calendar.defaultProps = {
  inputSize: 6,
  size: 'md',
  minDate: 70,
  maxDate: 4
}

// @Export Component
export default Calendar

// @Styles
const StyledCalendar = styled.div`
  .form-control {
    cursor: pointer;
  }
  .react-datepicker {
    background-color: #fff;
    border-radius: ${vars.borderRadiusBtn};
    border: 2px solid #c9c9d3;
    box-shadow: 4px 6px 0 rgba(0, 0, 0, 0.03);
    color: #777781;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 0.9286rem;
    padding: 10px 8px;
    &-wrapper {
      display: flex;
    }
    &-popper {
      margin-top: 5px !important;
      z-index: 20;
    }
    &__input-container {
      width: 100%;
    }
    &__header {
      background: #fff;
      border-bottom: none;
      padding: 0;
    }
    &__header__dropdown {
      margin-bottom: 10px;
      & > div {
        background-color: #fff;
        border-radius: ${vars.borderRadiusBtn};
        border: 2px solid #c9c9d3;
        position: relative;
        width: 110px;
        &.react-datepicker__year-dropdown-container {
          cursor: ns-resize;
        }
      }
    }
    &__navigation {
      border-width: 6px;
      top: 16px;
    }
    &__navigation--years-upcoming {
      top: -2px;
    }
    &__navigation--years-previous {
      top: 2px;
    }
    &__month-dropdown-container,
    &__year-dropdown-container {
      & > div {
        background-color: #fff;
        border-radius: ${vars.borderRadiusBtn};
        left: 0;
        top: 20px;
        width: 100%;
        & > div {
          cursor: ns-resize;
          &:hover {
            background: #0089ec;
            color: #fff;
          }
        }
      }
    }
    &__month-read-view--down-arrow,
    &__year-read-view--down-arrow {
      border-width: 5px;
      position: absolute;
      right: 4px;
      top: 9px;
    }
    &__day-name {
      color: #999;
      font-size: 0.7143rem;
    }
    &__day--selected {
      background: #0089ec;
      :hover {
        background: #0089ec;
      }
      &.react-datepicker__day--today {
        color: white;
      }
    }
    &__day--today {
      font-weight: 400 !important;
      position: relative;
      :before {
        border-left: 8px solid transparent;
        border-top: 8px solid #0059bc;
        content: '';
        position: absolute;
        right: 2px;
        top: 2px;
        width: 0;
      }
    }
    &__day--outside-month {
      color: #ddd !important;
    }
    &__current-month {
      font-size: 0.8571rem;
    }
    &__day {
      font-weight: 200;
      height: 24px;
      line-height: 1.7857rem;
      margin: 0 9px !important;
      width: 25px;
    }
    &__day-name {
      margin: 0 9px !important;
      width: 25px;
    }
    &__current-month {
      display: none;
    }
    &__month-option--selected {
      left: 5px;
    }
    &__today-button {
      background-color: #fff;
      border-top: none;
      display: flex;
      font-size: 0.7143rem;
      justify-content: center;
      position: relative;
      &:before {
        border-left: 8px solid transparent;
        border-top: 8px solid #0059bc;
        content: '';
        margin: 3px 5px 0 0;
        top: 0;
        width: 0;
      }
    }
    &__triangle {
      display: none;
    }
    &__header__dropdown {
      &--select {
        > div {
          height: 23px;
        }
      }
      select {
        border: none;
        width: 100%;
        text-align: center;
        text-align-last: center;
        position: absolute;
        left: 0;
      }
    }
    &__year-read-view {
      cursor: ns-resize;
    }
  }
`
