// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { axiosError, getToken } from '../../../Services'

const requestId = 'getFormats'

export default () => {
  return dispatch => {
    getToken()
    const query = `
      query {
        allFormats(orderBy: name_ASC, filter: { status: 1 }) {
          id
          name
          status
          FormatGames {
            gameId
            formatId
          }
        }
      }
    `

    return axios
      .post(`${apiURL}/graphql`, { query }, { requestId })
      .then(response => {
        if (response.status === 200) {
          return {
            formats: response.data.data.allFormats,
            status: {
              success: true,
              name: 'Petición exitosa'
            }
          }
        } else {
          return {
            formats: [],
            status: {
              success: false,
              name: 'No se encontraron datos'
            }
          }
        }
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}
