// @External Dependencies
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const getViewBox = size => `0 0 ${size} ${size}`

const getPath = (name, props) => {
  switch (name) {
    case 'bell':
      return (
        <path
          {...props}
          d='M11.826 6.957V4.87A4.84 4.84 0 0 0 6.956 0a4.84 4.84 0 0 0-4.869 4.87v2.087C2.087 9.252 0 9.809 0 11.13c0 1.183 2.713 2.087 6.957 2.087 4.243 0 6.956-.904 6.956-2.087 0-1.321-2.087-1.878-2.087-4.173zm-4.869 7.652c-.905 0-1.67-.07-2.435-.14C5.009 15.375 5.913 16 6.957 16 8 16 8.974 15.374 9.39 14.47c-.765.07-1.53.139-2.434.139z'
        />
      )
    case 'comment':
      return (
        <path
          {...props}
          d='M8 0C3.6 0 0 3.1 0 7s3.6 7 8 7c.4 0 .8 0 1.1-.1L14 16v-4.4c1.2-1.2 2-2.8 2-4.6 0-3.9-3.6-7-8-7z'
        />
      )
    case 'flag':
      return <path {...props} d='M.5 1.5h11l-2 4 2 4H.5M.5.5v15' />
    case 'circle-check':
      return (
        <g>
          <circle {...props} cx='8' cy='8' r='7.5' />
          <path {...props} d='M4.5 7.5L7 10l5-5' />
        </g>
      )
    case 'certificate':
      return (
        <g {...props}>
          <path d='M7.5 14.5h-6a1 1 0 0 1-1-1v-12a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v4' />
          <circle cx='12.5' cy='8.5' r='3' />
          <path d='M14.5 10.736V15.5l-2-1-2 1v-4.764M3.5 4.5h5M3.5 7.5h3M3.5 10.5h3' />
        </g>
      )
    case 'alert':
      return (
        <g {...props}>
          <circle cx='7.5' cy='7.5' r='7' {...props} />
          <circle cx='7.5' cy='11' r='1' fill='#999' />
          <path {...props} d='M7.5 3.5V8' />
        </g>
      )
    case 'calendar':
      return (
        <path
          {...props}
          d='M1 5.5h14M14 2.5H2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-11a1 1 0 0 0-1-1zM4 .5v2M12 .5v2M8 .5v2M6 7.5v6M10 7.5v6M3 10.5h10'
        />
      )
    case 'calendar-time':
      return (
        <g
          {...props}
          fillRule='evenodd'
          strokeLinecap='round'
          strokeLinejoin='round'>
          <path d='M.5 5.5h15m0 0v-2a1 1 0 0 0-1-1h-13a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h5m-2-15v2m7-2v2' />
          <circle cx='11.5' cy='11.5' r={4} />
          <path d='M11.5 9.5v2h2' />
        </g>
      )
    case 'information-welcome':
      return (
        <g fill='none' fillRule='evenodd'>
          <g transform='translate(1 1)'>
            <circle cx={13} cy={13} r={13} {...props} />
            <path
              fill='#FFF'
              d='M10 10.75h4.314v7.07h1.336v2.541H10V17.82h1.447v-4.527H10V10.75zm1.299-3.099c0-.228.042-.443.125-.644a1.602 1.602 0 0 1 .872-.877c.201-.087.42-.13.654-.13.223 0 .433.043.631.13a1.664 1.664 0 0 1 1.002 1.521 1.664 1.664 0 0 1-1.002 1.521c-.198.087-.408.13-.63.13a1.635 1.635 0 0 1-1.179-.482 1.602 1.602 0 0 1-.348-.524 1.664 1.664 0 0 1-.125-.645z'
            />
          </g>
          <path
            {...props}
            fillRule='nonzero'
            d='M14 28C6.268 28 0 21.732 0 14S6.268 0 14 0s14 6.268 14 14-6.268 14-14 14zm0-2c6.627 0 12-5.373 12-12S20.627 2 14 2 2 7.373 2 14s5.373 12 12 12z'
          />
        </g>
      )
    case 'information-gmt':
      return (
        <path
          {...props}
          fillRule='nonzero'
          d='M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0zm2.658 18.284c-.661.26-2.952 1.354-4.272.191a1.676 1.676 0 0 1-.59-1.318c0-.998.328-1.868.919-3.957.104-.395.231-.907.231-1.313 0-.701-.266-.887-.987-.887-.352 0-.742.125-1.095.257l.195-.799c.787-.32 1.775-.71 2.621-.71 1.269 0 2.203.633 2.203 1.837 0 .347-.06.955-.186 1.375l-.73 2.582c-.151.522-.424 1.673-.001 2.014.416.337 1.401.158 1.887-.071l-.195.799zM13.452 8a1.5 1.5 0 1 1 .001-3.001A1.5 1.5 0 0 1 13.452 8z'
        />
      )
    case 'letter':
      return (
        <g
          {...props}
          fillRule='evenodd'
          strokeLinecap='round'
          strokeLinejoin='round'>
          <path d='M.5 4L8 8l7.5-4' />
          <path d='M14.5 13.5h-13a1 1 0 0 1-1-1v-11a1 1 0 0 1 1-1h13a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1z' />
        </g>
      )
    case 'phone':
      return (
        <path
          {...props}
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M12 10l-2 2-6-6 2-2-3-3-2 2c0 6.627 5.373 12 12 12l2-2-3-3z'
        />
      )
    case 'arrow':
      return (
        <path
          {...props}
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M6 4l4.5 4.5L6 13'
        />
      )
    case 'arrow-back':
      return (
        <path
          {...props}
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M9.5 12L5 7.5 9.5 3'
        />
      )
    case 'arrow-big':
      return (
        <path
          {...props}
          fillRule='evenodd'
          d='M8.106 12.235a.934.934 0 0 1-.611-.227L.212 5.75l1.222-1.43 6.672 5.735 6.672-5.735L16 5.75l-7.283 6.258a.934.934 0 0 1-.611.227z'
        />
      )
    case 'close':
      return (
        <path
          {...props}
          d='M3.5 3.5l9 9m0-9l-9 9'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      )
    default:
      return <path />
  }
}

// @Component
/**
 * Este componente se encarga de dibujar todos los íconos svg pequeños de la app.
 * Los íconos más elaborados, tienen un archivo exclusivo para cada uno, por ejemplo
 * los de Ranking.
 */
const Svg = ({
  name = '',
  style = {},
  fill = 'none',
  stroke = '',
  className = '',
  size = '16'
}) => (
  <svg
    width={size}
    style={style}
    height={size}
    viewBox={getViewBox(size)}
    className={className}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'>
    {getPath(name, { fill, stroke })}
  </svg>
)

// @Export Styled Component
export const Icon = styled(Svg)`
  flex-shrink: 0;
  margin-right: ${({ className }) =>
    className && className.split(' ').includes('arrow-icon') ? 0 : '10px'};
`

// @PropTypes
Icon.propTypes = {
  name: PropTypes.string,
  style: PropTypes.object,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string
}
