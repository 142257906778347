export default {
  // // Border
  // borderRadius: '3px',
  // borderRadiusBtn: '5px',
  // borderTable: '1px solid #e0e0e0',
  // // Font
  // fontSize: '14px',
  // lineHeight: '1.4rem',
  // Color
  setColors: {
    primary: '#0189ff',
    info: '#97c0e1',
    default: '#c9c9d3',
    success: '#97d96c',
    warning: '#ecc863',
    danger: '#ed9b99',
    active: '#adadbd',
    text: '#9a9aa4',
    buttons: {
      success: '#2cb26b'
    },
    hexToRgbA: (hex, opacity) => hexToRgbA(hex, opacity),

    black: '#222222',
    brownishGrey: '#5c5c5c',
    blackTwo: '#333333',
    brownGrey: '#797979',
    veryLightPink: '#c6c6c6',
    brownGreyTwo: '#999999',
    veryLightPinkTwo: '#e8e8e8',
    deepSeaBlue: '#005580',
    deepSkyBlue: '#0189ff',
    white: '#f4f4f4',
    whiteTwo: '#f8f8f8',
    whiteThree: '#ffffff',
    whiteFour: '#e8e8e8',
    red: '#cc0000',
    jadeGreen: '#28a745',
    orangeyYellow: '#f5a623',
    veryLightBlue: '#e0eefa',
    ice: '#e9f6ec'
  },
  inputOptionFontSize: '1rem',
  inputOptionFontSizeSm: '0.7143rem',
  inputOptionSize: '1.7143rem',
  inputOptionSizeSm: '1.2143rem',
  fontSize: 16,
  fontSizeMobile: 14,
  hSize: {
    normal: {
      h1: 40,
      h2: 36,
      h3: 32,
      h4: 28,
      h5: 24,
      h6: 20
    },
    mobile: {
      h1: 36,
      h2: 32,
      h3: 28,
      h4: 24,
      h5: 20,
      h6: 18
    }
  },
  pSize: {
    big: 18,
    medium: 16,
    small: 14,
    tiny: 12
  },
  articleH: {
    normal: {
      // h1: 0,
      h2: 36,
      h3: 36,
      h4: 32,
      h5: 26,
      h6: 20
    },
    mobile: {
      // h1: 0,
      h2: 28,
      h3: 28,
      h4: 26,
      h5: 23
      // h6: 0,
    }
  },
  alertFontSize: {
    big: 24,
    tiny: 14
  }
}

const hexToRgbA = (hex, opacity) => {
  let c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = '0x' + c.join('')
    return (
      'rgba(' +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
      ',' +
      opacity +
      ')'
    )
  }
}
