// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { getToken, axiosError } from '../../../Services'

export default () => {
  return dispatch => {
    getToken()
    const query = `
       query TournamentFilters {
        TournamentFilters {
          status {
            success
            name
            detail
          }
          Games {
            id
            name
          }
          Seasons {
            id
            name
            isActive
            gameId
          }
          SeasonsTypes {
            id
            name
          }
          Countries {
            id
            name
            iso
          }
          Regions {
            id
            name: fullName
            countryId
          }
          Types {
            id
            name
          }
          States {
            id
            name
          }
          Stores {
            id
            name
            levelId
            Level {
              name
            }
          }
          Presentials {
            id
            name
          }
        }
      }
    `

    return axios
      .post(`${apiURL}/graphql`, { query }, {})
      .then(response => {
        if (response.status === 200) {
          return response.data.data.TournamentFilters
        } else {
          return []
        }
      })
      .catch(err => axiosError(err, 'TournamentFilters', dispatch))
  }
}
