// @External Dependencies
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// import PlaceholderName from './PlaceholderName'
// @Component

function ImgElement ({ url, alt, fn }) {
  return (
    <img
      alt={alt}
      src={url}
      onLoad={() => fn(true)}
      onError={() => fn(false)}
    />
  )
}

export default function RoundedImage ({
  image,
  size,
  alt,
  marginBottom,
  marginRight
}) {
  return (
    <Wrapper
      size={size}
      marginBottom={marginBottom}
      marginRight={marginRight}
      className='RoundedImage'>
      <ImgElement url={image} alt={alt} fn={() => {}} />
    </Wrapper>
  )
}

// @Proptypes
RoundedImage.propTypes = {
  alt: PropTypes.string,
  image: PropTypes.string.isRequired,
  marginBottom: PropTypes.number,
  marginRight: PropTypes.number,
  size: PropTypes.number
}

RoundedImage.defaultProps = {
  alt: 'Rounded Image',
  image: '',
  marginBottom: 0,
  marginRight: 0,
  size: 50
}

const Wrapper = styled.figure`
  border-radius: 50%;
  flex-shrink: 0;
  height: ${props => props.size}px;
  margin: 0 ${props => props.marginRight}px ${props => props.marginBottom}px 0;
  overflow: hidden;
  width: ${props => props.size}px;

  img {
    height: auto;
    max-width: 100%;
  }
`
