// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { getToken, axiosError } from '../../../Services'

export default id => {
  const requestId = 'TournamentTypeDelete'
  return dispatch => {
    getToken()
    const query = `
      mutation TournamentTypeDelete($TournamentTypeDeleteInput: TournamentTypeDeleteInput!) {
        TournamentTypeDelete(input: $TournamentTypeDeleteInput) {
          status {
            success
            name
            detail
          }
        }
      }

    `
    const variables = {
      TournamentTypeDeleteInput: {
        id
      }
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables }, { requestId })
      .then(response => {
        return response.data.data.TournamentTypeDelete
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}
