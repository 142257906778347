// @Dependencies
const eng = require('./eng.png')
const spa = require('./spa.png')

/**
 * Export module
 */
module.exports = {
  eng,
  spa
}
