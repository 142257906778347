// @External Dependencies
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import styled from 'styled-components'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'font-awesome/css/font-awesome.min.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './assets/css/nucleo.css'

// @Dependencies
import { setMediaquery } from './app/Redux/Actions'
import './app/Components/UI/Styles'
import ReactGA from 'react-ga'

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

// @Component
class App extends Component {
  constructor (props) {
    super(props)
    if (process.env.NODE_ENV === 'production') {
      // Sentry
      Sentry.init({
        environment: process.env.NODE_ENV,
        release: process.env.REACT_APP_SENTRY_RELEASE,
        autoSessionTracking: false,
        dsn:
          'https://f124357e30c6456cb4f41e04c31d96e7@o1049620.ingest.sentry.io/6030872',
        integrations: [new Integrations.BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0
      })
      Sentry.configureScope(scope => {
        scope.setUser(this.props.loggedUser)
      })
    }
  }

  componentDidMount () {
    const { setMediaquery } = this.props

    // media query change
    const WidthChange = mq => {
      if (mq.matches) {
        setMediaquery(true)
      } else {
        setMediaquery(false)
      }
    }

    // media query event handler
    if (matchMedia) {
      const mq = window.matchMedia('(max-width: 768px)')
      mq.addListener(WidthChange)
      WidthChange(mq)
    }
  }

  componentDidCatch (error, errorInfo) {
    if (process.env.NODE_ENV === 'production') {
      this.setState({ error })
      Sentry.withScope(scope => {
        scope.setExtras(errorInfo)
        const eventId = Sentry.captureException(error)
        this.setState({ eventId })
      })
    }
  }

  render () {
    const { children, className } = this.props
    return <section className={className}>{children}</section>
  }
}

/**
 * Filtramos las URL para Analytics dejándo como % todos los numéricos de la URL
 */
const analyticsUrl = window.location.pathname
  .split('/')
  .filter(part => part.length > 0)
  .map(part => {
    if (!isNaN(parseInt(part))) {
      return '%'
    }
    return part
  })
  .join('/')
if (analyticsUrl.length) {
  ReactGA.initialize('UA-XXXXXXX-X')
  ReactGA.pageview(analyticsUrl)
}
// @Proptypes
App.propTypes = {
  children: PropTypes.element
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setMediaquery
    },
    dispatch
  )

const ConnectedApp = connect(
  null,
  mapDispatchToProps
)(App)

// @Styles
export default styled(ConnectedApp)`
  margin: 0;
  min-height: 100vh;
  padding: 0;
  width: 100%;
`
