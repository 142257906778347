// @External Dependencies
import React from 'react'
import Alert from 'reactstrap/lib/Alert'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import UIVars from '../variables'

// @Component
const AlertUI = ({
  icon,
  color,
  close,
  children,
  typeLabel,
  inputSize,
  className
}) => (
  <div className={`wrapper ${typeLabel ? 'row' : ''}`}>
    <StyledAlertUI
      className={typeLabel && `col-sm-${inputSize} col-sm-offset-2`}>
      <Alert toggle={close} className={className} color={color}>
        <i className={`fa fa-${icon}`} />
        <div className='content'>{children}</div>
      </Alert>
    </StyledAlertUI>
  </div>
)

// @Proptypes
AlertUI.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  close: PropTypes.func,
  icon: PropTypes.string,
  inputSize: PropTypes.number,
  color: PropTypes.string,
  typeLabel: PropTypes.bool
}

AlertUI.defaultProps = {
  icon: 'info',
  inputSize: 8,
  color: 'warning'
}

export default AlertUI

const StyledAlertUI = styled.div.attrs({ className: 'alertUI' })`
  .alert {
    align-items: center;
    border: 0;
    color: #fff;
    display: flex;
    font-weight: 500;
    justify-content: space-between;
    padding-left: 60px;
    position: relative;
    transition: all ease-in-out 0.3s;
    width: 100%;

    i {
      align-items: center;
      border-radius: 4px 0 0 4px;
      color: white;
      display: flex;
      font-size: 20px;
      height: 100%;
      justify-content: center;
      left: 0;
      position: absolute;
      top: 0;
      width: 50px;
    }

    ul {
      margin-bottom: 0;
      padding-left: 15px;
    }

    .close {
      font-size: 24px;
      opacity: 1;
      order: 3;
      text-shadow: none;
      &:hover {
        color: #fff;
        opacity: 0.8;
      }
      &:focus,
      &:active {
        outline: 0;
      }
    }

    &-warning {
      background-color: ${UIVars.setColors.warning};
    }
    &-success {
      background-color: ${UIVars.setColors.success};
    }
    &-info {
      background-color: ${UIVars.setColors.info};
    }
    &-danger {
      background-color: ${UIVars.setColors.danger};
    }
  }
`
