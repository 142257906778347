// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { axiosError, getToken } from '../../../Services'

const recoverPasswd = search => {
  return dispatch => {
    getToken()
    const query = `
      mutation UserPasswdRecover($UserPasswdRecoverInput: UserPasswdRecoverInput!) {
        UserPasswdRecover(input: $UserPasswdRecoverInput) {
          status  {
            success
            name
            detail
          }
        }
      }
    `
    const variables = {
      UserPasswdRecoverInput: {
        search
      }
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then(response => {
        if (response.data.errors) {
          return response.data.errors[0].message
        } else {
          const { UserPasswdRecover } = response.data.data
          return UserPasswdRecover
        }
      })
      .catch(err => axiosError(err))
  }
}

export default recoverPasswd
