/**
 * Desloguea a un usuario del sistema
 * Borra los datos de loggedUser del storage
 * @example
 * ```js
 *   UserLogout()
 * ```
 */
const UserLogout = payload => {
  return {
    type: 'LOGOUT',
    payload
  }
}

export default UserLogout
