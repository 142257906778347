// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { getToken, axiosError } from '../../../Services'

export default filters => {
  const requestId = 'getStoreList'
  return dispatch => {
    getToken()
    const query = `
      query StoreList($StoreListInput: StoreListInput!) {
        StoreList(input: $StoreListInput) {
          status {
            success
            name
            detail
          }
          total
          pages
          Stores {
            id
            name
            description
            logo
            urlLogo
            phone
            email
            urlView
            urlTournaments
            siteUrl
            Level {
              name
              icon
            }
            StoreTournamentDays {
              TournamentDay {
                name
              }
            }
            Address {
              name
              address
              Country {
                iso
                name
              }
              Region {
                fullName
              }
              Location {
                name
              }
            }
            StoreGames {
              id
              gameId
            }
          }
          Countries {
            name
            id
          }
          Regions {
            id
            fullName
          }
        }
      }

    `
    const variables = {
      StoreListInput: {
        filter: filters
      }
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables }, { requestId })
      .then(response => {
        if (response.status === 200) {
          return response.data.data.StoreList
        } else {
          return []
        }
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}
