// @External Dependencies
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { Row, Col, Container, Jumbotron } from 'reactstrap'
import TournamentModal from '../Users/TournamentModal'
import { bgBackground } from '../../../assets/img/login'
// @Dependencies
import {
  getGames,
  getTournamentsList,
  getSeasons,
  getRankingList,
  getUser,
  getCountries,
  getRegions,
  getUserTournaments
} from '../../Redux/Actions'
import { axiosAbort } from '../../Services'
import { LoadingSVG, Select, PaginationList, Input } from '../../Components/UI'
import RankingList from './RankingList'

import moment from 'moment'
import 'moment/locale/es'

// @Component
class RankingView extends React.Component {
  state = {
    loading: true,
    loadingMessage: 'Estamos buscando los datos del Ranking',
    Ranking: [],
    seasons: [],
    Countries: [],
    Games: [],
    Game: {
      id: 0,
      name: '',
      urlImage: ''
    },
    seasonId: 0,
    filters: {
      countryId: 0,
      regionId: 0,
      search: '',
      page: 1,
      limit: 32
    },
    dateFormat: 'dddd DD \\d\\e MMMM \\d\\e\\l Y'
  }

  async componentDidMount () {
    const { params } = this.props.match
    const games = await this.props.getGames()
    const game = games.find(game => game.id === Number(params.gameId))
    if (game) {
      const seasons = game.Seasons.map(s => {
        return {
          id: s.id,
          name: `${s.name}`
        }
      })
      this.setState({
        seasons,
        seasonId: Number(params.seasonId),
        Games: games,
        Game: game,
        filters: { ...this.state.filters, page: this.getPageSearch() }
      })
      this.searchRanking(Number(params.seasonId))
    } else {
      this.setState({
        loadingMessage: 'Ocurrió un problema al listar el ranking.'
      })
    }
  }
  componentWillUnmount () {
    axiosAbort()
  }

  /**
   * Busca el top 16 del ranking
   */
  async searchRanking (seasonId) {
    this.setState({ loading: true })
    const response = await this.props.getRankingList(
      seasonId,
      this.state.filters
    )
    if (response.status.success) {
      this.setState({
        ...response,
        Regions: response.Regions.map(r => {
          return {
            id: r.id,
            name: r.fullName
          }
        }),
        loading: false
      })
    } else {
      this.setState({
        loadingMessage: response.status.detail
      })
      setTimeout(() => {
        this.props.history.push('/ranking')
      }, 2500)
    }
  }

  onChange = async e => {
    e.preventDefault()
    const target = e.target
    let { filters, seasonId } = this.state
    if (target.id === 'seasonId') {
      if (seasonId !== Number(target.value)) {
        const season = this.state.Game.Seasons.find(
          s => s.id === Number(target.value)
        )
        if (season) {
          this.props.history.replace(season.rankingUrl)
        }
      }
      seasonId = Number(target.value)
      this.setState({ seasonId })
    }

    if (target.id === 'countryId') {
      filters.countryId = Number(target.value)
      filters.page = 1
      if (!filters.countryId) {
        this.setState({ Regions: [] })
      }
    }
    if (target.id === 'regionId') {
      filters.regionId = Number(target.value)
      filters.page = 1
    }

    let search = true
    if (target.id === 'search') {
      filters.search = target.value
      search = false
    }

    this.setState({ filters })
    if (search) {
      this.searchRanking(seasonId)
    }
  }

  handleSubmit = async e => {
    e.preventDefault()
    await this.searchRanking(this.state.seasonId)
  }

  /**
   * Paginación
   */
  handlePagination = async event => {
    const { filters } = this.state
    if (filters.page !== event) {
      filters.page = event
      const { search, pathname } = this.props.history.location
      let pageSearch = search
        .replace('?', '')
        .split('&')
        .map(s => {
          const split = s.split('=')

          if (split.length > 1) {
            split[1] = event
          }
          return split.join('=')
        })
        .filter(x => x.length)
      if (!pageSearch.length) {
        pageSearch = ['page', event].join('=')
      }
      const url = `${pathname}?${pageSearch}`
      this.props.history.replace(url)
      this.setState({ filters })
      await this.searchRanking(this.state.seasonId)
    }
  }

  getPageSearch = () => {
    const { search } = this.props.history.location
    return search
      .replace('?', '')
      .split('&')
      .map(s => {
        const split = s.split('=')
        if (split.length > 1) {
          return Number(split[1])
        }
        return 1
      })
      .find(x => x)
  }

  /**
   * Cambia a un jugador
   */
  toggleModal = async personId => {
    if (personId > 0) {
      this.setState({ personId })
      const PlayerInfo = await this.props.getUserTournaments({
        personId,
        seasonId: this.state.seasonId
      })
      if (PlayerInfo.status.success) {
        this.setState({
          modal: true,
          PlayerInfo
        })
      }
    } else {
      this.setState({
        modal: false,
        PlayerInfo: null
      })
    }
  }

  render () {
    const {
      loading,
      loadingMessage,
      Ranking,
      Season,
      dateFormat,
      PlayerInfo
    } = this.state

    if (loading) {
      return <LoadingSVG message={loadingMessage} />
    }
    return (
      <Wrapper>
        <Container>
          <Row>
            <Col md={12}>
              <article className='Ranking'>
                <Jumbotron>
                  <Row>
                    <Col
                      md={12}
                      className={`${!this.state.Game.urlImage ? '' : 'grid'}`}>
                      {this.state.Game.urlImage && (
                        <div className='image'>
                          <img
                            src={this.state.Game.urlImage}
                            alt={this.state.Game.name}
                            className='img-thumbnail'
                          />
                        </div>
                      )}
                      <div className='content'>
                        <h1 className='text-center'>
                          Ranking "{this.state.Game.name}"
                        </h1>
                        <p>
                          Puedes aplicar filtros para acotar el ranking de
                          jugadores de esta temporada.
                        </p>
                        <p>
                          <b>Temporada:</b> {Season.name}
                        </p>
                        <p>
                          <b>Fecha:</b> Desde el{' '}
                          <i>{moment(Season.startDate).format(dateFormat)}</i>{' '}
                          al{' '}
                          <i>
                            <u>{moment(Season.endDate).format(dateFormat)}</u>
                          </i>
                        </p>
                        <p>
                          <b>Estado:</b>{' '}
                          {Season.isActive
                            ? '✅ Temporada Activa'
                            : '⚠️ Temporada Inactiva'}
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <div className='Tournaments__filter row'>
                    <form onSubmit={this.handleSubmit} className='col-sm-6'>
                      <Input
                        inputSize={12}
                        name='search'
                        placeholder='Buscar por nombre jugador (Nombre, Nick o Código jugador)'
                        value={this.state.filters.search}
                        onChange={this.onChange}
                      />
                    </form>
                    <Col md={6} className='text-center'>
                      <Select
                        inputSize={12}
                        name='seasonId'
                        options={this.state.seasons}
                        select={this.state.seasonId}
                        onChange={this.onChange}
                        noDefaultValue={true}
                      />
                    </Col>
                    <Col md={6} className='text-center'>
                      <Select
                        inputSize={12}
                        name='countryId'
                        options={this.state.Countries}
                        placeholder='Todos los Paises'
                        onChange={this.onChange}
                        select={this.state.filters.countryId}
                      />
                    </Col>
                    <Col md={6} className='text-center'>
                      <Select
                        inputSize={12}
                        name='regionId'
                        options={this.state.Regions}
                        placeholder='Todos las regiones'
                        onChange={this.onChange}
                        select={this.state.filters.regionId}
                      />
                    </Col>
                  </div>
                </Jumbotron>

                {Ranking.length > 0 ? (
                  <React.Fragment>
                    <RankingList
                      ranking={Ranking}
                      total={this.state.total}
                      page={this.state.filters.page}
                      limit={this.state.filters.limit}
                      toggleModal={this.toggleModal}
                      isMobile={this.props.isMobile}
                      filtered={this.state.filters.search.length > 0}
                    />
                    <PaginationList
                      active={this.state.filters.page}
                      pages={this.state.pages}
                      total={this.state.total}
                      handle={this.handlePagination}
                      centered
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Jumbotron className='text-center'>
                      <h2 className='text-center'>
                        <i className='fa fa-user-secret' />
                        <br />
                        Aún no se han reportados torneos en esta temporada.
                        Acércate a tu tienda más cercana para participar en
                        eventos.
                      </h2>
                    </Jumbotron>
                  </React.Fragment>
                )}
              </article>
            </Col>
          </Row>
        </Container>
        {PlayerInfo && (
          <TournamentModal
            player={PlayerInfo}
            isOpen={this.state.modal}
            toggleModal={this.toggleModal}
            isMobile={this.props.isMobile}
          />
        )}
      </Wrapper>
    )
  }
}

// @Proptypes
RankingView.propTypes = {
  loggedUser: PropTypes.object
}

const mapStateToProps = state => ({
  loggedUser: state.loggedUser
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getTournamentsList,
      getSeasons,
      getRankingList,
      getCountries,
      getRegions,
      getUserTournaments,
      getUser,
      getGames
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(RankingView))

// @Styles
const Wrapper = styled.div.attrs({ className: 'Ranking' })`
  background: url(${bgBackground('fondo4')}) 50% 0;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding: 80px 0px;

  .container {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 20px;
    padding: 20px 15px;
    @media (min-width: 426px) and (max-width: 768px) {
      grid-template-columns: 1fr;
    }
    @media (max-width: 426px) {
      grid-template-columns: 1fr;
    }
  }

  .Ranking {
    padding: 0px;
    &__table {
      td,
      th {
        padding: 3px;
        cursor: pointer;
        vertical-align: middle;
      }
    }
    &__footer {
      width: 100%;
      text-align: center;
      button {
        margin: 0 auto !important;
      }
    }
  }
  .Tournaments {
    .btn-link {
      display: block;
      text-align: right;
    }
  }
`
