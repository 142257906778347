// @External Dependencies
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Col } from 'reactstrap'

import StoreCard from './StoreCard'
const StoreList = ({ stores, total, active, isMobile }) => (
  <Wrapper className='Stores__list flex'>
    {stores &&
      stores.length &&
      stores.map((store, key) => {
        return (
          <Col md={4} className='ListElement col-xs-12' key={key}>
            <StoreCard store={store} isMobile={isMobile} />
          </Col>
        )
      })}
  </Wrapper>
)

// @Proptypes
StoreList.propTypes = {
  stores: PropTypes.array.isRequired
}

export default StoreList

// @Styles
const Wrapper = styled.section`
  margin-top: 30px;
  flex-flow: wrap;
  p {
    margin: 0;
  }
  @media (min-width: 426px) and (max-width: 768px) {
    .ListElement {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  @media (max-width: 426px) {
    .ListElement {
      &.col-xs-12 {
        padding: 0px;
      }
    }
  }
  .ListElement {
    .card {
      margin: 20px auto;
      // box-shadow: 10px 5px 40px 20px #e9ecef;
      transition: 0.25s;
      padding: 0px;

      &:hover {
        box-shadow: 0px 0px 20px 10px #c9c9d3;
        cursor: pointer;

        .button-primary {
          transform: translate(10px, 0);
        }
      }
    }

    .card-header {
      height: 200px;
      width: 100%;
      padding: 0px;
      width: 100%;
      background-size: cover;
      background-position: center;
      color: #fff;
      h4 {
        font-size: 1rem;
        padding: 15px;
        background-color: rgba(244, 244, 244, 0.4);
      }
      &--title {
        text-transform: uppercase;
        margin: 0;
      }
    }

    .card-body {
      padding: 15px;
      background-color: #fff;
      width: 100%;
      h3 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .date {
        font-size: 11px;
        font-weight: 600;
        color: grey;
      }
      .category {
        position: absolute;
        top: 160px;
        left: 0px;
        width: 100%;
        background: #e74c3c;
        padding: 10px 15px;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        i {
          margin-right: 10px;
        }
      }

      .body-content {
        margin-bottom: 10px;
        font-size: 13px;
        height: 40px;
        overflow: hidden;
      }

      .button-primary {
        border: none;
        box-shadow: none;
        font-family: inherit;
        background-color: transparent;
        font-size: 15px;
        transition: 0.25s;

        i {
          padding-right: 4px;
        }
      }

      .designer-link {
        color: #fff;
        text-align: center;
        font-size: 12px;
        text-decoration: none;
        display: block;
      }
    }
  }
`
