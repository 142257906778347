// @External Dependencies
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// @Component
function PlaceholderName (props) {
  const { id, name, size, fontSize, lineHeight } = props
  const [selectedColor, setColor] = useState('#0076ba')

  useEffect(() => {
    const colors = [
      '#f6712f',
      '#229f9c',
      '#30baed',
      '#95569e',
      '#9bc03a',
      '#ff6363',
      '#f5a623',
      '#0076ba',
      '#d84315',
      '#2cb26b'
    ].map(x => '#6a6a6a')

    let randomPosition = Math.floor(Math.random() * colors.length)
    if (id >= 0) {
      randomPosition = id
        .toString()
        .split('')
        .pop()
    }
    const randomColor = colors[randomPosition]

    setColor(randomColor)
  }, [setColor])

  const splitLetterFromName = name =>
    name
      .split(' ')
      .map(name => name[0])
      .slice(0, 2)
      .join('')

  return (
    <Placeholder
      className='PlaceholderName flex-center'
      selectedColor={selectedColor}
      size={size}
      fontSize={fontSize}
      lineHeight={lineHeight}>
      <span>{splitLetterFromName(name)}</span>
    </Placeholder>
  )
}

// @PropTypes
PlaceholderName.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
  fontSize: PropTypes.number,
  lineHeight: PropTypes.number
}

PlaceholderName.defaultProps = {
  size: 30,
  fontSize: 14,
  lineHeight: 18,
  id: -1
}

// @Export Styled Component
const Placeholder = styled.div`
  background-color: ${({ selectedColor }) => selectedColor};
  border-radius: 50%;
  flex-shrink: 0;
  height: ${({ size }) => `${size}px`};
  width: ${({ size }) => `${size}px`};

  span {
    color: white;
    font: bold ${({ fontSize, lineHeight }) => `${fontSize}px/${lineHeight}px`}
      'Roboto';
    text-transform: uppercase;
  }

  @media screen and (max-width: 480px) {
    span {
      color: white;
      font: bold
        ${({ fontSize, lineHeight }) => `${fontSize / 1.5}px/${lineHeight}px`}
        'Roboto';
      text-transform: uppercase;
    }
  }
`

export default PlaceholderName
