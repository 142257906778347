// @External Dependencies
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { Row, Col, Container, Jumbotron } from 'reactstrap'
import { Link } from 'react-router-dom'
import { bgBackground } from '../../../assets/img/login'
// @Dependencies
import {
  getFormatList,
  FormatDelete,
  getUser,
  getGames
} from '../../Redux/Actions'
import { axiosAbort } from '../../Services'
import { LoadingSVG, Select } from '../../Components/UI'
import FormatList from './List'

// @Component
class FormatsView extends React.Component {
  state = {
    loading: true,
    loadingMessage: 'Estamos buscando los formatos de juego.',
    buttonBack: '',
    gameId: 0,
    Formats: [],
    Games: []
  }

  async componentDidMount () {
    await this.props.getUser()
    this.search()
  }
  componentWillUnmount () {
    axiosAbort()
  }

  search = async () => {
    this.setState({ loading: true })
    const Formats = await this.props.getFormatList()
    const Games = await this.props.getGames()
    this.setState({
      Formats,
      Games,
      loading: false
    })
  }

  deleteFormat = async type => {
    this.setState({
      loading: true,
      loadingMessage: 'Estamos borrando el Formato de Juego'
    })
    const response = await this.props.FormatDelete(type.id)

    this.setState({
      loadingMessage: response.status.name
    })

    setTimeout(() => {
      this.search()
      this.setState({ loading: false })
    }, 3000)
  }

  onChange = e => {
    const target = e.target

    if (target && target.id === 'gameId') {
      this.setState({
        gameId: Number(target.value)
      })
    }
  }

  render () {
    const { loading, Formats } = this.state
    if (loading)
      return (
        <LoadingSVG
          message={this.state.loadingMessage}
          buttonBack={this.state.buttonBack}
        />
      )
    const { isAdmin } = this.props.loggedUser
    return (
      <Wrapper>
        <Container>
          <Row>
            <Col md={12}>
              <article className='Formats'>
                <Jumbotron>
                  <Row>
                    <Col md={12}>
                      <h1 className='text-center'>Formatos de Juego</h1>
                      <p>
                        Los formatos de juego regulan las estrategias y proponen
                        ciertas limitaciones o libertades en las cartas que
                        están disponibles para usarse.
                      </p>
                      <p>
                        Algunos formatos de juego son transversales y pueden
                        estar presentes en diferentes <b>Juegos</b>
                      </p>
                      <p>
                        Te invitamos a conocer en detalle cada uno de ellos.
                      </p>
                    </Col>
                    {isAdmin && (
                      <Col md={6}>
                        <Link
                          to={'/admin/formats/create'}
                          className='btn btn-primary'>
                          Crear formato de Juego
                        </Link>
                      </Col>
                    )}
                    <Col md={isAdmin ? 6 : 12}>
                      <Select
                        disabled={loading}
                        inputSize={8}
                        labelSize={4}
                        name='gameId'
                        label='Filtrar por juego'
                        onChange={e => this.onChange(e)}
                        select={this.state.gameId}
                        options={this.state.Games}
                        required
                      />
                    </Col>
                  </Row>
                </Jumbotron>

                {Formats.length > 0 ? (
                  <FormatList
                    formats={Formats}
                    deleteFormat={this.deleteFormat}
                    isAdmin={isAdmin}
                    gameId={this.state.gameId}
                  />
                ) : (
                  <Jumbotron>
                    <h2>No se encontraron formatos de juego</h2>
                  </Jumbotron>
                )}
              </article>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    )
  }
}

// @Proptypes
FormatsView.propTypes = {
  loggedUser: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  loggedUser: state.loggedUser
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getFormatList,
      FormatDelete,
      getGames,
      getUser
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(FormatsView))

// @Styles
const Wrapper = styled.div.attrs({ className: 'Formats' })`
  background: url(${bgBackground('fondo2')}) 50% 0;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding: 80px 0px;

  .container {
    padding: 20px;
    background-color: white;
  }
  .Formats {
    padding: 0px;
    &__table {
      td,
      th {
        padding: 3px;
        cursor: pointer;
        vertical-align: middle;
      }
    }
    &__footer {
      width: 100%;
      text-align: center;
      button {
        margin: 0 auto !important;
      }
    }
  }
  .Tournaments {
    .btn-link {
      display: block;
      text-align: right;
    }
  }
`
