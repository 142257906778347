// @External Dependencies
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import RoundedImage from './RoundedImage'
import PlaceholderName from './PlaceholderName'

// @Component
export default function Avatar ({ person, size, fontSize }) {
  return (
    <Wrapper>
      {person.avatar ? (
        <RoundedImage
          alt={person.fullName}
          size={size || 70}
          image={person.urlAvatar}
        />
      ) : (
        <PlaceholderName
          name={person.fullName}
          size={size || 60}
          fontSize={fontSize || 30}
          id={person.id || 0}
        />
      )}
    </Wrapper>
  )
}

// @Proptypes
Avatar.propTypes = {
  person: PropTypes.object.isRequired
}

const Wrapper = styled.figure`
  margin: 0px;
  display: inline-flex;
`
