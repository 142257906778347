// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { getToken, axiosError } from '../../../Services'

const register = data => {
  return async dispatch => {
    getToken()
    const query = `
      mutation TournamentPlayerRegister($TournamentPlayerRegisterInput: TournamentPlayerRegisterInput!) {
        TournamentPlayerRegister(input: $TournamentPlayerRegisterInput) {
          status {
            success
            name
            detail
          }
          rounds
          TournamentPlayer {
            id
            personId
            raceId
            Person {
              id
              avatar
              fullName
              urlAvatar
              email
              emailValid
              code
            }
          }
          TournamentOrganizers {
            id
            personId
            Person {
              id
              avatar
              fullName
              urlAvatar
              code
            }
          }
        }
      }
    `
    const variables = {
      TournamentPlayerRegisterInput: {
        ...data
      }
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then(response => {
        if (response.data.errors) {
          return response.data.errors[0].message
        } else {
          const { TournamentPlayerRegister } = response.data.data
          return TournamentPlayerRegister
        }
      })
      .catch(err => axiosError(err))
  }
}

export default register
