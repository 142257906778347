// @External Dependencies
import axios from 'axios'
import jwtDecode from 'jwt-decode'
// @Dependencies
import { store } from '../../Redux/Store'

const getToken = () => {
  const token = store.getState().loggedUser.token
  let expired = false
  if (token) {
    const tokenDecoded = jwtDecode(token)
    if (!tokenDecoded.newToken) {
      expired = true
    } else {
      const currentTime = new Date().getTime() / 1000
      if (currentTime < tokenDecoded.exp) {
        axios.defaults.headers.common.Authorization = `bearer ${token}`
        return tokenDecoded
      } else {
        expired = true
      }
    }
  }

  if (expired) {
    window.sessionStorage.setItem('session', 'expired')
    if (window.localStorage) {
      window.localStorage.removeItem('persist:myl')
    }
    window.location.assign('/users/inactive')
  }
  return null
}

// @Export Component
export default getToken
