// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { getToken, axiosError } from '../../../Services'

export default id => {
  const requestId = 'FormatDelete'
  return dispatch => {
    getToken()
    const query = `
      mutation FormatDelete($FormatDeleteInput: FormatDeleteInput!) {
        FormatDelete(input: $FormatDeleteInput) {
          status {
            success
            name
            detail
          }
        }
      }
    `
    const variables = {
      FormatDeleteInput: {
        id
      }
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables }, { requestId })
      .then(response => {
        return response.data.data.FormatDelete
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}
