// @External Dependencies
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import ReactHtmlParser from 'react-html-parser'
import { Jumbotron } from 'reactstrap'

// @Dependencies
import { UserConfirm } from '../../Redux/Actions'
import { LoadingSVG } from '../../Components/UI'
// @Component
class UserValidate extends Component {
  state = {
    loading: true,
    confirmed: false,
    message: '',
    user: {}
  }

  async componentDidMount () {
    const { UserConfirm, match } = this.props
    const { token } = match.params

    const confirm = await UserConfirm(token)
    this.setState({
      confirmed: confirm.status.success,
      user: confirm.status.success ? confirm.person : {},
      message: `${confirm.status.name} <br/> ${
        confirm.status.detail ? confirm.status.detail : ''
      }`,
      loading: false
    })
  }

  render () {
    const { user, confirmed, message, loading } = this.state
    if (loading) return <LoadingSVG />
    return (
      <Wrapper>
        <div className='UserValidateContent'>
          <Jumbotron>
            <h1>Confirmación de email.</h1>
            {confirmed && [
              <p className='text-center'>
                {user ? `${user.fullName} (${user.email})` : ''}
              </p>
            ]}
            <h4 className='text-center'>{ReactHtmlParser(message)}</h4>
            <a href='/' className='btn btn-primary'>
              Ir al inicio
            </a>
          </Jumbotron>
        </div>
      </Wrapper>
    )
  }
}

// @Proptypes
UserValidate.propTypes = {
  loggedUser: PropTypes.object
}

/*
  @Store Connection: connect
  @Export Component
*/

const mapStateToProps = state => ({
  loggedUser: state.loggedUser
})
const mapDispatchToProps = dispatch =>
  bindActionCreators({ UserConfirm }, dispatch)
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(UserValidate))

// @Styles
const Wrapper = styled.div.attrs({ className: 'UserValidate' })`
  height: inherit;
  .UserValidateContent {
    h1 {
      text-align: center;
    }
    a {
      text-align: center;
      display: block;
      margin: 0 auto;
      width: 33%;
    }
  }
`
