// @External Dependencies
import React from 'react'
import PropTypes from 'prop-types'
import { Jumbotron, Table } from 'reactstrap'
import styled from 'styled-components'
import moment from 'moment'

const dateFormat = 'DD MMMM Y - HH:mm\\h\\r\\s.'

const PlayersPdf = ({ players, tournament }) => {
  const hasByes =
    players.filter(player => {
      return player.byes
    }).length > 0

  const hasDrops =
    players.filter(player => {
      return player.drop
    }).length > 0

  return (
    <PdfWrapper>
      <Jumbotron className='Round' id='pdfRender'>
        <h1 className='display-5 text-center'>Listado Jugadores</h1>
        <h4 className='text-center'>{tournament.name}</h4>
        <p className='text-center'>{moment(new Date()).format(dateFormat)}</p>
        {players.length > 0 && (
          <Table className='table-bordered'>
            <thead>
              <tr>
                <th className='text-center'>#</th>
                <th>Jugador</th>
                <th>Código</th>
                {hasByes && <th>Rondas Bye</th>}
                {hasDrops && <th>Drop?</th>}
              </tr>
            </thead>
            <tbody>
              {players.map((player, key) => {
                return (
                  <tr key={key}>
                    <td className='text-center'>{key + 1}</td>
                    <td>{player.fullName}</td>
                    <td>{player.code}</td>
                    {hasByes && (
                      <td className='text-center'>
                        {player.byes ? player.byes : ''}
                      </td>
                    )}
                    {hasDrops && (
                      <td className='text-center'>
                        {player.RoundDrop ? player.RoundDrop.name : ''}
                      </td>
                    )}
                  </tr>
                )
              })}
            </tbody>
          </Table>
        )}
      </Jumbotron>
    </PdfWrapper>
  )
}

// @Proptypes
PlayersPdf.propTypes = {
  players: PropTypes.array.isRequired,
  tournament: PropTypes.object.isRequired
}

export default PlayersPdf

const PdfWrapper = styled.div`
  align-items: center;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  padding: 15px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9999;

  .jumbotron {
    background-color: transparent;
    padding: 0rem 1rem;
    max-width: 700px;
    font-size: 13px;
    th {
      background-color: #f8f9fa;
      vertical-align: middle;
    }
    table {
      td {
        padding: 2px !important;
      }
    }
  }
`
