// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { getToken, axiosError } from '../../../Services'

const TournamentCancel = tournamentId => {
  return dispatch => {
    getToken()
    const query = `
      mutation TournamentCancel($TournamentCancelInput: TournamentCancelInput!) {
        TournamentCancel(input: $TournamentCancelInput) {
          status {
            success
            name
            detail
          }
          Tournament {
            id
            name
            urlView
            isPresential
          }
        }
      }

    `
    const variables = {
      TournamentCancelInput: {
        tournamentId
      }
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then(response => {
        const { TournamentCancel } = response.data.data
        return TournamentCancel
      })
      .catch(err => axiosError(err))
  }
}

export default TournamentCancel
