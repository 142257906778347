// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { getToken, axiosError } from '../../../Services'

const getStorePlayers = data => {
  const requestId = 'getStorePlayers'
  return dispatch => {
    getToken()

    const query = `
      query StorePlayers($StorePlayersInput: StorePlayersInput!) {
        StorePlayers(input: $StorePlayersInput) {
          status {
            success
            name
            detail
          }
          Players {
            id
            fullName
            code
            nickname
            email
            active
          }
        }
      }

    `
    const variables = {
      StorePlayersInput: data
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables }, { requestId })
      .then(response => {
        if (response.status === 200 && response.data.data.StorePlayers) {
          return response.data.data.StorePlayers
        } else {
          return []
        }
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}

export default getStorePlayers
