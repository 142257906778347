// @External Dependencies
import React from 'react'
import { Link } from 'react-router-dom'

import {
  CardHeader,
  CardBody,
  CardFooter
} from '../../Components/UI/SimpleCard'

/**
 * Componente con la caja con los tipos de torneos.
 *
 */
export const GameCard = ({ data, isAdmin }) => {
  return (
    <div className='card'>
      <CardHeader
        image={
          data.urlImage ||
          'https://api.myl.cl/static/images-wpmyl/sectionWallpaper/arte.jpg'
        }
        title={data.name}
        ignoreTitle={data.urlImage.length > 0}
      />
      <CardBody
        key={data.id}
        title={data.name}
        titleClassName='text-center'
        substract={`Total de torneos: ${data.totalTournaments}`}
        text={data.description}
        href={isAdmin ? data.urlUpdate : ''}
        isObject
        info={data.Seasons.map(season => {
          return {
            name: season.isActive ? '✅' : '⚠️',
            value: <Link to={season.rankingUrl}>{season.name}</Link>
          }
        })}
        status={{ ignore: true }}
      />
      {isAdmin && (
        <CardFooter
          href={data.urlUpdate}
          texts={{ desktop: 'Ver juego', mobile: 'Ver juego' }}
        />
      )}
    </div>
  )
}
