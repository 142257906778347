// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { getToken, axiosError } from '../../../Services'

const requestId = 'getTournamentTypes'
export default input => {
  return dispatch => {
    getToken()
    const query = `
       query UserList($UserListInput: UserListInput!) {
        UserList(input: $UserListInput) {
          status {
            success
            name
          }
          total
          pages
          Users {
            id
            name
            fullName
            code
            email
            avatar
            urlAvatar
            emailValid
            active
            urlEdit
            created
            PersonCategory {
              name
            }
            Address {
              Region {
                id
                name
                fullName
              }
              Country {
                name
                iso
              }
              Location {
                name
              }
            }
          }
          Countries {
            id
            name
          }
          Regions {
            id
            fullName
            name
          }
        }
      }
    `
    const variables = {
      UserListInput: input
    }

    return (
      axios
        // @ts-ignore
        .post(`${apiURL}/graphql`, { query, variables }, { requestId })
        .then(response => {
          if (response.status === 200) {
            return response.data.data.UserList
          } else {
            return []
          }
        })
        .catch(err => axiosError(err, requestId, dispatch))
    )
  }
}
