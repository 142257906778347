// @External Dependencies
import styled from 'styled-components'
import React from 'react'
import { Table } from 'reactstrap'
import { Avatar } from '../../../Components/UI'
import ReactCountryFlag from 'react-country-flag'
import { Link } from 'react-router-dom'
import moment from 'moment'

const UserList = ({ users, total, page, limit, isMobile }) => {
  return (
    <Wrapper>
      <React.Fragment>
        <Table
          className={`Users__table table-bordered table-hover ${
            isMobile ? 'table-responsive' : ''
          }`}>
          <thead>
            <tr>
              <th className='text-center'>#</th>
              <th colSpan={!isMobile ? 2 : 1} className='text-center'>
                Jugador
              </th>
              <th className='text-center'>Código</th>
              <th className='text-center' colspan={2}>
                Registro
              </th>
              <th className='text-center'>Categoría</th>
              <th className='text-center'>Activo</th>
              <th className='text-center'>País</th>
              <th className='text-center'>Región</th>
              <th className='text-center'>Comuna</th>
              <th className='text-center'>
                <i className='fa fa-cogs' />
              </th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, key) => {
              let position = key + 1
              if (page > 1) {
                position = position + limit * (page - 1)
              }
              return (
                <tr key={key}>
                  <td className='text-center'>{position}</td>
                  {!isMobile && (
                    <td className='text-center'>
                      <Avatar person={user} size={35} fontSize={16} />
                    </td>
                  )}
                  <td className='name'>
                    {user.fullName} <br />
                    <small>
                      <i className='fa fa-envelope-o' /> {user.email}
                    </small>
                  </td>
                  <td className='text-center'>{user.code}</td>
                  <td className='text-center'>
                    <i
                      className={`fa fa-thumbs-${
                        user.emailValid ? 'up' : 'down'
                      } text-${user.emailValid ? 'success' : 'danger'}`}
                    />
                  </td>
                  <td className='text-center'>
                    {moment(user.created).format('DD MMM Y')}
                  </td>
                  <td className='text-center'>{user.PersonCategory.name}</td>
                  <td className='text-center'>
                    {user.active === 2 ? '⛔️' : '✅'}
                  </td>
                  <td className='text-center'>
                    <ReactCountryFlag code={user.Address.Country.iso} svg />
                  </td>
                  <td className='text-center'>{user.Address.Region.name}</td>
                  <td className='text-center'>
                    {user.Address.Location ? user.Address.Location.name : '--'}
                  </td>
                  <td className='text-center'>
                    <Link to={user.urlEdit} className='btn btn-primary'>
                      Editar
                    </Link>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </React.Fragment>
    </Wrapper>
  )
}

export default UserList

// @Styles
const Wrapper = styled.div.attrs({ className: 'Users' })`
  .Users {
    & __table {
      td,
      th {
        padding: 3px;
        cursor: pointer;
        vertical-align: middle;

        &.name {
          span {
            float: right;
          }
        }
      }
    }
  }
`
