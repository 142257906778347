// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { getToken, axiosError } from '../../../Services'

const register = data => {
  return async () => {
    getToken()
    const query = `
      mutation StoreEdit($StoreEditInput: StoreEditInput!) {
        StoreEdit(input: $StoreEditInput) {
          status  {
            success
            name
            detail
          }
          store {
            id
            name
            urlView
          }
        }
      }
    `
    const variables = {
      StoreEditInput: {
        id: data.id,
        name: data.name,
        description: data.description,
        logo: data.logo,
        cover: data.cover,
        phone: data.phone,
        email: data.email,
        active: data.active,
        siteUrl: data.siteUrl,
        inChargePerson: data.inChargePerson,
        client: data.client,
        clasificationId: data.clasificationId,
        levelId: data.levelId,
        address: {
          id: data.address.id,
          name: data.address.name,
          address: data.address.address,
          countryId: data.address.countryId,
          regionId: data.address.regionId,
          locationId: data.address.locationId
        },
        tournamentDays: data.tournamentDays.map(({ id }) => id),
        storeGames: data.games.map(({ id }) => id),
        tournamentTypes: data.tournamentTypes.map(t => {
          return {
            typeId: t.typeId,
            quantity: t.quantity
          }
        })
      }
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then(response => {
        if (response.data.errors) {
          return response.data.errors[0].message
        } else {
          const { StoreEdit } = response.data.data
          if (response.status === 200 && StoreEdit.status.success) {
            return StoreEdit
          } else {
            return StoreEdit
          }
        }
      })
      .catch(err => axiosError(err))
  }
}

export default register
