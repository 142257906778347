import i18n from 'i18next'

import spa from './spa'
import eng from './eng'
import por from './por'

i18n.init({
  // we init with resources
  resources: {
    'es-419': { translations: spa },
    'en-EN': { translations: eng },
    por: { translations: por }
  },
  fallbackLng: {
    'es-ES': ['es-419'],
    es: ['es-419'],
    en: ['en-EN'],
    default: ['es-419']
  },
  debug: false,

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ','
  },

  react: {
    wait: true
  }
})

export default i18n
