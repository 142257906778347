// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../constants'
import { getToken, axiosError } from '../../../../Services'

const DeleteTournamentRelated = id => {
  return async dispatch => {
    getToken()
    const query = `
      mutation {
        deleteTournamentRelated (id: ${id}) {
          id
          Tournament {
            name
          }
        }
      }
    `

    return axios
      .post(`${apiURL}/graphql`, { query })
      .then(response => {
        if (response.data.errors) {
          return {
            status: {
              name: 'No se pudo eliminar el torneo relacionado.',
              success: false
            }
          }
        } else {
          const { deleteTournamentRelated } = response.data.data
          return {
            status: {
              name: `Se eliminó exitosamente el torneo ${
                deleteTournamentRelated.Tournament.name
              }.`,
              success: true
            },
            deletedId: deleteTournamentRelated.id
          }
        }
      })
      .catch(err => axiosError(err))
  }
}

export default DeleteTournamentRelated
