// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../constants'
import { getToken, axiosError } from '../../../../Services'

const RoundList = tournamentId => {
  return dispatch => {
    getToken()
    let matchDetails = `
    TournamentMatches(paginate: 99999) {
      table
      reported
      draw
      Player {
        Person {
          id
          avatar
          fullName
          urlAvatar
          code
        }
      }
      Opponent {
        Person {
          id
          avatar
          fullName
          urlAvatar
          code
        }
      }
      Winner {
        Person {
          id
          avatar
          fullName
          urlAvatar
          code
        }
      }
      Loser {
        Person {
          id
          avatar
          fullName
          urlAvatar
          code
        }
      }
    }
    `
    if (tournamentId === 7267) {
      // eslint-disable-next-line no-console
      console.log({ matchDetails })
    }
    const query = `
      query {
        allTournamentRounds(filter: { tournamentId: ${tournamentId} }, orderBy: sortOrder_ASC, paginate: 99999) {
          id
          name
          description
          sortOrder
          urlView
          urlStanding
          minutes
          totalMatches
          created
          TournamentRoundType {
            id
            name
          }
          TournamentRoundStatus {
            id
            name
          }
        }
      }
    `

    return axios
      .post(`${apiURL}/graphql`, { query })
      .then(response => {
        if (response.data.errors) {
          const error = response.data.errors
            ? response.data.errors[0].message
            : 'No hay rondas creadas'
          return {
            status: {
              success: false,
              detail: error
            }
          }
        } else {
          const { allTournamentRounds } = response.data.data
          return {
            status: {
              success: allTournamentRounds.length > 0,
              name: 'Petición exitosa'
            },
            Rounds: allTournamentRounds
          }
        }
      })
      .catch(err => axiosError(err))
  }
}

export default RoundList
