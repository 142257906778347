import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { getUser } from '../../Redux/Actions'

/**
 * Suplanta al usuario.
 */
export const SurveySupplant = () => {
  const { playerId, token } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()

  const search = useCallback(async () => {
    dispatch({ type: 'LOGIN', payload: { token } })
    const user = await dispatch(getUser())
    if (user === 'SET_USER_OK') {
      history.replace(`/survey/${playerId}`)
    }
  }, [token, dispatch, history, playerId])

  useEffect(() => {
    search()
  }, [token, dispatch])

  return <></>
}
