// @External Dependencies
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Container from 'reactstrap/lib/Container'
import Row from 'reactstrap/lib/Row'
import Col from 'reactstrap/lib/Col'
import UIVars from '../UI/variables'

const AlertContent = ({ alert }) => (
  <Wrapper color={alert.status}>
    <Container>
      <Row>
        <Col md={12}>
          <p>{alert.text}</p>
        </Col>
      </Row>
    </Container>
  </Wrapper>
)

// @Proptypes
AlertContent.propTypes = {
  email: PropTypes.string
}

// @Export Styled Component
export default AlertContent

const Wrapper = styled.div.attrs({ className: 'AlertContent flex-center' })`
  margin: 25px 0px;
  padding: 15px;
  color: white;
  background-color: ${props => UIVars.setColors[props.color]};
  border-radius: 5px;
  box-shadow: 0px 0px 53px rgba(0, 0, 0, 0.5);
  p {
    text-align: center;
    margin: 0px;
  }
`
