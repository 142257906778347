// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../constants'
import { getToken, axiosError } from '../../../Services'

const getSeasons = () => {
  const requestId = 'getSeasons'
  return dispatch => {
    getToken()
    const query = `
      query allSeasons {
        allSeasons(orderBy: startDate_DESC) {
          id
          name
          startDate
          Game {
            name
          }
        }
      }
    `

    return axios
      .post(`${apiURL}/graphql`, { query }, { requestId })
      .then(response => {
        if (response.status === 200) {
          return {
            status: {
              success: true
            },
            seasons: response.data.data.allSeasons
          }
        } else {
          return {
            status: {
              success: false
            }
          }
        }
      })
      .catch(err => axiosError(err, requestId, dispatch))
  }
}

export default getSeasons
