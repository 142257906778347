// @External Dependencies
import React from 'react'

import { CardBody, CardFooter } from '../../Components/UI/SimpleCard'

/**
 * Componente con la caja con los tipos de torneos.
 *
 */
export const FormatCard = ({ data, deleteType, isAdmin }) => {
  return (
    <div className='card'>
      <CardBody
        key={data.id}
        title={data.name}
        text={data.description}
        href={isAdmin ? data.urlUpdate : ''}
        info={[
          {
            name: 'Torneos realizados',
            value: data.totalTournaments
          },
          {
            name: 'Juegos',
            value: <GamesAssociated games={data.FormatGames} />
          }
        ]}
        isObject={true}
        status={{ ignore: true }}
      />
      {isAdmin && (
        <CardFooter
          href={data.urlUpdate}
          texts={{ desktop: 'Ver tipo torneo', mobile: 'Ver tipo Torneo' }}
        />
      )}
    </div>
  )
}

const GamesAssociated = ({ games }) => {
  return (
    <ul>
      {games.map((formatGame, key) => {
        return <li key={key}>{formatGame.Game.name}</li>
      })}
    </ul>
  )
}
