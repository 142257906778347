// @External Dependencies
import React, { Component } from 'react'
// import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Jumbotron, Container } from 'reactstrap'
import ReactCountryFlag from 'react-country-flag'
import { bgBackground } from '../../../assets/img/login'
// @Dependencies
import { getUser } from '../../Redux/Actions'
import { axiosAbort } from '../../Services'

import StoreMenu from './StoreMenu'

// @Component
class StoreIndex extends Component {
  state = {
    alertContent: '',
    loading: false,
    storeId: 0
  }

  /**
   * - Al iniciar busca los paises
   * - Si estoy logueado entonces me manda al home
   */
  async componentDidMount () {
    await this.props.getUser()
    const { loggedUser, history } = this.props
    if (!loggedUser.user.Store) {
      loggedUser && loggedUser.user && history.push('/store/register')
    }
  }
  componentWillUnmount () {
    axiosAbort()
  }

  render () {
    const { t, loggedUser, history } = this.props
    const { Store } = loggedUser.user
    if (!Store) {
      history.push('/store/register')
      return ''
    }

    const { havePermissions } = this.state

    const fullAddress = [
      Store.Address.address,
      ', ',
      Store.Address.Region.fullName,
      Store.Address.Location ? Store.Address.Location.name : ''
    ].join(' ')

    return (
      <Wrapper>
        <Container>
          <StoreMenu
            store={Store}
            havePermissions={havePermissions}
            match={this.props.match}
            t={t}
          />
          <Jumbotron>
            <h1>{`${Store.name}`}</h1>
            <p>Los datos de contacto principal son</p>
            <ul>
              <li>{`Contacto Principal: ${loggedUser.user.fullName} `}</li>
              <li>{`Email: ${Store.email} `}</li>
              <li>
                {`Dirección: ${fullAddress}`}{' '}
                <ReactCountryFlag code={Store.Address.Country.iso} svg />{' '}
              </li>
              <li
                className={
                  Store.active === 1 ? 'text-success' : 'text-danger'
                }>{`Estado: ${
                Store.active === 1 ? 'Activa' : 'Aún no ha sido validada'
              }`}</li>
              <li>Nivel tienda: {Store.Level.name}</li>
            </ul>
            <h2>REDES SOCIALES</h2>
            <ul>
              {Store.siteUrl && (
                <li>
                  Web:{' '}
                  <a
                    href={Store.siteUrl}
                    target='_blank'
                    rel='noopener noreferrer'
                    title='asd'>
                    {Store.siteUrl}
                  </a>
                </li>
              )}
              {Store.facebookUrl && (
                <li>
                  Facebook:{' '}
                  <a
                    href={Store.facebookUrl}
                    target='_blank'
                    rel='noopener noreferrer'
                    title='asd'>
                    {Store.facebookUrl}
                  </a>
                </li>
              )}
              {Store.instagramUrl && (
                <li>
                  Instagram:{' '}
                  <a
                    href={Store.instagramUrl}
                    target='_blank'
                    rel='noopener noreferrer'
                    title='asd'>
                    {Store.instagramUrl}
                  </a>
                </li>
              )}
              {Store.twitterUrl && (
                <li>
                  Twitter:{' '}
                  <a
                    href={Store.twitterUrl}
                    target='_blank'
                    rel='noopener noreferrer'
                    title='asd'>
                    {Store.twitterUrl}
                  </a>
                </li>
              )}
              {Store.youtubeUrl && (
                <li>
                  Youtube:{' '}
                  <a
                    href={Store.youtubeUrl}
                    target='_blank'
                    rel='noopener noreferrer'
                    title='asd'>
                    {Store.youtubeUrl}
                  </a>
                </li>
              )}
            </ul>
            <p className='lead'>
              <Link to='/store/update' className='btn btn-primary'>
                <i className='fa fa-edit' />
                Actualizar datos
              </Link>
              <Link to='/tournament/register' className='btn btn-primary'>
                <i className='fa fa-plus-circle' />
                Crear torneo
              </Link>
            </p>
          </Jumbotron>
        </Container>
      </Wrapper>
    )
  }
}

// @Proptypes
StoreIndex.propTypes = {
  loggedUser: PropTypes.object
}

/*
  @Store Connection: connect
  @Export Component
*/
const mapStateToProps = state => ({
  loggedUser: state.loggedUser,
  countries: state.countries
})
const mapDispatchToProps = dispatch => bindActionCreators({ getUser }, dispatch)
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(StoreIndex))

// @Styles
const Wrapper = styled.div.attrs({ className: 'Login' })`
  height: inherit;
  background: url(${bgBackground('fondo1')}) 50% 0;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding: 20px 0px;
`
