// @External Dependencies
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import UIVars from '../../Components/UI/variables'

const ValidateMail = ({ email }) => (
  <Wrapper>
    <p>
      El correo de esta cuenta{' '}
      <b>
        "<u>{email}</u>"
      </b>{' '}
      aún no ha sido confirmado.
    </p>
  </Wrapper>
)

// @Proptypes
ValidateMail.propTypes = {
  email: PropTypes.string
}

// @Export Styled Component
export default ValidateMail

const Wrapper = styled.div.attrs({ className: 'ValidateMail flex-center' })`
  margin: 25px 0px;
  padding: 15px;
  color: white;
  background-color: ${UIVars.setColors.danger};
  border-radius: 5px;
  box-shadow: 0px 0px 53px rgba(0, 0, 0, 0.5);
  p {
    margin: 0px;
  }
`
