import React from 'react'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import styled from 'styled-components'

const pxToMm = px => {
  return Math.floor(px / document.getElementById('myMm').offsetHeight)
}

const mmToPx = mm => {
  return document.getElementById('myMm').offsetHeight * mm
}

const range = (start, end) => {
  return Array(end - start)
    .join(0)
    .split(0)
    .map(function (val, id) {
      return id + start
    })
}

const PrintButton = ({ id, label, alias }) => (
  <div className='tc mb4 mt2'>
    <div id='myMm' style={{ height: '1mm' }} />
    <div
      onClick={() => {
        const input = document.getElementById(id)
        const inputHeightMm = pxToMm(input.offsetHeight)
        // const a4WidthMm = 210;
        const a4HeightMm = 297
        const a4HeightPx = mmToPx(a4HeightMm)
        const numPages =
          inputHeightMm <= a4HeightMm
            ? 1
            : Math.floor(inputHeightMm / a4HeightMm) + 1

        // System to manually handle page breaks
        // Wasn't able to get it working !
        // The idea is to break html2canvas screenshots into multiple chunks and stich them together as a pdf
        // If you get this working, please email me a khuranashivek@outlook.com and I'll update the article
        // eslint-disable-next-line
        const pdf = new jsPDF()
        range(0, numPages).forEach(page => {
          html2canvas(input, { height: a4HeightPx, y: page * a4HeightPx }).then(
            canvas => {
              const imgData = canvas.toDataURL('image/png')
              if (page > 0) {
                pdf.addPage()
              }
              pdf.addImage(imgData, 'PNG', 10, 0)
            }
          )
        })

        setTimeout(() => {
          pdf.save(`${alias || 'Descarga'}.pdf`)
        }, 2000)
      }}>
      <span className='btn'>
        <i className='fa fa-print' /> {label}
      </span>
    </div>
  </div>
)

export default styled(PrintButton)``
