// @External Dependencies
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { bgBackground } from '../../../assets/img/login'
// @Style Dependencies
import { Row, Col, Container, Jumbotron } from 'reactstrap'

// @Dependencies
import {
  getTournamentsList,
  getTournamentStatus,
  getSeasons,
  getGames,
  getCountries,
  getRegions,
  UserFind
} from '../../Redux/Actions'
import { axiosAbort } from '../../Services'
import {
  LoadingSVG,
  Select,
  PaginationList,
  Button,
  Input
} from '../../Components/UI'
import TournamentList from '../Tournaments/TournamentList'

import { TODOS } from '../Tournaments/constants'
// @Component
class UserTournamentsView extends Component {
  state = {
    loading: true,
    loadingMessage: 'Estamos buscando los torneos...',
    disableStore: false,
    showFilters: !this.props.isMobile,
    data: {
      total: 0,
      pages: 0,
      Tournaments: [],
      Types: [],
      Stores: [],
      Countries: [],
      Regions: []
    },
    seasons: [],
    presentials: [
      {
        id: 0,
        name: 'Todos los torneos'
      },
      {
        id: 2,
        name: '📍 Torneos Presenciales'
      },
      {
        id: 1,
        name: '🖥️ Torneos Remotos'
      }
    ],
    seaonsActives: [
      {
        id: 0,
        name: '⚠️ Temporadas pasadas'
      },
      {
        id: 1,
        name: '✅ Temporadas actuales'
      },
      {
        id: 2,
        name: 'Temporadas pasadas y actuales (todas)'
      }
    ],
    games: [],
    status: [],
    seasonId: 0,
    filters: {
      page: 1,
      limit: 12,
      formatId: 0,
      typeId: 0,
      storeId: 0,
      countryId: 0,
      gameId: 0,
      regionId: 0,
      search: '',
      isActiveSeason: 1,
      date: 'DESC',
      players: 'DESC',
      /** Flag para cursos futuros solamente. */
      statusId: TODOS,
      code: '',
      isOrganizer: false
    },
    buttonBack: '',
    person: {
      code: ''
    }
  }

  async componentDidMount () {
    const { params } = this.props.match
    const { filters } = this.state
    let disableStore = false
    if (params.storeId) {
      filters.storeId = Number(params.storeId)
      disableStore = Number(params.storeId)
    }

    if (params.code) {
      filters.code = params.code
    }

    if (params.organizer) {
      filters.isOrganizer = params.organizer === 'organizer'
    }

    const games = await this.props.getGames()
    const status = await this.props.getTournamentStatus()
    const resolve = await this.props.UserFind(params.code)

    let person = {}
    if (resolve.status.success && resolve.Persons.length > 0) {
      person = resolve.Persons[0]
      this.setState({
        games,
        status,
        baseFilters: this.state.filters,
        disableStore,
        filters,
        Countries: await this.props.getCountries(),
        person
      })
      await this.search()
    } else {
      this.setState({
        loadingMessage: `No se encontraron datos del usuario con el código "${
          filters.code
        }"`,
        buttonBack: '/ranking'
      })
    }
  }

  componentWillUnmount () {
    axiosAbort()
  }

  /**
   * Trigger de búsqueda en componente
   */
  async search () {
    const { getTournamentsList } = this.props
    const { filters } = this.state
    this.setState({
      loading: true
    })
    const tournaments = await getTournamentsList(this.state.seasonId, filters)

    if (tournaments.status.success) {
      this.setState({
        data: {
          ...tournaments,
          Regions: tournaments.Regions.map(r => {
            return {
              id: r.id,
              name: r.fullName
            }
          })
        }
      })
    }
    this.setState({
      loading: false
    })
  }

  /**
   * Paginación
   */
  handlePagination = async event => {
    const { filters } = this.state
    if (filters.page !== event) {
      filters.page = event
      this.setState({ filters })
      await this.search()
    }
  }

  /**
   * Reset filters
   */
  resetFilters = async () => {
    this.setState({
      filters: this.state.baseFilters
    })
    await this.search()
  }
  /**
   * On change filtros etc
   */
  onChange = async e => {
    const target = e.target
    let { filters, seasonId } = this.state
    let search = true
    filters.page = 1
    if (target.id === 'search') {
      filters.search = target.value
      search = false
    }
    if (target.id === 'countryId') {
      filters.countryId = Number(target.value)
    }
    if (target.id === 'regionId') {
      filters.regionId = Number(target.value)
    }
    if (target.id === 'gameId') {
      filters.gameId = Number(target.value)
    }
    if (target.id === 'seasonId') {
      seasonId = Number(target.value)
    }
    if (target.id === 'formatId') {
      filters.formatId = Number(target.value)
    }
    if (target.id === 'typeId') {
      filters.typeId = Number(target.value)
    }
    if (target.id === 'limit') {
      filters.limit = Number(target.value)
    }
    if (target.id === 'isActiveSeason') {
      filters.isActiveSeason = Number(target.value)
    }
    if (target.id === 'isPresential') {
      filters.isPresential = Number(target.value)
    }
    if (target.id === 'statusId') {
      filters.statusId = [Number(target.value)]
    }
    if (target.id === 'storeId') {
      filters.storeId = Number(target.value)
    }
    if (target.id === 'sortDate') {
      filters.date = filters.date === 'ASC' ? 'DESC' : 'ASC'
    }
    if (target.id === 'sortPlayers') {
      filters.players = filters.players === 'ASC' ? 'DESC' : 'ASC'
    }

    this.setState({ filters, seasonId })
    if (search) {
      await this.search()
    }
  }

  /**
   * Evento para buscar texto
   */
  handleSubmit = async e => {
    e.preventDefault()
    const { search } = this.state.filters
    if (search.length > 1) {
      search && (await this.search())
    } else {
      if (!search.length) {
        this.resetFilters()
      }
      this.setState({
        loading: true,
        loadingMessage: 'Mínimo debes ingresar 1 caracteres para la búsqueda'
      })
      setTimeout(() => {
        this.setState({
          loading: false
        })
      }, 2000)
    }
  }

  render () {
    const {
      loading,
      loadingMessage,
      filters,
      data,
      person,
      buttonBack
    } = this.state

    if (loading) {
      return <LoadingSVG message={loadingMessage} buttonBack={buttonBack} />
    }

    const { code, organizer } = this.props.match.params

    return (
      <Wrapper>
        <Container>
          <article>
            <Jumbotron>
              <Row>
                <Col md={12}>
                  {filters.isOrganizer ? (
                    <>
                      <h1>Organización de torneos ({person.code})</h1>
                      <p>
                        Estos son los torneos que fue organizador{' '}
                        <b>{person.fullName}</b>
                      </p>
                    </>
                  ) : (
                    <>
                      <h1>Participación en torneos ({person.code})</h1>
                      <p>
                        Estos son los torneos en que participó{' '}
                        <b>{person.fullName}</b>
                      </p>
                    </>
                  )}
                  <p>
                    Puedes aplicar filtros para acotar la búsqueda de torneos.
                  </p>
                </Col>
              </Row>
              <div className='Tournaments__filter row'>
                <Col md={4} className='text-center'>
                  <form onSubmit={this.handleSubmit} className=''>
                    <Input
                      inputSize={12}
                      name='search'
                      placeholder='Buscar por nombre del torneo'
                      value={filters.search}
                      onChange={this.onChange}
                    />
                  </form>
                </Col>
                <Col md={4} className='text-center'>
                  <Select
                    inputSize={12}
                    name='gameId'
                    options={this.state.games}
                    placeholder='Selecciona un Juego'
                    onChange={this.onChange}
                    select={filters.gameId}
                  />
                </Col>
                {this.state.showFilters && (
                  <React.Fragment>
                    <Col md={4} className='text-center'>
                      <Select
                        inputSize={12}
                        name='seasonId'
                        options={data.Seasons}
                        placeholder='Temporadas de juegos'
                        onChange={this.onChange}
                        select={this.state.seasonId}
                      />
                    </Col>
                    <Col md={4} className='text-center'>
                      <Select
                        inputSize={12}
                        name='isActiveSeason'
                        options={this.state.seaonsActives}
                        placeholder={'Selecciona temporadas'}
                        onChange={this.onChange}
                        select={filters.isActiveSeason}
                      />
                    </Col>

                    <Col md={4} className='text-center'>
                      <Select
                        inputSize={12}
                        name='typeId'
                        options={data.Types}
                        placeholder='Todos los Tipos'
                        onChange={this.onChange}
                        select={filters.typeId}
                      />
                    </Col>
                    <Col md={4} className='text-center'>
                      <Select
                        inputSize={12}
                        name='statusId'
                        options={this.state.status}
                        placeholder='Todos los estados de Torneos'
                        onChange={this.onChange}
                        select={filters.statusId}
                      />
                    </Col>
                    <Col md={4} className='text-center'>
                      <Select
                        inputSize={12}
                        name='storeId'
                        options={data.Stores}
                        placeholder='Todas las tiendas'
                        onChange={this.onChange}
                        select={filters.storeId}
                      />
                    </Col>
                    <Col md={4} className='text-center'>
                      <Select
                        inputSize={12}
                        name='isPresential'
                        options={this.state.presentials}
                        placeholder=''
                        onChange={this.onChange}
                        select={filters.isPresential}
                      />
                    </Col>
                    <Col md={12} className='text-center'>
                      <div className=''>
                        <Button
                          text='Limpiar filtros'
                          onClick={this.resetFilters}
                          state='default'
                        />
                      </div>
                    </Col>
                  </React.Fragment>
                )}
                {this.props.isMobile && (
                  <Button
                    text={
                      !this.state.showFilters
                        ? 'Mostrar Filtros'
                        : 'Ocultar Filtros'
                    }
                    state='info'
                    style={{
                      margin: 'auto',
                      marginTop: this.state.showFilters ? '20px' : ''
                    }}
                    onClick={() => {
                      this.setState({
                        showFilters: !this.state.showFilters
                      })
                    }}
                  />
                )}
              </div>
            </Jumbotron>
            {data.Tournaments.length > 0 ? (
              <Fragment>
                <TournamentList
                  tournaments={data.Tournaments}
                  isMobile={this.props.isMobile}
                />
                <PaginationList
                  active={filters.page}
                  total={data.total}
                  pages={data.pages}
                  handle={this.handlePagination}
                  centered
                />
              </Fragment>
            ) : (
              <Fragment>
                <Jumbotron>
                  <h3 className='text-center'>No se encontraron resultados</h3>
                  <a
                    href={`/user/tournaments/${code}${
                      organizer === 'organizer' ? '/organizer' : ''
                    }`}
                    style={{ margin: 'auto 30%', display: 'block' }}
                    className='btn btn-primary'>
                    Limpiar filtros
                  </a>
                </Jumbotron>
              </Fragment>
            )}
          </article>
        </Container>
      </Wrapper>
    )
  }
}

// @Proptypes
UserTournamentsView.propTypes = {
  loggedUser: PropTypes.object
}

const mapStateToProps = state => ({
  loggedUser: state.loggedUser
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getTournamentsList,
      getTournamentStatus,
      getSeasons,
      getCountries,
      getRegions,
      getGames,
      UserFind
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(UserTournamentsView))

// @Styles
const Wrapper = styled.div.attrs({ className: 'TournamentUsers' })`
  background: url(${bgBackground('fondo2')}) 50% 0;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding: 80px 0px;

  .container {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
  }

  article {
    h1 {
      text-align: left;
    }
  }
  .Tournaments__sort {
    text-align: right;
    span {
      margin-left: 10px;
    }
  }
`
