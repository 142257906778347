import React from 'react'
import { format, formatDistanceStrict } from 'date-fns'
import { es } from 'date-fns/locale'
import ReactCountryFlag from 'react-country-flag'
import ReactHtmlParser from 'react-html-parser'
import { Table } from 'reactstrap'
import { Link } from 'react-router-dom'

/**
 *
 * @param {*} param0
 * @returns
 */
export const TournamentListTable = ({ data, isMobile }) => {
  return (
    <Table className='table table-hover table-responsive'>
      <thead>
        <tr>
          <th />
          {!isMobile && <th colSpan={2}>Fecha</th>}
          <th>Tienda</th>
          <th>Juego / Tipo Torneo</th>
          {!isMobile && (
            <th>
              {' '}
              <i className='fa fa-users' />{' '}
            </th>
          )}
          {!isMobile && <th>Formato</th>}
          <th>Estado</th>
        </tr>
      </thead>
      <tbody>
        {data &&
          data.map(torneo => {
            return (
              <tr className='row-tournament' key={torneo.id}>
                <td>
                  <img
                    src={torneo.Game.icon}
                    alt={torneo.Game.name}
                    width={75}
                  />
                </td>
                {!isMobile && (
                  <td>
                    <div>
                      {torneo.isPresential ? (
                        torneo.Address && (
                          <span>
                            <ReactCountryFlag
                              code={torneo.Address.countryIso}
                              svg
                            />
                          </span>
                        )
                      ) : (
                        <i className='fa fa-camera' />
                      )}
                    </div>
                  </td>
                )}
                {!isMobile && (
                  <td>
                    {formatDistanceStrict(
                      new Date(torneo.startDate),
                      new Date(),
                      {
                        addSuffix: true,
                        locale: es
                      }
                    )}
                    <br />
                    {torneo.isPresential && torneo.Address.locationName
                      ? torneo.Address.locationName
                      : ''}
                  </td>
                )}
                <td className='store'>
                  <p>
                    <i className='fa fa-bank' />
                    {torneo.Store && torneo.Store.name}
                  </p>
                  <span className='btn'>
                    {format(new Date(torneo.startDate), 'EEEE dd MMMM y', {
                      addSuffix: true,
                      locale: es
                    })}
                  </span>
                </td>
                <td>
                  {!isMobile && (
                    <div
                      className='button btn-info game'
                      style={{
                        // backgroundColor: torneo.Game.color
                        backgroundColor: '#5c5c5c'
                      }}>
                      {torneo.Game.name}
                      {torneo.TournamentType && (
                        <span className='type'>
                          {torneo.TournamentType.name}
                        </span>
                      )}
                    </div>
                  )}
                  <Link to={torneo.urlView}>
                    <i className='fa fa-chevron-right' />
                    {torneo.name}
                  </Link>
                </td>
                {!isMobile && (
                  <td className='center'>
                    {torneo.TournamentStatus.name !== 'Programado'
                      ? torneo.maxPlayers
                      : ''}
                  </td>
                )}
                {!isMobile && <td>{torneo.Format.name}</td>}
                <td>
                  <div
                    className={`text-center state category bg-${
                      torneo.TournamentStatus.style
                    }`}>
                    <i className={`fa fa-${torneo.TournamentStatus.icon}`} />{' '}
                    <>
                      <span>{torneo.TournamentStatus.name}</span>
                      <span className='pull-right'>
                        {ReactHtmlParser(torneo.TournamentStatus.extra)}
                      </span>
                    </>
                  </div>
                </td>
              </tr>
            )
          })}
      </tbody>
    </Table>
  )
}
