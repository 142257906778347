// @External Dependencies
import React from 'react'
import { CardHeader, CardBody } from '../../Components/UI/SimpleCard'

/**
 * Componente con la caja con los tipos de torneos.
 *
 */
export const GameCard = ({ data, isAdmin }) => {
  return (
    <div className='card'>
      <CardHeader
        image={
          data.urlImage ||
          'https://api.myl.cl/static/images-wpmyl/sectionWallpaper/arte.jpg'
        }
        title={data.name}
        ignoreTitle={data.urlImage.length > 0}
        href={data.urlActiveSeason}
      />
      <CardBody
        key={data.id}
        title={data.name}
        titleClassName='text-center'
        href={data.urlActiveSeason}
        info={[]}
        status={{
          ignore: true
        }}
      />
    </div>
  )
}
